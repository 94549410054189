import React from "react";
import { BodyText, HeaderText } from "../Text";
import Modal from "./index";
import Flex from "../Flex";
import { borders, colors, radii } from "../../../providers/theme/theme";
import { PrimaryButton } from "../Button";
import Close from "../../svgs/Close";


export default function CtaModal({
    closeModal,
    showModal,
    headerText,
    bodyText,
    ctaButtonText,
    ctaButtonColor,
    onClickCtaButton,
    ctaTracking,
    showCloseButton,
}) {
    return (
        <Modal showModal={showModal} closeModal={closeModal}>
            <Flex {...styles.Container}>
                {
                    showCloseButton && (
                        <Flex {...styles.CloseIconContainer}>
                            <Close {...styles.CloseIcon} onClick={closeModal}/>
                        </Flex>
                    )
                }
                <HeaderText
                    bold
                    text={headerText}
                    {...styles.HeaderText}
                />
                <BodyText text={bodyText} {...styles.BodyText}/>
                {
                    ctaButtonText && (
                        <PrimaryButton
                            tracking={ctaTracking}
                            text={ctaButtonText}
                            backgroundColor={ctaButtonColor}
                            hoverBg={ctaButtonColor}
                            onClick={onClickCtaButton}
                            {...styles.CtaButton}
                        />
                    )
                }
            </Flex>
        </Modal>
    );
}

const styles = {
    Container: {
        backgroundColor: "#272B41",
        border: borders.primary,
        borderRadius: radii.medium,
        alignItems: "flex-start",
        justifyContent: "center",
        paddingTop: "15px",
        paddingBottom: "20px",
        paddingLeft: "16px",
        paddingRight: "16px",
        flexDirection: "column",
        maxWidth: ["100%", "350px"],
    },
    HeaderText: {
        textAlign: "left",
        fontSize: "30px",
    },
    BodyText: {
        marginTop: "10px",
        textAlign: "left",
        fontSize: "14px",
    },
    CtaButton: {
        marginTop: "20px",
        width: "100%"
    },
    CloseIconContainer:{
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start"
    },
    CloseIcon: {
        fill: colors.grey.light,
        opacity: "0.2",
        cursor: "pointer",
        width: "25px",
        height: "25px",
    }
}