import { useState, useEffect, useRef } from "react";
import { createWatcher } from "@makerdao/multicall";
import get from "lodash/get";
import set from "lodash/set";
import throttle from "lodash/throttle";
import config from "../../config";
import ethereumConfig from "./config";
import {fromWei, toWei, withCommas} from "../../utils/numbers";
import BigNumber from "bignumber.js";
import {track} from "../../providers/analytics";
import {EVENT_TYPES, CONTEXT_NAMES, EVENTS} from "../../providers/analytics/events";
import { tribalChiefConfig } from "../../components/pages/TribalChief/logic";

const multicallContractAddresses = {
    LOCAL: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    ROPSTEN: "0x53c43764255c17bd724f74c4ef150724ac50a3ed",
    MAINNET: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    RINKEBY: "0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821",
};

// Watcher timing params
const watcherIntervalWithWallet = 30000;
const watcherIntervalWithoutWallet = 60000;
const secondsBeforeInactivity = 25; // This is lower than the watcher interval to make sure users who bounce don't do more than 1 eth_call
const activityMonitorThrottle = 1000; // Update lastUserActivityTimestamp max 1/second
const inactivityMonitorInterval = 10000; // How often we check for inactivity


const watcherConfig = {
    rpcUrl: ethereumConfig.rpcUrl,
    multicallAddress: multicallContractAddresses[config.network],
};

export default function useFeiProtocolWatcher({
    contracts,
    connectedAccount,
}) {

    const [state, _setState] = useState({
        watcher: null,
        shouldWatch: true,
        protocolState: {},
        lastUserActivityTimestamp: Date.now(),
    });
    const stateRef = useRef(state);
    const setState = (newState) => {
        stateRef.current = newState;
        _setState(newState);
    }

    const {
        watcher,
        shouldWatch,
        protocolState,
    } = state;

    const watcherModel = getWatcherModel({
        contracts,
        connectedAccount,
    });

    watcherConfig.interval = !!connectedAccount ?
        watcherIntervalWithWallet : 
        watcherIntervalWithoutWallet;

    const updateLastUserActivityTimestamp = throttle(() => {
        setState({
            ...stateRef.current,
            lastUserActivityTimestamp: Date.now(),
        });
        if (!stateRef.current.shouldWatch) {
            setState({
                ...stateRef.current,
                shouldWatch: true,
            })
        }     
    }, activityMonitorThrottle, {leading: true});

    useEffect(() => {
        const events = [
            "mousemove",
            "focus",
            "keypress",
            "click",
            "scroll",
        ];
        events.forEach((eventName) => {
            window.addEventListener(eventName, () => {
                updateLastUserActivityTimestamp();
            });
        });

        setInterval(() => {
            const secondsElapsed = (Date.now() - stateRef.current.lastUserActivityTimestamp) / 1000;
            if (secondsElapsed > secondsBeforeInactivity) {
                setState({
                    ...stateRef.current,
                    shouldWatch: false,
                })
            }
        }, inactivityMonitorInterval)
    }, []);

    useEffect(() => {
        if (!watcher) {
            return;
        }
        if (shouldWatch) {
            watcher.start();
        } else {
            watcher.stop();
        }
    }, [shouldWatch]);


    const recreateWatcher = () => {
        setState({
            ...stateRef.current,
            protocolState: {},
        });
        watcher.recreate(watcherModel, watcherConfig);
    }

    useEffect(() => {
        if (!contracts) {
            return;
        }

        if (!watcher) {
            const newWatcher = createWatcher(watcherModel, watcherConfig);
            setState({
                ...stateRef.current,
                watcher: newWatcher,
            });

            newWatcher.batch().subscribe(parseWatcherUpdates(setState, stateRef, contracts));

            newWatcher.onPoll(listener => {
                track({
                    eventName: EVENTS.GENERIC.WATCHER_WEB3_CALL,
                    eventType: EVENT_TYPES.API_CALL,
                    contextName: CONTEXT_NAMES.BACKGROUND,
                }, listener);
            });

            newWatcher.onError(error => {
                console.log("Error watching Fei Protocol: ", error);
                track({
                    eventName: EVENTS.GENERIC.WATCHER_WEB3_ERROR,
                    eventType: EVENT_TYPES.API_ERROR,
                    contextName: CONTEXT_NAMES.BACKGROUND,
                });
            });

            newWatcher.start();
        } else {
            // The watcher exists so just recreate it with the new model
            recreateWatcher();
        }
    }, [contracts, connectedAccount]);
    
    return {
        protocolState,
        recreateWatcher,
    };
}

function parseWatcherUpdates(setState, stateRef, contracts) {
    return (updates) => {
        // We are going to ovewrite all of the old fields with new ones
        const newState = stateRef.current.protocolState;
        for (let i = 0; i < updates.length; i++) {
            let { type, value } = updates[i];
            set(newState, type, value);
        }
        const newStateWithComputedFields = getComputedFields(newState, contracts);
        setState({
            ...stateRef.current,
            protocolState: newStateWithComputedFields,
        });
    }
}

function getComputedFields(state, contracts) {

    // INDEX price from INDEX-ETH on Sushi
    const indexReservesInIndexEthPool = get(state, "SUSHI_INDEX_ETH.INDEX_RESERVES", null);
    const ethReservesInIndexEthPool = get(state, "SUSHI_INDEX_ETH.ETH_RESERVES", null);
    const ethUsdChainlinkPrice = get(state, "CHAINLINK.ETH_USD_PRICE", "0");

    const indexReservesInIndexEthPoolBN = new BigNumber(indexReservesInIndexEthPool);
    const ethReservesInIndexEthPoolBN = new BigNumber(ethReservesInIndexEthPool);
    const ethUsdChainlinkPriceBN = new BigNumber(ethUsdChainlinkPrice);

    if (indexReservesInIndexEthPool && ethReservesInIndexEthPool) {
        
        const indexUsdPriceBN = ethReservesInIndexEthPoolBN
            .div(indexReservesInIndexEthPoolBN)
            .times(ethUsdChainlinkPriceBN);
        set(state, "PRICES.INDEX_USD", indexUsdPriceBN.toString())
    }

    // TRIBE USD Price via Chainlink
    const tribeEthChainlinkPrice = get(state, "CHAINLINK.TRIBE_ETH_PRICE", null);
    const tribeEthChainlinkPriceBN = new BigNumber(tribeEthChainlinkPrice);
    if (ethUsdChainlinkPrice && tribeEthChainlinkPrice) {
        const tribeUsdPriceBN = tribeEthChainlinkPriceBN.times(ethUsdChainlinkPriceBN);
        set(state, "USD_PRICES.TRIBE", tribeUsdPriceBN.toString())
    }

    // ETH-USDC price on Uniswap
    const usdcReserves = get(state, "UNI_USDC_ETH.USDC_RESERVES", null);
    const ethReserves = get(state, "UNI_USDC_ETH.ETH_RESERVES", null);
    const usdcReservesBN = new BigNumber(usdcReserves);
    const ethReservesBN = new BigNumber(ethReserves);

    if (usdcReserves && ethReserves) {
        const spotPrice = usdcReservesBN.div(ethReservesBN).toString();
        set(state, "UNI_USDC_ETH.ETH_PRICE", spotPrice);
    }

    // FEI-TRIBE LP USD value
    const feiInFeiTribePool = get(state, "UNI_FEI_TRIBE.FEI_RESERVES", "0");
    const feiPrice = get(state, "CHAINLINK.FEI_USD_PRICE", "0");
    const feiTribeLpTokenSupply = get(state, "UNI_FEI_TRIBE.TRIBE_FEI_LP_SUPPLY", "0");
    const feiInFeiTribePoolBN = new BigNumber(feiInFeiTribePool);
    const feiPriceBN = new BigNumber(feiPrice);
    const feiTribeLpTokenSupplyBN = new BigNumber(feiTribeLpTokenSupply);

    if (feiTribeLpTokenSupply) {
        const feiTribeTotalLiqInUsdBN =  feiInFeiTribePoolBN.times(2).times(feiPriceBN);
        const feiTribeLpUsdValueBN = feiTribeTotalLiqInUsdBN.div(feiTribeLpTokenSupplyBN);
        set(state, "USD_PRICES.TRIBE_FEI_LP", feiTribeLpUsdValueBN.toString());
    }

    // G_UNI_FEI_DAI USD value
    const gUniFeiDaiLpUnderlyingDai = get(state, "GELATO.FEI_DAI_G_UNI.TOTAL_UNDERLYING_DAI", "0");
    const gUniFeiDaiLpUnderlyingFei = get(state, "GELATO.FEI_DAI_G_UNI.TOTAL_UNDERLYING_FEI", "0");
    const gUniFeiDaiLpTotalSupply = get(state, "GELATO.FEI_DAI_G_UNI.TOTAL_LP_SUPPLY", "0");
    const daiPrice = get(state, "CHAINLINK.DAI_USD_PRICE", "0");
    
    
    const gUniFeiDaiLpUnderlyingDaiBN = new BigNumber(gUniFeiDaiLpUnderlyingDai);
    const gUniFeiDaiLpUnderlyingFeiBN = new BigNumber(gUniFeiDaiLpUnderlyingFei);
    const gUniFeiDaiLpTotalSupplyBN = new BigNumber(gUniFeiDaiLpTotalSupply);
    const daiPriceBN = new BigNumber(daiPrice);
    const gUniFeiDaiLpUnderlyingDaiValueBN = daiPriceBN.times(gUniFeiDaiLpUnderlyingDaiBN);
    const gUniFeiDaiLpUnderlyingFeiValueBN = feiPriceBN.times(gUniFeiDaiLpUnderlyingFeiBN);
    const gUniFeiDaiLpTotalUnderlyingValueBN = gUniFeiDaiLpUnderlyingDaiValueBN
        .plus(gUniFeiDaiLpUnderlyingFeiValueBN);
    const gUniFeiDaiLpPriceBN = gUniFeiDaiLpTotalUnderlyingValueBN.div(gUniFeiDaiLpTotalSupplyBN);
    set(state, "USD_PRICES.G_UNI_FEI_DAI", gUniFeiDaiLpPriceBN.toString());
    

    // TOTAL PROTOCOL FEI IN FUSE
    const rariPoolsWithProtocolFei = [6,7,8,9,18,19,22,24,25,26,27,28,31,72,79,91];
    let totalProtocolFeiInFuse = new BigNumber(0);
    for (let i = 0; i < rariPoolsWithProtocolFei.length; i++) {
        let currentPoolId = rariPoolsWithProtocolFei[i];
        let feiInCurrentPool = get(state, `RARI.FFEI_${currentPoolId}_PROTOCOL_BALANCE`, "0");
        // console.log(currentPoolId, withCommas(feiInCurrentPool));
        let feiInCurrentPoolBN = new BigNumber(feiInCurrentPool);
        totalProtocolFeiInFuse = totalProtocolFeiInFuse.plus(feiInCurrentPoolBN);
    } 
    set(state, "RARI.TOTAL_PROTOCOL_FEI_IN_FUSE", totalProtocolFeiInFuse.toString());

    // TOTAL PROTOCOL FEI IN KASHI
    const kashiPoolsWithProtocolFei = ["TRIBE", "ETH", "XSUSHI", "DPI"];
    let totalProtocolFeiInKashi = new BigNumber(0);
    for (let k = 0; k < kashiPoolsWithProtocolFei.length; k++) {
        let currentPoolAsset = kashiPoolsWithProtocolFei[k];
        let feiInCurrentPool = get(state, `KASHI.FEI_${currentPoolAsset}_FEI_PROTOCOL_BALANCE`, "0");
        let feiInCurrentPoolBN = new BigNumber(feiInCurrentPool);
        totalProtocolFeiInKashi = totalProtocolFeiInKashi.plus(feiInCurrentPoolBN);
    }
    set(state, "KASHI.TOTAL_PROTOCOL_FEI_IN_KASHI", totalProtocolFeiInKashi.toString());

    // Underlying FEI + DPI in FEI-DPI PCV deposit
    const dpiFeiLpTotalSupply = get(state, "SUSHI.DPI_FEI.TOTAL_SUPPLY");
    const dpiFeiLpUnderlyingFei = get(state, "SUSHI.DPI_FEI.UNDERLYING_FEI");
    const dpiFeiLpUnderlyingDpi = get(state, "SUSHI.DPI_FEI.UNDERLYING_DPI");
    const dpiFeiLpProtocolBalance = get(state, "SUSHI.DPI_FEI.PROTOCOL_LP_BALANCE");

    if(dpiFeiLpTotalSupply) {
        const dpiFeiLpTotalSupplyBN = new BigNumber(dpiFeiLpTotalSupply);
        const dpiFeiLpUnderlyingFeiBN = new BigNumber(dpiFeiLpUnderlyingFei);
        const dpiFeiLpUnderlyingDpiBN = new BigNumber(dpiFeiLpUnderlyingDpi);
        const dpiFeiLpProtocolBalanceBN = new BigNumber(dpiFeiLpProtocolBalance);
    
        const protocolShareOfLPTokenBN = dpiFeiLpProtocolBalanceBN.div(dpiFeiLpTotalSupplyBN);
        const protocolOwnedDpiInDpiFeiLp = dpiFeiLpUnderlyingDpiBN.times(protocolShareOfLPTokenBN);
        const protocolOwnedFeiInDpiFeiLp = dpiFeiLpUnderlyingFeiBN.times(protocolShareOfLPTokenBN);
    
        set(state, "SUSHI.DPI_FEI.PROTOCOL_OWNED_DPI", protocolOwnedDpiInDpiFeiLp.toString());
        set(state, "SUSHI.DPI_FEI.PROTOCOL_OWNED_FEI", protocolOwnedFeiInDpiFeiLp.toString());
    }

    const feiLusdLiqBootstrappingPoolTokenAddresses = get(state, "FEI_LUSD_LIQ_BOOTSTRAPPING_POOL.TOKEN_ADDRESSES");
    const feiLusdLiqBootstrappingPoolTokenBalances = get(state, "FEI_LUSD_LIQ_BOOTSTRAPPING_POOL.TOKEN_BALANCES");
    if (feiLusdLiqBootstrappingPoolTokenAddresses) {
        if (feiLusdLiqBootstrappingPoolTokenAddresses[1] === get(contracts, "Fei._address")) {
            set(state, "FEI_LUSD_LIQ_BOOTSTRAPPING_POOL.FEI_BALANCE", feiLusdLiqBootstrappingPoolTokenBalances[1]);
        }
        if (feiLusdLiqBootstrappingPoolTokenAddresses[0] === get(contracts, "Lusd._address")) {
            set(state, "FEI_LUSD_LIQ_BOOTSTRAPPING_POOL.LUSD_BALANCE", feiLusdLiqBootstrappingPoolTokenBalances[0]);
        }
    }

    // RETURN
    return state;
}

function getWatcherModel({
    contracts,
    connectedAccount,
}) {
    const hasConnectedAccount = !!connectedAccount;

    // The base watcher model includes all the unconditional queries
    const baseWatcherModel = getBaseWatcherModel(
        contracts,
        hasConnectedAccount,
        connectedAccount
    ); 

    // Special multicall functions that don't require target
    const targetlessWatcherModel = getTargetlessWatcherModel(
        contracts,
        hasConnectedAccount,
        connectedAccount
    );

    const balancesWatcherModel = getBalancesWatcherModel(
        contracts,
        hasConnectedAccount,
        connectedAccount
    );

    const allowancesWatcherModel = getAllowancesWatcherModel(
        contracts,
        hasConnectedAccount,
        connectedAccount
    ); 
    
    const tribalChiefWatcherModel = getTribalChiefWatcherModel(
        contracts,
        hasConnectedAccount,
        connectedAccount
    );

    return baseWatcherModel
        .concat(balancesWatcherModel)
        .concat(tribalChiefWatcherModel)
        .concat(allowancesWatcherModel)
        .filter(({target}) => {
            // Remove calls without valid contract addresses
            return !!target;
        })
        .concat(targetlessWatcherModel)
        .filter(({active}) => {
            // Remove calls labeled as not active
            return !!active;
        });

}

function getBaseWatcherModel(
    contracts,
    hasConnectedAccount,
    connectedAccount
) {
    return [
        {
            target: get(contracts, "GenesisGroup._address"),
            call: ["committedFGEN(address)(uint256)", connectedAccount],
            returns: [["BALANCES.FGEN_COMMITTED", val => fromWei(val, "FGEN")]],
            active: hasConnectedAccount,
        },
        {
            target: get(contracts, "GenesisGroup._address"),
            call: ["totalCommittedFGEN()(uint256)"],
            returns: [["GENESIS.FGEN_TOTAL_COMMITTED", val => fromWei(val, "FGEN")]],
            active: true,
        },
        {
            target: get(contracts, "UsdcEthUniswapV2Pair._address"),
            call: ["getReserves()(uint112,uint112)"],
            returns: [
                ["UNI_USDC_ETH.USDC_RESERVES", val => fromWei(val, "USDC")],
                ["UNI_USDC_ETH.ETH_RESERVES", val => fromWei(val, "ETH")],
            ],
            active: true,
        },
        {
            target: get(contracts, "IDO._address"),
            call: ["getReserves()(uint112,uint112)"],
            returns: [
                ["UNI_FEI_TRIBE.FEI_RESERVES", val => fromWei(val, "FEI")],
                ["UNI_FEI_TRIBE.TRIBE_RESERVES", val => fromWei(val, "TRIBE")],
            ],
            active: true,
        },
        {
            target: get(contracts, "IndexEthSushiswapV2Pair._address"),
            call: ["getReserves()(uint112,uint112)"],
            returns: [
                ["SUSHI_INDEX_ETH.INDEX_RESERVES", val => fromWei(val, "INDEX")],
                ["SUSHI_INDEX_ETH.ETH_RESERVES", val => fromWei(val, "ETH")],
            ],
            active: true,
        },
        {
            target: get(contracts, "FeiTribeUniswapV2Pair._address"),
            call: ["totalSupply()(uint256)"],
            returns: [["UNI_FEI_TRIBE.TRIBE_FEI_LP_SUPPLY", val => fromWei(val, "TRIBE_FEI_LP")]],
            active: true,
        },
        {
            target: get(contracts, "GenesisGroup._address"),
            call: ["totalSupply()(uint256)"],
            returns: [
                ["GENESIS.FGEN_TOTAL_SUPPLY", val => fromWei(val, "FGEN")],
            ],
            active: true,
        },
        // Genesis redeemable amounts
        {
            target: get(contracts, "GenesisGroup._address"),
            call: ["getAmountsToRedeem(address)(uint256,uint256,uint256)", connectedAccount],
            returns: [
                ["GENESIS.REDEEMABLE_GENESIS_FEI", val => fromWei(val, "FEI")],
                ["GENESIS.REDEEMABLE_GENESIS_TRIBE", val => fromWei(val, "TRIBE")],
                ["GENESIS.REDEEMABLE_IDO_TRIBE", val => fromWei(val, "TRIBE")],
            ],
            active: hasConnectedAccount,
        },
        // Redeemable TRIBE from staking pool
        {
            target: get(contracts, "FeiStakingRewards._address"),
            call: ["earned(address)(uint256)", connectedAccount],
            returns: [["STAKING.REDEEMABLE_TRIBE_REWARD", val =>  fromWei(val, "TRIBE")]],
            active: hasConnectedAccount,
        },
        {
            target: get(contracts, "FeiStakingRewards._address"),
            call: ["getRewardForDuration()(uint256)"],
            returns: [["STAKING.REWARD_FOR_DURATION", val =>  fromWei(val, "TRIBE")]],
            active: true,
        },
        {
            target: get(contracts, "FeiStakingRewards._address"),
            call: ["rewardPerToken()(uint256)"],
            returns: [["STAKING.REWARD_PER_TOKEN", val =>  fromWei(val, "TRIBE")]],
            active: true,
        },
        {
            target: get(contracts, "FeiStakingRewards._address"),
            call: ["totalSupply()(uint256)"],
            returns: [["STAKING.STAKED_LP_SUPPLY", val =>  fromWei(val, "STAKED_TRIBE_FEI_LP")]],
            active: true,
        },
        // FEI-ETH liquidity pool reserves
        {
            target: get(contracts, "UniswapIncentive._address"),
            call: ["getReserves()(uint256,uint256)"],
            returns: [
                ["UNI_FEI_ETH.FEI_RESERVES", val => fromWei(val, "FEI")],
                ["UNI_FEI_ETH.ETH_RESERVES", val => fromWei(val, "ETH")],
            ],
            active: true,
        },

        // Timeweight
        {
            target: get(contracts, "UniswapIncentive._address"),
            call: ["getTimeWeight()(uint32)"],
            returns: [["INCENTIVES.TIME_WEIGHT", val  => fromWei(val, "TIME_WEIGHT")]],
            active: true,
        },
        // FIP-20 reserve stabilizer
        {
            target: get(contracts, "EthReserveStabilizerFipTwenty._address"),
            call: ["getAmountOut(uint256)(uint256)", toWei("1", "FEI")],
            returns: [["RESERVE_STABILIZATION.ETH_PER_FEI", val => fromWei(val, "ETH")]],
            active: true,
        },
        // Peg price
        {
            target: get(contracts, "BondingCurveOracle._address"),
            call: ["read()(uint256)"],
            returns: [["INCENTIVES.PEG_PRICE", val => fromWei(val, "FEI")]],
            active: true,
        },
        // PCV
        {
            target: get(contracts, "EthUniswapPCVDeposit._address"),
            call: ["liquidityOwned()(uint256)"],
            returns: [["PCV.FEI_ETH_LP", val => fromWei(val, "FEI_ETH_LP")]],
            active: true,
        },
        {
            target: get(contracts, "EthUniswapPCVDeposit._address"),
            call: ["balance()(uint256)"],
            returns: [["PCV.ETH_UNI_PCV_DEPOSIT", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            target: get(contracts, "FeiEthUniswapV2Pair._address"),
            call: ["totalSupply()(uint256)"],
            returns: [["UNI_FEI_ETH.FEI_ETH_LP_SUPPLY", val => fromWei(val, "FEI_ETH_LP")]],
            active: true,
        },
        {
            target: get(contracts, "EthLidoPCVDeposit._address"),
            call: ["balance()(uint256)"],
            returns: [["LIDO.STETH_IN_PCV", val => fromWei(val, "STETH")]],
            active: true,
        },
        {
            target: get(contracts, "aWETH._address"),
            call: ["balanceOf(address)(uint256)", get(contracts, "AaveEthPCVDeposit._address")],
            returns: [["PCV.ETH_IN_AAVE", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            target: get(contracts, "aRAI._address"),
            call: ["balanceOf(address)(uint256)", get(contracts, "AaveRaiPCVDeposit._address")],
            returns: [["PCV.RAI_IN_AAVE", val => fromWei(val, "RAI")]],
            active: true,
        },
        {
            target: get(contracts, "RariFusePoolNineRai._address"),
            call: ["balanceOfUnderlying(address)(uint256)", get(contracts, "RaiFusePcvDeposit._address")],
            returns: [["PCV.RAI_IN_FUSE", val => fromWei(val, "RAI")]],
            active: true,
        },
        {
            target: get(contracts, "CEther._address"),
            call: ["balanceOfUnderlying(address)(uint256)", get(contracts, "EthCompoundPCVDeposit._address")],
            returns: [["PCV.ETH_IN_COMPOUND", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            target: get(contracts, "CDai._address"),
            call: ["balanceOfUnderlying(address)(uint256)", get(contracts, "DaiCompoundPCVDeposit._address")],
            returns: [["PCV.DAI_IN_COMPOUND", val => fromWei(val, "DAI")]],
            active: true,
        },
        {
            target: get(contracts, "Index._address"),
            call: ["balanceOf(address)(uint256)", get(contracts, "IndexSnapshotDelegatorPCVDeposit._address")],
            returns: [["PCV.INDEX_IN_DELEGATOR", val => fromWei(val, "INDEX")]],
            active: true,
        },
        // Other
        {
            target: get(contracts, "Fei._address"),
            call: ["totalSupply()(uint256)"],
            returns: [["FEI.TOTAL_SUPPLY", val => fromWei(val, "FEI")]],
            active: true,
        },
        {
            target: get(contracts, "EthUniswapPCVController._address"),
            call: ["remainingTime()(uint256)"],
            returns: [["REWEIGHTS.REMAINING_TIME_SECONDS", val => new BigNumber(val)]],
            active: true,
        },
        // Chainlink
        {
            target: get(contracts, "ChainlinkEthUsdAggregator._address"),
            call: ["latestRoundData()(uint80,int256,uint256,uint256,uint80)"],
            returns: [
                ["CHAINLINK.ETH_USD_ROUND", (round) => undefined], // ignore
                ["CHAINLINK.ETH_USD_PRICE", (answer) => fromWei(answer, "CHAINLINK_ETH_USD")],
                ["CHAINLINK.ETH_USD_STARTED_AT", (startedAt) => undefined], // ignore
                ["CHAINLINK.ETH_USD_UPDATED_AT", (updatedAt) => undefined], // ignore
                ["CHAINLINK.ETH_USD_ANSWERED_IN_ROUND", (answeredInRound) => undefined] // ignore
            ],
            active: true,
        },
        {
            target: get(contracts, "DpiUsdChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.DPI_USD_PRICE", (answer) => fromWei(answer, "CHAINLINK_DPI_USD")]
            ],  
            active: true,
        },
        {
            target: get(contracts, "FeiUsdChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.FEI_USD_PRICE", (answer) => fromWei(answer, "CHAINLINK_FEI_USD")]
            ],
            active: true,
        },
        {
            target: get(contracts, "DaiUsdChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.DAI_USD_PRICE", (answer) => fromWei(answer, "CHAINLINK_DAI_USD")]
            ],  
            active: true,
        },
        {
            target: get(contracts, "RaiEthChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.RAI_ETH_PRICE", (answer) => fromWei(answer, "CHAINLINK_RAI_ETH")]
            ],  
            active: true,
        },
        {
            target: get(contracts, "TribeEthChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.TRIBE_ETH_PRICE", (answer) => fromWei(answer, "CHAINLINK_TRIBE_ETH")]
            ],
            active: true,
        },
        {
            target: get(contracts, "LusdUsdChainlinkOracle._address"),
            call: ["latestAnswer()(uint256)"],
            returns: [
                ["CHAINLINK.LUSD_USD_PRICE", (answer) => fromWei(answer, "CHAINLINK_LUSD_USD")]
            ],
            active: true,
        },
    ];
}

function getBalancesWatcherModel(
    contracts,
    hasConnectedAccount,
    connectedAccount
) {
    return [
        {
            contractName: "GenesisGroup",
            tokenSymbol: "FGEN",
            active: hasConnectedAccount,
        },
        {
            contractName: "Fei",
            tokenSymbol: "FEI",
            active: hasConnectedAccount,
        },
        {
            contractName: "Tribe",
            tokenSymbol: "TRIBE",
            active: hasConnectedAccount,
        },
        {
            contractName: "FeiTribeUniswapV2Pair",
            tokenSymbol: "TRIBE_FEI_LP",
            active: hasConnectedAccount,
        },
        {
            contractName: "FeiStakingRewards",
            tokenSymbol: "STAKED_TRIBE_FEI_LP",
            active: hasConnectedAccount,
        },
    ].map(({contractName, tokenSymbol, active}) => {
        return {
            target: get(contracts, `${contractName}._address`),
            call: ["balanceOf(address)(uint256)", connectedAccount],
            returns: [[`BALANCES.${tokenSymbol}`, val => fromWei(val, tokenSymbol)]],
            active,
        }
    });
}

function getTargetlessWatcherModel(
    contracts,
    hasConnectedAccount,
    connectedAccount
) {
    return [
        {
            call: ["getEthBalance(address)(uint256)", connectedAccount],
            returns: [["BALANCES.ETH", val => fromWei(val, "ETH")]],
            active: hasConnectedAccount,
        },
        {
            call: ["getEthBalance(address)(uint256)", get(contracts, "EthPCVDripper._address")],
            returns: [["RESERVE_STABILIZATION.ETH_IN_DRIPPER", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            call: ["getEthBalance(address)(uint256)", get(contracts, "EthBondingCurve._address")],
            returns: [["PCV.ETH_IN_BONDING_CURVE", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            call: ["getEthBalance(address)(uint256)", get(contracts, "EthReserveStabilizerFipTwenty._address")],
            returns: [["RESERVE_STABILIZATION.ETH_AVAILABLE_FIP_20", val => fromWei(val, "ETH")]],
            active: true,
        },
        {
            call: ["getEthBalance(address)(uint256)", get(contracts, "EthReserveStabilizerFipTwo._address")],
            returns: [["RESERVE_STABILIZATION.ETH_AVAILABLE_FIP_2", val => fromWei(val, "ETH")]],
            active: true,
        },
    ];
}

function getAllowancesWatcherModel(
    contracts,
    hasConnectedAccount,
    connectedAccount
) {
    const baseAllowancesConfig =  [
        {
            contractName: "Fei",
            allowedAddress: get(contracts, "FeiRouter._address"),
            tokenSymbol: "FEI",
            allowedAddressName: "FEI_ROUTER",
        },
        {
            contractName: "FeiTribeUniswapV2Pair",
            allowedAddress: get(contracts, "FeiStakingRewards._address"),
            tokenSymbol: "TRIBE_FEI_LP",
            allowedAddressName: "FEI_POOL",
        }
    ];

    const tribalChiefAllowancesConfig = tribalChiefConfig.pools.map(({
        stakingTokenSymbol,
        tokenContractName,
    }) => {
        return {
            tokenAddress: get(contracts, `${tokenContractName}._address`),
            allowedAddress: get(contracts, "TribalChief._address"),
            tokenSymbol: stakingTokenSymbol,
            allowedAddressName: "TRIBAL_CHIEF",
        };
    });

    const customAllowancesConfig = [
        {
            // TRIBE allowance given to fTRIBE contract for minting fTRIBE
            tokenAddress: get(contracts, "Tribe._address", ""),
            allowedAddress: get(contracts, "FeiRariTribe._address", ""),
            tokenSymbol: "TRIBE",
            allowedAddressName: "FTRIBE",
        }
    ];

    const allowancesConfig = 
        baseAllowancesConfig
        .concat(tribalChiefAllowancesConfig)
        .concat(customAllowancesConfig);
    
    return allowancesConfig.map(({ 
        tokenAddress,
        contractName,
        allowedAddress,
        tokenSymbol,
        allowedAddressName
    }) => {
        return {
            target: tokenAddress || get(contracts, `${contractName}._address`),
            call: ["allowance(address,address)(uint256)", connectedAccount, allowedAddress],
            returns: [[`ALLOWANCES.${tokenSymbol}.${allowedAddressName}`, val => fromWei(val, tokenSymbol)]],
            active: hasConnectedAccount,
        }
    });
}

function getTribalChiefWatcherModel(
    contracts,
    hasConnectedAccount,
    connectedAccount
) {

    const pools = tribalChiefConfig.pools;

    const allocationWatchers = pools.map(({
        poolId,
        stakingTokenSymbol,
    }) => {
        return {
            target: get(contracts, "TribalChief._address"),
            call: ["poolInfo(uint256)(uint256,uint256,uint128,uint120,bool)", poolId],
            returns: [
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                [`TRIBAL_CHIEF.ALLOCATION_POINTS.${stakingTokenSymbol}`, val => val.toString()],
                ["SKIP", val => null], // ignore
            ],
            active: true,
        };
    })

    const pendingRewardsWatchers = pools.map(({
        poolId,
        stakingTokenSymbol
    }) => {
        return {
            target: get(contracts, "TribalChief._address"),
            call: ["pendingRewards(uint256,address)(uint256)", poolId, connectedAccount],
            returns: [
                [`TRIBAL_CHIEF.ALL_PENDING_REWARDS.${stakingTokenSymbol}`, val => fromWei(val, "TRIBE")]
            ],
            active: hasConnectedAccount,
        };
    });

    const stakedBalancesWatchers = pools.map(({
        poolId,
        stakingTokenSymbol
    }) => {
        return {
            target: get(contracts, "TribalChief._address"),
            call: ["getTotalStakedInPool(uint256,address)(uint256)", poolId, connectedAccount],
            returns: [[`TRIBAL_CHIEF.STAKED_BALANCE.${stakingTokenSymbol}`, val => fromWei(val, stakingTokenSymbol)]],
            active: hasConnectedAccount,
        };
    });



    const stakedTokenUserBalancesWatcher = pools.map(({
        stakingTokenSymbol,
        tokenContractName,
    }) => {
        return {
            target: get(contracts, `${tokenContractName}._address`),
            call: ["balanceOf(address)(uint256)", connectedAccount],
            returns: [[`BALANCES.${stakingTokenSymbol}`, val => fromWei(val, stakingTokenSymbol)]],
            active: hasConnectedAccount,
        };
    });

    const stakedTokenBalancesWatcher = pools.map(({
        stakingTokenSymbol,
        tokenContractName,
    }) => {
        return {
            target: get(contracts, `${tokenContractName}._address`),
            call: ["balanceOf(address)(uint256)", get(contracts, "TribalChief._address")],
            returns: [[`TRIBAL_CHIEF.TOTAL_STAKED.${stakingTokenSymbol}`, val => fromWei(val, stakingTokenSymbol)]],
            active: true,
        };
    });


    return [
        {
            target: get(contracts, "Curve3MetapoolToken._address"),
            call: ["get_virtual_price()(uint256)"],
            returns: [["USD_PRICES.FEI3CRV", val => fromWei(val, "FEI3CRV")]],
            active: true,
        },
        {
            target: get(contracts, "GelatoUniswapDaiFeiLp._address"),
            call: ["getUnderlyingBalances()(uint256,uint256)"],
            returns: [
                ["GELATO.FEI_DAI_G_UNI.TOTAL_UNDERLYING_DAI", val => fromWei(val, "DAI")],
                ["GELATO.FEI_DAI_G_UNI.TOTAL_UNDERLYING_FEI", val => fromWei(val, "FEI")],
            ],
            active: true,
        },
        {
            target: get(contracts, "GelatoUniswapDaiFeiLp._address"),
            call: ["totalSupply()(uint256)"],
            returns: [["GELATO.FEI_DAI_G_UNI.TOTAL_LP_SUPPLY", val => fromWei(val, "FEI")]],
            active: true,
        },
        {
            target: get(contracts, "TribalChief._address"),
            call: ["totalAllocPoint()(uint256)"],
            returns: [["TRIBAL_CHIEF.TOTAL_ALLOC_POINTS", val => val.toString()]],
            active: true,
        },
        {
            target: get(contracts, "TribalChief._address"),
            call: ["tribePerBlock()(uint256)"],
            returns: [["TRIBAL_CHIEF.TRIBE_PER_BLOCK", val => fromWei(val, "TRIBE")]],
            active: true,
        },
        {
            target: get(contracts, "AaveTribeIncentivesController._address"),
            call: ["getAssetData(address)(uint256,uint256,uint256)", get(contracts, "AFeiVariableDebt._address", "")],
            returns: [
                ["SKIP", val => null], // ignore
                ["TRIBAL_CHIEF.AAVE.REWARDS_PER_SECOND", val => fromWei(val, "TRIBE")],
                ["SKIP", val => null], // ignore
            ],
            active: true
        },
        {
            target: get(contracts, "AaveTribeIncentivesController._address"),
            call: [
                "getRewardsBalance(address[],address)(uint256)", 
                [get(contracts, "AFeiVariableDebt._address", "")],
                connectedAccount,
            ],
            returns: [["TRIBAL_CHIEF.AAVE.USER_UNCLAIMED_REWARDS", val => fromWei(val, "TRIBE")]],
            active: hasConnectedAccount,
        },
        {
            target: get(contracts, "AFeiVariableDebt._address", ""),
            call: ["balanceOf(address)(uint256)", connectedAccount],
            returns: [["TRIBAL_CHIEF.AAVE.USER_A_FEI_BALANCE", val => fromWei(val, "FEI")]],
            active: hasConnectedAccount,
        },
        {
            target: get(contracts, "AFeiVariableDebt._address", ""),
            call: ["totalSupply()(uint256)"],
            returns: [["TRIBAL_CHIEF.AAVE.TOTAL_A_FEI_SUPPLY", val => fromWei(val, "FEI")]],
            active: true,
        },
        {
            target: get(contracts, "FeiRariTribe._address", ""),
            call: ["exchangeRateCurrent()(uint256)"],
            returns: [["TRIBAL_CHIEF.FTRIBE_TRIBE_EXCHANGE_RATE", val => fromWei(val, "FTRIBE")]],
            active: true,
        },
        {
            target: get(contracts, "FeiRariTribe._address", ""),
            call: ["totalSupply()(uint256)"],
            returns: [["TRIBAL_CHIEF.FTRIBE_TOTAL_SUPPLY", val => fromWei(val, "FTRIBE")]],
            active: true,
        },
        {
            target: get(contracts, "FeiRariTribe._address", ""),
            call: ["balanceOfUnderlying(address)(uint256)", connectedAccount],
            returns: [["TRIBAL_CHIEF.USER_TRIBE_UNDERLYING_FTRIBE", val => fromWei(val, "TRIBE")]],
            active: hasConnectedAccount,
        },
        {
            target: get(contracts, "RariRewardsDistributor._address", ""),
            call: ["getUnclaimedRewardsByDistributors(address,address[])(address[],uint256[],address[][],uint256[2][][],uint256[])", connectedAccount, ["0x73F16f0c0Cd1A078A54894974C5C054D8dC1A3d7"]],
            returns: [
                ["SKIP", val => null], // ignore
                ["TRIBAL_CHIEF.USER_UNCLAIMED_TRIBE_REWARDS_ON_FTRIBE_POOL", val => fromWei(val[0], "TRIBE")], // ignore
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
            ],
            active: hasConnectedAccount,

        },
        {
            target: get(contracts, "TribalChief._address"),
            call: ["poolInfo(uint256)(uint256,uint256,uint128,uint120,bool)", "3"],            
            returns: [
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                ["TRIBAL_CHIEF.ALLOCATION_POINTS.FTRIBE", val => val.toString()],
                ["SKIP", val => null], // ignore
            ],
            active: true,
        },
    ]
        .concat(pendingRewardsWatchers)
        .concat(stakedBalancesWatchers)
        .concat(stakedTokenUserBalancesWatcher)
        .concat(stakedTokenBalancesWatcher)
        .concat(allocationWatchers);
}