import get from "lodash/get";
import {formatCryptoAmount, formatDollarAmount, fromWei, toWei} from "../../utils/numbers";
import useTransactionStatusManager from "./transactionStatusManager";
import {getExchangeTransactionDeadline} from "../../utils/time";
import {NUMBERS} from "../../constants";
import {track} from "../analytics";
import {CONTEXT_NAMES, EVENT_TYPES} from "../analytics/events";

export default function useTransactions({
    connectedAccount,
    contracts,
    alchemyProvider,
    recreateWatcher,
}) {
    const {
        handleTransactionSubmissionError,
        monitorSubmittedTransaction,
    } = useTransactionStatusManager({
        alchemyProvider,
        recreateWatcher,
    });

    if (!contracts || !connectedAccount) {
        return {};
    }

    const transactionsConfig = [
        // GENESIS
        {
            transactionName: "GenesisPurchase",
            contractName: "GenesisGroup",
            methodName: "purchase",
            generateArguments: ({ethAmount}) => {
                const ethAmountInWei = toWei(ethAmount, "ETH");
                return {
                    txActionMessage: `commit ${formatCryptoAmount(ethAmount)} ETH`,
                    methodArgs: [
                        connectedAccount,
                        ethAmountInWei,
                    ],
                    sendArgs: {
                        value: ethAmountInWei
                    }
                };
            },
        },
        {
            transactionName: "GenesisCommit",
            contractName: "GenesisGroup",
            methodName: "commit",
            generateArguments: ({fgenAmount}) => {
                const fgenAmountInWei = toWei(fgenAmount, "FGEN");
                return {
                    txActionMessage: `pre-swap ${formatCryptoAmount(fgenAmount)} ETH`,
                    methodArgs: [
                        connectedAccount,
                        connectedAccount,
                        fgenAmountInWei
                    ],
                };
            },
        },
        {
            transactionName: "GenesisRedeem",
            contractName: "GenesisGroup",
            methodName: "redeem",
            generateArguments: () => {
                return {
                    txActionMessage: "redeem your Genesis tokens",
                    methodArgs: [connectedAccount],
                };
            },
        },
        // TOKEN APPROVALS
        {
            transactionName: "SetMaxFeiAllowanceOnFeiRouter",
            contractName: "Fei",
            methodName: "approve",
            generateArguments: () => {
                const feiRouterAddress = get(contracts, "FeiRouter._address");
                return {
                    txActionMessage: "set FEI allowance",
                    methodArgs: [
                        feiRouterAddress,
                        NUMBERS.MAX_UINT_256,
                    ],
                };
            },
        },
        {
            transactionName: "SetMaxFeiTribeLpAllowanceOnStakingPool",
            contractName: "FeiTribeUniswapV2Pair",
            methodName: "approve",
            generateArguments: () => {
                const stakingRewardsAddress = get(contracts, "FeiStakingRewards._address");
                return {
                    txActionMessage: "set FEI-TRIBE LP allowance",
                    methodArgs: [
                        stakingRewardsAddress,
                        NUMBERS.MAX_UINT_256,
                    ],
                };
            }
        },
        // {
        //     transactionName: "ApproveTribalChiefTribeOnTribeWrapper",
        //     contractName: "FuseTribeOnTribeStakingWrapperToken",
        //     methodName: "approve",
        //     generateArguments: () => {
        //         const tribalChiefAddress = get(contracts, "TribalChief._address");
        //         return {
        //             txActionMessage: "set fuse TRIBE allowance for Tribal Chief",
        //             methodArgs: [
        //                 tribalChiefAddress,
        //                 NUMBERS.MAX_UINT_256,
        //             ],
        //         };
        //     }
        // },
        {
            transactionName: "ApproveTribalChiefCurve3Metapool",
            contractName: "Curve3MetapoolToken",
            methodName: "approve",
            generateArguments: () => {
                const tribalChiefAddress = get(contracts, "TribalChief._address");
                return {
                    txActionMessage: "set Curve 3 Metapool allowance for Tribal Chief",
                    methodArgs: [
                        tribalChiefAddress,
                        NUMBERS.MAX_UINT_256,
                    ],
                };
            }
        },
        {
            transactionName: "ApproveTribalChiefFeiTribeLp",
            contractName: "FeiTribeUniswapV2Pair",
            methodName: "approve",
            generateArguments: () => {
                const tribalChiefAddress = get(contracts, "TribalChief._address");
                return {
                    txActionMessage: "set FEI-TRIBE LP allowance for Tribal Chief",
                    methodArgs: [
                        tribalChiefAddress,
                        NUMBERS.MAX_UINT_256,
                    ],
                };
            }
        },
        {
            transactionName: "ApproveGUniFeiDaiLp",
            contractName: "GelatoUniswapDaiFeiLp",
            methodName: "approve",
            generateArguments: () => {
                const tribalChiefAddress = get(contracts, "TribalChief._address");
                return {
                    txActionMessage: "set G-UNI FEI-DAI LP allowance for Tribal Chief",
                    methodArgs: [
                        tribalChiefAddress,
                        NUMBERS.MAX_UINT_256,
                    ],
                };
            }
        },
        {
            transactionName: "ApproveFTribeTribe",
            contractName: "Tribe",
            methodName: "approve",
            generateArguments: () => {
                return {
                    txActionMessage: "set TRIBE allowance for Rari Fuse Pool 8 (FeiRari)",
                    methodArgs: [
                        get(contracts, "FeiRariTribe._address", ""),
                        NUMBERS.MAX_UINT_256,
                    ],
                }
            },
        },
        {
            transactionName: "MintFTribeInFeiRari",
            contractName: "FeiRariTribe",
            methodName: "mint",
            generateArguments: ({depositAmount}) => {
                return {
                    txActionMessage: "deposit TRIBE into the FeiRari Fuse lending pool",
                    methodArgs: [
                        toWei(depositAmount, "TRIBE"),
                    ],
                };
            },
        },
        {
            transactionName: "RedeemFTribeForTribeInFeiRari",
            contractName: "FeiRariTribe",
            methodName: "redeemUnderlying",
            generateArguments: ({ tribeToRedeem }) => {
                return {
                    txActionMessage: "redeem fTRIBE for TRIBE",
                    methodArgs: [
                        toWei(tribeToRedeem, "TRIBE"),
                    ]
                }
            }
        },
        {
            transactionName: "ClaimFTribeRewards",
            methodName: "claimRewards",
            contractName: "RariFTribeRewardsDistributorDelegator",
            generateArguments: () => {
                return {
                    txActionMessage: "claim TRIBE rewards for the fTRIBE pool",
                    methodArgs: [
                        [connectedAccount],
                        [get(contracts, "FeiRariTribe._address", "")],
                        false,
                        true,
                    ]
                }
            }
        },
        // TRADING
        {
            transactionName: "BuyFei",
            contractName: "FeiRouter",
            methodName: "buyFei",
            generateArguments: ({
                amountIn,
                amountOutMin,
                minReward,
                amountOutPlusReward,
            }) => {
                const deadline = getExchangeTransactionDeadline();
                return {
                    txActionMessage: `buy ${formatDollarAmount(amountOutPlusReward)} FEI`,
                    methodArgs: [
                        toWei(minReward, "FEI"),
                        toWei(amountOutMin, "FEI"),
                        connectedAccount,
                        deadline,
                    ],
                    sendArgs: {
                        value: toWei(amountIn, "ETH")
                    }
                }
            }
        },
        {
            transactionName: "SellFei",
            contractName: "FeiRouter",
            methodName: "sellFei",
            generateArguments: ({
                amountIn,
                maxPenalty,
                amountOutMin,
            }) => {
                const deadline = getExchangeTransactionDeadline();
                return {
                    txActionMessage: `sell ${formatDollarAmount(amountIn)} FEI`,
                    methodArgs: [
                        toWei(maxPenalty, "FEI"),
                        toWei(amountIn, "FEI"),
                        toWei(amountOutMin, "ETH"),
                        connectedAccount,
                        deadline,
                    ],
                };
            },
        },
        // STAKING
        {
            transactionName: "StakeLpTokens",
            contractName: "FeiStakingRewards",
            methodName: "stake",
            generateArguments: ({ lpTokenAmount }) => {
                return {
                    txActionMessage: `stake ${formatCryptoAmount(lpTokenAmount)} FEI-TRIBE LP`,
                    methodArgs: [
                        toWei(lpTokenAmount, "TRIBE_FEI_LP")
                    ],
                };
            },
        },
        {
            transactionName: "ClaimStakingRewards",
            contractName: "FeiStakingRewards",
            methodName: "getReward",
            generateArguments: () => {
                return {
                    txActionMessage: "claim TRIBE rewards",
                    methodArgs: [],
                };
            },
        },
        {
            transactionName: "WithdrawStakedLpTokens",
            contractName: "FeiStakingRewards",
            methodName: "exit",
            generateArguments: () => {
                    return {
                        txActionMessage: "withdraw FEI-TRIBE LP and TRIBE rewards",
                        methodArgs: [],
                    };
            },
        },
        // TRIBAL CHIEF
        {
            transactionName: "TribalChiefDeposit",
            contractName: "TribalChief",
            methodName: "deposit",
            generateArguments: ({
                tokenSymbol,
                poolId,
                depositAmount
            }) => {
                return {
                    txActionMessage: `stake ${tokenSymbol} in TribalChief`,
                    methodArgs: [
                        poolId,
                        toWei(depositAmount, tokenSymbol),
                        0,
                    ],
                };
            },
        },
        {
            transactionName: "TribalChiefHarvest",
            contractName: "TribalChief",
            methodName: "harvest",
            generateArguments: ({
                tokenSymbol,
                poolId,
            }) => {
                return {
                    txActionMessage: `claim rewards on your ${tokenSymbol} deposit`,
                    methodArgs: [
                        poolId,
                        connectedAccount,
                    ],
                };
            }
        },
        {
            transactionName: "TribalChiefHarvestAndWithdraw",
            contractName: "TribalChief",
            methodName: "withdrawAllAndHarvest",
            generateArguments: ({
                tokenSymbol,
                poolId
            }) => {
                return {
                    txActionMessage: `withdraw and claim rewards on your ${tokenSymbol} deposit`,
                    methodArgs: [
                        poolId,
                        connectedAccount,
                    ]
                }
            }
        },
        {
            transactionName: "ClaimAaveAFeiTribeRewards",
            contractName: "AaveTribeIncentivesController",
            methodName: "claimRewards",
            generateArguments: ({amount}) => {
                return {
                    txActionMessage: `claim rewards on the Aave debtFEI`,
                    methodArgs: [
                        ["0xC2e10006AccAb7B45D9184FcF5b7EC7763f5BaAe"], // aaveVariableDebtFei
                        toWei(amount, "TRIBE"),
                        connectedAccount,
                    ]
                }
            }
        },
        // RESERVE STABILIZATION
        {
            transactionName: "ExchangeFeiOnReserveStabilizer",
            contractName: "EthReserveStabilizerFipTwenty",
            methodName: "exchangeFei",
            generateArguments: ({ feiAmount }) => {
                return {
                    txActionMessage: "exchange FEI for ETH",
                    methodArgs: [
                        toWei(feiAmount, "FEI")
                    ],
                }
            }
        }
    ];

    const transactions = {};

    transactionsConfig.map(({
        transactionName,
        contractName,
        methodName,
        generateArguments,
    }) => {

        transactions[transactionName] = async (
            transactionArgs,
            eventHandlers,
        ) => {

            const onBroadcast = get(eventHandlers, "onBroadcast", () => {});
            const onConfirm = get(eventHandlers, "onConfirm", () => {});
            const onFailure = get(eventHandlers, "onFailure", () => {});

            const {
                txActionMessage,
                methodArgs,
                sendArgs = {},
            } = generateArguments(transactionArgs);

            const trackingData = {
                transactionName,
                contractName,
                methodName,
                connectedAccount,
                ...transactionArgs,
            };

            const method = get(contracts, `${contractName}.methods.${methodName}`);

            try {
                await method.apply(null, methodArgs).send(sendArgs, (error, txHash) => {

                    trackingData.txHash = txHash;

                    track({
                        contextName: CONTEXT_NAMES.TRANSACTIONS,
                        eventType: EVENT_TYPES.TX_SUBMITTED,
                        eventName: transactionName,
                    }, trackingData);

                    if (txHash) {
                        monitorSubmittedTransaction({
                            txHash,
                            txActionMessage,
                            trackingData,
                            onTxBroadcast: onBroadcast,
                            onTxSuccess: () => {
                                onConfirm();
                                recreateWatcher();
                            },
                            onTxRevert: () => {
                                onFailure();
                            },
                        });
                    }
                });
           
            } catch (error) {
                handleTransactionSubmissionError({
                    error,
                    txActionMessage,
                    trackingData
                });
                onFailure();
            }
        }

        // TODO: figure out why this is not working. It gives out a gas estimate that
        // seems lower than it is when metamask opens up to confirm the tx
        transactions[`${transactionName}GasEstimate`] = async (transactionArgs) => {
            const {
                methodArgs,
                sendArgs = {},
            } = generateArguments(transactionArgs);
            const method = get(contracts, `${contractName}.methods.${methodName}`);
            console.log({sendArgs})
            let gasEstimate;
            try {
                gasEstimate = await method.apply(null, methodArgs).estimateGas(sendArgs)
            } catch (error) {
                console.log("ERROR estimating gas", error);
            }
            return fromWei(gasEstimate, "ETH");
        };
    })

    return transactions;
}