const sharedConfig = {
    links: {
        discord: "https://discord.gg/2prhYdQ5jP",
        telegram: "https://t.me/feiprotocol",
        twitter: "https://twitter.com/feiprotocol",
        github: "https://github.com/fei-protocol/fei-protocol-core",
        medium: "https://medium.com/fei-protocol/introducing-fei-protocol-2db79bd7a82b",
    },
    defaultSlippageParam: 0.005,
    exchangeTransactionDeadlineMinutes: 20,
    dedicatedWalletConnectBridgeUrl: "https://fei.bridge.walletconnect.org"
};

export default sharedConfig;