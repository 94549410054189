import React from "react";
import { DefaultToast } from "react-toast-notifications";
import { BodyText } from "../Text";
import Flex from "../Flex";
import Link from "../Link";
import { colors } from "../../../providers/theme/theme";
import {CONTEXT_NAMES, EVENTS} from "../../../providers/analytics/events";
import ethConfig from "../../../providers/ethereum/config";

export default function EtherscanLinkToast({ children, transactionHash, ...props }) {
    const onClickEtherscanLink = () => {
        if (transactionHash) {
            window.open(`${ethConfig.etherscanUrl}/tx/${transactionHash}`);
        }
    }
    return (
        <DefaultToast {...props}>
            <Flex {...styles.ContentContainer}>
                <BodyText
                    dark paragraph
                    text={children}
                />
                {
                    transactionHash && (
                        <Link
                            onClick={onClickEtherscanLink}
                            text={"See Etherscan"}
                            tracking={{
                                eventName: EVENTS.TRANSACTIONS.TOAST_ETHERSCAN,
                                contextName: CONTEXT_NAMES.TRANSACTIONS,
                                data: {
                                    transactionHash,
                                    message: children,
                                }
                            }}
                            {...styles.EtherscanLink}
                        />
                    )
                }
            </Flex>

        </DefaultToast>
    );
}

const styles = {
    ContentContainer: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    EtherscanLink: {
        color: colors.blue.link,
        hasUnderline: true,
        hoverColor: colors.blue.link,
        width: "100%",
        textAlign: "right",
        marginTop: 10,
    },
};