
export const CURRENCY_DECIMALS = {
    USDC: 6,
    FEI: 18,
    FGEN: 18,
    TRIBE: 18,
    ETH: 18,
    FEI_ETH_LP: 18,
    DPI_FEI_LP: 18,
    TRIBE_FEI_LP: 18,
    STAKED_TRIBE_FEI_LP: 18,
    TIME_WEIGHT: 5,
    CHAINLINK_ETH_USD: 8,
    CHAINLINK_DPI_USD: 8,
    CHAINLINK_FEI_USD: 8,
    CHAINLINK_RAI_ETH: 18,
    CHAINLINK_DAI_USD: 8,
    CHAINLINK_TRIBE_ETH: 18,
    CHAINLINK_LUSD_USD: 8,
    FFEI: 18,
    STETH: 18,
    DPI: 18,
    RAI: 18,
    DAI: 18,
    INDEX: 18,
    TRIBAL_CHIEF: 18,
    FFEI: 18,
    FTRIBE: 18,
    FEI3CRV: 18,
    G_UNI_FEI_DAI: 18,
    FTRIBE: 8,
};

export const NUMBERS = {
    MAX_UINT_256: "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
}