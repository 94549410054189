import { useEffect, useState } from "react";
import {useEthereum} from "../../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";

export function useGenesisCommitLogic({
    formContainerProps
}) {
    // Setup state
    const [inputField, setInputField] = useState({
        fgenAmountInput:"",
        maxAmountSelected: false,
    });
    const {
        fgenAmountInput,
        maxAmountSelected,
    } = inputField;
    const setFgenAmountInput = (newValue) => {
        setInputField({
            maxAmountSelected: false,
            fgenAmountInput: newValue,
        });
    };

    const [formError, setFormError] = useState("");
    const [broadcastingTx, setBroadcastingTx] = useState(false);
    const [confirmingTx, setConfirmingTx] = useState(false);

    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();
    
    const fgenBalance = get(protocolState, "BALANCES.FGEN", "0");
    const committedFgenBalance = get(protocolState, "BALANCES.FGEN_COMMITTED", "0");

    const fgenAmountInputBN = new BigNumber(fgenAmountInput || 0);
    const committedFgenBalanceBN = new BigNumber(committedFgenBalance);
    const fgenBalanceBN =  new BigNumber(fgenBalance);
    const buttonDisabled = !fgenBalance || fgenBalanceBN.lte(0);

    const totalEthDepositedInGenesisByUserBN = fgenBalanceBN.plus(committedFgenBalanceBN);
    const currentPreswapRatioBN = totalEthDepositedInGenesisByUserBN.gt(0) ?
        committedFgenBalanceBN.div(totalEthDepositedInGenesisByUserBN) :
        new BigNumber(0);

    let preswapRatioAfterActionBN = totalEthDepositedInGenesisByUserBN.gt(0) ?
        committedFgenBalanceBN.plus(fgenAmountInputBN).div(totalEthDepositedInGenesisByUserBN) :
        new BigNumber(0);
    if (preswapRatioAfterActionBN.gt(1)) {
        preswapRatioAfterActionBN = new BigNumber(1);
    }
    if (preswapRatioAfterActionBN.lt(currentPreswapRatioBN)) {
        preswapRatioAfterActionBN = currentPreswapRatioBN;
    }
    
    const preswapPercentBeforeAction = currentPreswapRatioBN.times(100).dp(0).toString();
    const preswapPercentAfterAction = preswapRatioAfterActionBN.times(100).dp(0).toString();

    useEffect(() => {
        if (formError) {
            setFormError("");
        }
    }, [fgenAmountInput])

    const onClickGenesisCommit = async () => {
        if (!fgenAmountInput) {
            setFormError("Enter pre-swap amount");
            return;
        }

        if (fgenAmountInputBN.lte(0)) {
            setFormError("Please enter a valid amount");
            return;
        }

        if (fgenAmountInputBN.gt(fgenBalanceBN)) {
            setFormError("Insufficient ETH available");
            return;
        }

        setBroadcastingTx(true);
        const fgenAmountToSubmit = maxAmountSelected ?
            fgenBalance :
            fgenAmountInput;

        await transactions.GenesisCommit({
            fgenAmount: fgenAmountToSubmit
        }, {
            onBroadcast: () => {
                setBroadcastingTx(false);
                setConfirmingTx(true);
            },
            onConfirm: () => {
                setConfirmingTx(false);
                setFgenAmountInput("");
            },
            onFailure: () => {
                setBroadcastingTx(false);
                setConfirmingTx(false);
                setFgenAmountInput("");
            },
        });
    };

    const onClickMax = () => {
        const formattedFgenBalance = fgenBalanceBN.dp(6, BigNumber.ROUND_DOWN).toString();
        setInputField({
            fgenAmountInput: formattedFgenBalance,
            maxAmountSelected: true,
        });
    }

    return {
        fgenAmountInput,
        setFgenAmountInput,
        fgenBalance,
        formError,
        broadcastingTx,
        confirmingTx,
        connectedAccount,
        onClickGenesisCommit,
        committedFgenBalance,
        buttonDisabled,
        formContainerProps,
        preswapPercentBeforeAction,
        preswapPercentAfterAction,
        onClickMax,
    };
}