import { useState } from "react";
import { useEthereum } from "../../../providers/ethereum";
import get from "lodash/get";
import {isBrowser} from "react-device-detect";

export function useWalletInfoLogic({...props}) {

    const {
        connectedAccount,
        walletProviderName,
        protocolState,
        disconnectAccount,
    } = useEthereum();

    const [walletMenuOpen, setWalletMenuOpen] = useState(isBrowser ? false : true);

    const feiBalance = get(protocolState, "BALANCES.FEI", "0");
    const tribeBalance = get(protocolState, "BALANCES.TRIBE", "0");
    const hasConnectedAccount = !!connectedAccount;

    return {
        ...props,
        connectedAccount,
        hasConnectedAccount,
        walletProviderName,
        disconnectAccount,
        feiBalance,
        tribeBalance,
        walletMenuOpen,
        setWalletMenuOpen,
    };
}