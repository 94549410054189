import { ToastProvider } from "react-toast-notifications";
import EtherscanLinkToast from "../../components/ui/Notifications/EtherscanLinkToast";

export default function NotificationsProvider({children}) {
    const components = { Toast: EtherscanLinkToast };
    return (
        <ToastProvider 
            autoDismiss={true}
            autoDismissTimeout={10000} // dismiss after 10 seconds
            components={components}
        >
            {children}
        </ToastProvider>
    );
}