import React from "react";
import { Fragment } from "react";
import PoolSummary from "../PoolSummary";
import Flex from "../../../ui/Flex";
import { colors, fontWeights, radii, shadows } from "../../../../providers/theme/theme";
import TribeIcon from "../../../svgs/TribeIcon";
import Web3Button from "../../../ui/Button/Web3Button";
import { HeaderText, MiniText, TitleText } from "../../../ui/Text";
import { formatCryptoAmount } from "../../../../utils/numbers";
import ConnectWalletButton from "../../../ui/Button/ConnectWalletButton";
import PositionSummary from "../PositionSummary";
import StakingForm from "../StakingForm";
import RariLogo from "../../../svgs/RariLogo";

export default function FuseTribeView({
    tvl,
    apr,
    onClickSummary,
    stakingTokenSymbol,
    formOpen,
    showConnectWalletButton,
    displayYourPositionSection,
    onClickClaimButton,
    onClickWithdrawButton,
    claimBroadcasting,
    claimConfirming,
    withdrawBroadcasting,
    withdrawConfirming,
    stakedAmount,
    unclaimedRewardAmount,
    rewardTokenSymbol,
    onClickMax,
    onClickStakeButton,
    inputValue,
    setInputValue,
    depositBroadcasting,
    depositConfirming,
    inputError,
    tokenApprovalTransactionName,
    requiresTokenTransferApproval,
    stakingTokenUserBalance,
}) {
    return (
        <Flex {...styles.PoolCardContainer}>
            <PoolSummary
                tvl={tvl}
                apr={apr}
                onClickPoolSummary={onClickSummary} 
                stakingTokenSymbol={stakingTokenSymbol}
                buyTokenLink={"https://app.rari.capital/fuse/pool/8"}
                PoolIcon={(
                    <Flex position={"relative"}>
                        <TribeIcon width={"50px"} height={"50px"}/>
                        <RariLogo
                            width={"30px"} 
                            height={"30px"}
                            position={"absolute"}
                            right={"-15px"}
                            bottom={"-3px"}
                        />
                    </Flex>
                )}
                poolDescription={"TRIBE in Fei-Rari Fuse pool"}
            />
            {
                formOpen && (
                    <Fragment>
                        <Flex {...styles.SeparatorLine}/>
                        {
                            showConnectWalletButton ? (
                                <Flex {...styles.ConnectWalletContainer}> 
                                    <ConnectWalletButton/>
                                </Flex>
                            ) : (
                                <Fragment>
                                    {
                                        displayYourPositionSection && (
                                            <Fragment>
                                                <PositionSummary
                                                    onClickClaimButton={onClickClaimButton}
                                                    onClickClaimAndWithdrawButton={onClickWithdrawButton}
                                                    claimBroadcasting={claimBroadcasting}
                                                    claimConfirming={claimConfirming}
                                                    claimAndWithdrawBroadcasting={withdrawBroadcasting}
                                                    claimAndWithdrawConfirming={withdrawConfirming}
                                                    stakedAmount={stakedAmount}
                                                    unclaimedRewardAmount={unclaimedRewardAmount}
                                                    rewardTokenSymbol={rewardTokenSymbol}
                                                    stakingTokenSymbol={stakingTokenSymbol}
                                                    customStakedBalanceLabel={"TRIBE in Fuse Pool 8"}
                                                    customClaimAndWithdrawLabel={"Withdraw"}
                                                />
                                                <Flex {...styles.SeparatorLine}/>
                                            </Fragment>
                                        )
                                    }
                                    <StakingForm
                                        onClickMax={onClickMax}
                                        onClickStakeButton={onClickStakeButton}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        depositBroadcasting={depositBroadcasting}
                                        depositConfirming={depositConfirming}
                                        inputError={inputError}
                                        stakingTokenSymbol={"TRIBE"} // should be fTRIBE but we are buying fTRIBE with TRIBE in this form
                                        stakingTokenUserBalance={stakingTokenUserBalance}
                                        requiresTokenTransferApproval={requiresTokenTransferApproval}
                                        tokenApprovalTransactionName={tokenApprovalTransactionName}
                                        customButtonLabel={"Deposit TRIBE"}
                                    />
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
        </Flex>
    );
}

const styles = {
    PoolCardContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: colors.darkCardBackground,
        borderRadius: radii.small,
        marginTop: "20px",
        shadow: shadows.primary,
    },
    SeparatorLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    ConnectWalletContainer: {
        marginBottom: "10px"
    },
};