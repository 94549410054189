export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
}

// Takes "3", 3 or "3px" and returns a float
// representing the number of pixels
export function getPixelValueFloat(value) {
    if (typeof(value) === "number") {
        return value;
    } 
    if (typeof(value) === "string") {
        if (value.endsWith("px")) {
            value = value.substr(0, value.length -2);
        }
        return parseFloat(value);
    }
    return value;
}
  
export function truncateEthAddress(ethAddress, length = 20) {
    if (typeof(ethAddress) !== "string") {
        return ethAddress;
    }
    const has0x = ethAddress.startsWith("0x");
    if (has0x) {
        ethAddress = ethAddress.substr(2);
    }
    const halfLength = Math.trunc(length / 2);
    const firstHalf = ethAddress.substr(0,halfLength);
    const secondHalf = ethAddress.substr(-halfLength);
    return (`0x${firstHalf}...${secondHalf}`);
}