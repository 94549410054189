import React, { Fragment } from "react";
import { colors } from "../../../providers/theme/theme";
import { PrimaryButton } from "../../ui/Button";
import Flex from "../../ui/Flex";
import { BodyText, TitleText } from "../../ui/Text";

export default function DepositsTable({

}) {
    const columnWidths = [1,1,1,1,1];
    const headers = ["Date", "Amount", "Lockup", "Multiplier", ""];
    const rows = [
        ["02/05/2021", "123.23", "6mo", "1.5x", "Withdraw"],
        ["02/05/2021", "123.23", "6mo", "1.5x", "Withdraw"],
        ["02/05/2021", "123.23", "6mo", "1.5x", "Withdraw"],
        ["02/05/2021", "123.23", "6mo", "1.5x", "Withdraw"],
    ];
    return (
        <Flex {...styles.Container}>
            <TitleText
                bold
                text={"Your Deposits"}
                {...styles.Title}
            />
            <Flex {...styles.Row}>
                {
                    headers.map((header, index) => {
                        return (
                            <Flex 
                                {...styles.Cell}
                                flex={columnWidths[index]}
                            >
                                <BodyText bold text={header} />
                            </Flex>
                        );
                    })
                }
            </Flex>
            {
                rows.map((row) => {
                    return (
                        <Fragment>
                            <Flex {...styles.RowSeparatorLine}/>
                            <Flex {...styles.Row}>
                                {
                                    row.map((cellText, index) => {
                                        return (
                                            <Flex
                                                {...styles.Cell}
                                                flex={columnWidths[index]}
                                            >
                                                {
                                                    index === 4 ? (
                                                        <PrimaryButton
                                                            {...styles.WithdrawButton}
                                                        >
                                                            <BodyText
                                                                bold
                                                                text={"Withdraw"}
                                                                fontSize={"14px"}
                                                            />
                                                        </PrimaryButton>
                                                    ) : (
                                                        <BodyText text={cellText} />
                                                    )
                                                }
                                                
                                            </Flex>
                                        );
                                    })
                                }
                            </Flex>
                        </Fragment>
                    );
                })
            }
            
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "20px",
        paddingBottom: "5px"
    },
    Title: {
        marginBottom: "10px"
    },  
    Row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "30px"
    },
    Cell: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "10px",
    },
    RowSeparatorLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(255,255,255,0.1)"
    },
    WithdrawButton: {
        height: "15px",
        backgroundColor: colors.red.dark,
        hoverBg: colors.red.dark,
        paddingRight: "10px",
        paddingLeft: "10px",
        minHeight: "30px",
    }

};