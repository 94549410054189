import React from "react";

import Svg from "../ui/Svg";

export default function ArrowSouthGlowing({ width = 22, height = 26, fill, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.94834 3.93064L10.1624 22.8797C10.2377 23.069 10.3681 23.2314 10.5368 23.3459C10.7054 23.4603 10.9046 23.5215 11.1084 23.5215C11.3122 23.5215 11.5113 23.4603 11.6799 23.3459C11.8486 23.2314 11.979 23.069 12.0544 22.8797L19.2684 3.93064C19.3388 3.73044 19.3439 3.51304 19.2828 3.30979C19.2217 3.10654 19.0977 2.92794 18.9285 2.79975C18.7594 2.67156 18.5539 2.60041 18.3417 2.59654C18.1295 2.59268 17.9215 2.65632 17.7478 2.77827L11.6829 6.87493C11.513 6.98906 11.313 7.05 11.1084 7.05C10.9037 7.05 10.7037 6.98906 10.5338 6.87493L4.46882 2.77827C4.29512 2.65635 4.08719 2.59274 3.87501 2.59662C3.66283 2.60049 3.45736 2.67165 3.28823 2.79984C3.1191 2.92802 2.99505 3.1066 2.93396 3.30983C2.87288 3.51307 2.87791 3.73045 2.94834 3.93064Z" fill="#A7E9FC"/>
        <path d="M17.9571 3.08801L17.9571 3.08806L17.9626 3.08418C18.0715 3.00773 18.2019 2.96785 18.3349 2.97027C18.4679 2.97269 18.5967 3.01729 18.7027 3.09765C18.8088 3.17801 18.8865 3.28996 18.9248 3.41737C18.9627 3.54345 18.96 3.67821 18.9172 3.80261L11.7061 22.7438C11.6583 22.8627 11.5762 22.9646 11.4701 23.0365C11.3634 23.109 11.2373 23.1477 11.1084 23.1477C10.9794 23.1477 10.8534 23.109 10.7466 23.0365C10.6406 22.9646 10.5584 22.8626 10.5106 22.7438L3.29955 3.8026C3.25673 3.67822 3.25404 3.54348 3.29193 3.41743C3.33022 3.29003 3.40798 3.17809 3.514 3.09773C3.62003 3.01737 3.74883 2.97277 3.88183 2.97034C4.01484 2.96791 4.14519 3.00778 4.25407 3.08421L4.25404 3.08426L4.2596 3.08801L10.3246 7.18468L10.3254 7.1852C10.5569 7.34073 10.8295 7.42379 11.1084 7.42379C11.3873 7.42379 11.6598 7.34073 11.8913 7.1852L11.8921 7.18467L17.9571 3.08801Z" stroke="#A7E9FC" strokeWidth="0.747567"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.648901" y="0.353674" width="20.919" height="25.4105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="1.12135"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.52549 0 0 0 0 0.831373 0 0 0 0 0.901961 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </Svg>

    );
}

