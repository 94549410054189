import React from "react";

import Svg from "../ui/Svg";

export default function DownCaret({ width = 10, height = 11, fill, opacity, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity={opacity || "0.3"} fill={fill || "#EFEFEF"} d="M9.29062 6.06484L5.22598 10.1295C5.12331 10.2322 4.98406 10.2898 4.83887 10.2898C4.69367 10.2898 4.55443 10.2322 4.45176 10.1295L0.387109 6.06484C0.284442 5.96218 0.226764 5.82293 0.226764 5.67773C0.226764 5.53254 0.284442 5.39329 0.387109 5.29063C0.489777 5.18796 0.629025 5.13028 0.774219 5.13028C0.919413 5.13028 1.05866 5.18796 1.16133 5.29063L4.83887 8.96816L8.51641 5.29062C8.61907 5.18796 8.75832 5.13028 8.90352 5.13028C9.04871 5.13028 9.18796 5.18796 9.29062 5.29063C9.39329 5.39329 9.45097 5.53254 9.45097 5.67773C9.45097 5.82293 9.39329 5.96218 9.29062 6.06484Z"/>
        </Svg>
        
    );
}

