import React from "react";

import Svg from "../ui/Svg";

export default function TooltipIcon({ width = 12, height = 12, ...props }) {
    return (
        <Svg width={width} height={height} cursor={"pointer"} {...props}  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#4CACE2"/>
        <path d="M5.74046 3.36792C5.93701 3.5364 6.19206 3.6206 6.45029 3.60228C6.70852 3.6206 6.96359 3.5364 7.16014 3.36792C7.3567 3.19944 7.47891 2.96027 7.50029 2.70228C7.47892 2.44428 7.3567 2.2051 7.16014 2.03662C6.96358 1.86814 6.70853 1.78394 6.45029 1.80228C6.19206 1.78395 5.93701 1.86816 5.74046 2.03664C5.5439 2.20511 5.42168 2.44428 5.40029 2.70228C5.42168 2.96027 5.5439 3.19944 5.74046 3.36792Z" fill="white"/>
        <path d="M6.04088 8.78412L6.87629 5.63133C6.91118 5.49768 6.91504 5.3578 6.88728 5.22249C6.85952 5.08718 6.80103 4.96006 6.71633 4.85095C6.63164 4.74183 6.523 4.65364 6.3988 4.59319C6.27461 4.53273 6.13818 4.50163 6.00005 4.50228H5.10338C5.02385 4.50233 4.9476 4.53397 4.89138 4.59022C4.83517 4.64648 4.80359 4.72275 4.80359 4.80228V4.89915C4.80358 4.95909 4.82153 5.01767 4.85512 5.06732C4.88871 5.11696 4.9364 5.15541 4.99205 5.1777C5.13413 5.23466 5.25046 5.34163 5.31912 5.47844C5.38778 5.61525 5.40401 5.77245 5.36477 5.92041L4.52921 9.07323C4.49433 9.20689 4.49063 9.34678 4.51841 9.48209C4.54618 9.61741 4.60468 9.74453 4.68939 9.85365C4.77411 9.96276 4.88276 10.0509 5.00697 10.1114C5.13118 10.1718 5.26762 10.2029 5.40575 10.2023H6.30236C6.38188 10.2022 6.45813 10.1706 6.51434 10.1143C6.57055 10.0581 6.60212 9.9818 6.60212 9.90228V9.80538C6.60213 9.74543 6.58419 9.68686 6.55059 9.63721C6.517 9.58756 6.46931 9.54912 6.41366 9.52683C6.27157 9.46988 6.15523 9.36291 6.08656 9.2261C6.01789 9.08929 6.00164 8.93208 6.04088 8.78412Z" fill="white"/>
        </Svg>
        
    );
}