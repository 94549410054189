import {DateTime} from "luxon";
import Interval from "luxon/src/interval.js"
import { useEffect, useState } from "react";
import { track } from "../../../../providers/analytics";
import { CONTEXT_NAMES, EVENTS, EVENT_TYPES } from "../../../../providers/analytics/events";


const GENESIS_END_TIMESTAMP = 1617217200 + (3600*24*3); // Sat April 3rd 2021 Noon Pacific

export function useTimerBlockLogic({
    noCalendar,
    customVerb,
    customEndTime,
    customMarchDate,
    customTime,
    customMarginTop,
    customMonth,
}) {
    const initialTime = DateTime.fromSeconds(Date.now() / 1000);
    const genesisEndTime = DateTime.fromSeconds(customEndTime || GENESIS_END_TIMESTAMP);
    const [timeLeft, setTimeLeft] = useState(Interval.fromDateTimes(initialTime, genesisEndTime));
    const onClickGoogleCalendarLink = () => {
        track({
            contextName: CONTEXT_NAMES.GENESIS,
            eventName: EVENTS.GENESIS.GOOGLE_CALENDAR_LINK,
            eventType: EVENT_TYPES.CLICKED_LINK,
        });
        window.open("https://add.eventable.com/events/6050082088e91406ed7a846b/605a4b0593122f098b7d3ad3/");
    }

    const updateTimeLeft = () => {
        const now = DateTime.fromSeconds(Date.now() / 1000);
        setTimeLeft(Interval.fromDateTimes(now, genesisEndTime))
    };

    useEffect(() => {
        const interval = setInterval(() => {
            updateTimeLeft();
        }, 10 * 1000)
        return () => {
            clearInterval(interval);
        }
    }, []);

    const endDate = genesisEndTime.toLocaleString({
        month: "short",
        day: "numeric",
        timeZoneName: "short",
        hour: "numeric",
        minute: "2-digit",
    });
    
    const daysLeft = Math.floor(timeLeft.length("day"));
    const hoursLeft = Math.floor(timeLeft.length("hour")) % 24;
    const minutesLeft = Math.floor(timeLeft.length("minutes")) % 60;

    const hideCountdown = (genesisEndTime.ts - initialTime.ts) < 0

    return {
        daysLeft,
        hoursLeft,
        minutesLeft,
        endDate,
        onClickGoogleCalendarLink,
        hideCountdown,
        noCalendar,
        customVerb,
        customMarchDate,
        customTime,
        customMarginTop,
        customMonth,
    };
}