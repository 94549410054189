import { useEthereum } from "../../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";
import { useState } from "react";

export function useFuseTribeFormLogic({...props}) {
    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();
    
    const [formOpen, setFormOpen] = useState(true);
    const [inputValue, _setInputValue] = useState();
    const [maxAmountSelected, setMaxAmountSelected] = useState(false);

    const [depositBroadcasting, setDepositBroadcasting] = useState(false);
    const [depositConfirming, setDepositConfirming] = useState(false);

    const [claimBroadcasting, setClaimBroadcasting] = useState(false);
    const [claimConfirming, setClaimConfirming] = useState(false);

    const [withdrawBroadcasting, setWithdrawBroadcasting] = useState(false);
    const [withdrawConfirming, setWithdrawConfirming] = useState(false);

    const tribeUsdPrice = get(protocolState, "USD_PRICES.TRIBE", "0");
    const tribePerBlock = get(protocolState, "TRIBAL_CHIEF.TRIBE_PER_BLOCK", "0");
    const totalAllocPoints = get(protocolState, "TRIBAL_CHIEF.TOTAL_ALLOC_POINTS", "0");
    const fTribeAllocPoints = get(protocolState, "TRIBAL_CHIEF.ALLOCATION_POINTS.FTRIBE", "0");
    const userTribeBalance = get(protocolState, "BALANCES.TRIBE", "0");
    const userTribeUnderlyingFTribe = get(protocolState, "TRIBAL_CHIEF.USER_TRIBE_UNDERLYING_FTRIBE", "0");
    const userTribeFTribeAllowance = get(protocolState, "ALLOWANCES.TRIBE.FTRIBE", "0");
    const fTribeToTribeExchangeRate = get(protocolState, "TRIBAL_CHIEF.FTRIBE_TRIBE_EXCHANGE_RATE", "0");
    const fTribeTotalSupply = get(protocolState, "TRIBAL_CHIEF.FTRIBE_TOTAL_SUPPLY", "0");
    const userUnclaimedRewards = get(protocolState, "TRIBAL_CHIEF.USER_UNCLAIMED_TRIBE_REWARDS_ON_FTRIBE_POOL", "0");

    const fTribeAllocPointsBN = new BigNumber(fTribeAllocPoints);
    const totalAllocPointsBN = (new BigNumber(totalAllocPoints));
    const tribePerBlockBN = new BigNumber(tribePerBlock);
    const userTribeBalanceBN = new BigNumber(userTribeBalance);
    const tribeUsdPriceBN = new BigNumber(tribeUsdPrice);
    const fTribeToTribeExchangeRateBN = new BigNumber(fTribeToTribeExchangeRate);
    const fTribeTotalSupplyBN = new BigNumber(fTribeTotalSupply);
    const totalTribeUnderlyingFTribeBN = fTribeToTribeExchangeRateBN.gt(0) ?
        fTribeTotalSupplyBN.div(fTribeToTribeExchangeRateBN) :
        new BigNumber(0);

    const tvlBN = tribeUsdPriceBN.times(totalTribeUnderlyingFTribeBN);

    const tvl = tvlBN.toString();

    const blockTimeBN = new BigNumber(13.3);
    const secondsInAYearBN = new BigNumber(3600 * 24 * 365);
    const blocksPerYearBN = secondsInAYearBN.div(blockTimeBN);
    const tribalChiefTribePerYearBN = blocksPerYearBN.times(tribePerBlockBN);
    const tribalChiefTribeValuePerYearBN = tribalChiefTribePerYearBN.times(tribeUsdPriceBN);
    const poolsShareOfRewardsBN = fTribeAllocPointsBN.div(totalAllocPointsBN);
    const poolTribeValuePerYearBN = tribalChiefTribeValuePerYearBN.times(poolsShareOfRewardsBN);
    const aprBN = tvlBN.gt(0) ? poolTribeValuePerYearBN.div(tvlBN).times(100).decimalPlaces(1) : new BigNumber(0);

    // console.log(JSON.stringify({
    //     blocksPerYearBN: blocksPerYearBN.toString(),
    //     tribalChiefTribePerYearBN: tribalChiefTribePerYearBN.toString(),
    //     tribalChiefTribeValuePerYearBN: tribalChiefTribeValuePerYearBN.toString(),
    //     poolsShareOfRewardsBN: poolsShareOfRewardsBN.toString(),
    //     poolTribeValuePerYearBN: poolTribeValuePerYearBN.toString(),
    //     aprBN: aprBN.toString(),
    //     tvlBN: tvlBN.toString(),
    //     totalTribeUnderlyingFTribeBN: totalTribeUnderlyingFTribeBN.toString(),

    // }, null, 2))

    const setInputValue = (val) => {
        _setInputValue(val);
        setMaxAmountSelected(false);
    };
    const onClickSummary = () => {
        setFormOpen(!formOpen);
    };
    const onClickMax = () => {
        const formattedMaxAmount = userTribeBalanceBN.dp(6, BigNumber.ROUND_DOWN).toString();
        _setInputValue(formattedMaxAmount);
        setMaxAmountSelected(true);
    };

    async function onClickStakeButton() {
        setDepositBroadcasting(true);

        const depositAmount = maxAmountSelected ? 
            userTribeBalance :
            inputValue;
        await transactions.MintFTribeInFeiRari({
            depositAmount,
        },{
            onBroadcast: () => {
                setDepositBroadcasting(false);
                setDepositConfirming(true);
            },
            onConfirm: () => {
                setDepositConfirming(false);
                setInputValue("");  
            },
            onFailure: () => {
                setDepositBroadcasting(false);
                setDepositConfirming(false);
            },
        });
    }

    async function onClickWithdrawButton() {
        setWithdrawBroadcasting(true);

        await transactions.RedeemFTribeForTribeInFeiRari({
            tribeToRedeem: userTribeUnderlyingFTribe
        }, {
            onBroadcast: () => {
                setWithdrawBroadcasting(false);
                setWithdrawConfirming(true);
            },
            onConfirm: () => {
                setWithdrawConfirming(false);     
            },
            onFailure: () => {
                setWithdrawBroadcasting(false);
                setWithdrawConfirming(false);
            },
        });
    }

    async function onClickClaimButton() {
        setClaimBroadcasting(true);

        await transactions.ClaimFTribeRewards({}, {
            onBroadcast: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(true);
            },
            onConfirm: () => {
                setClaimConfirming(false);     
            },
            onFailure: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(false);
            },
        });
    }

    return {
        tvl,
        apr: aprBN.toString(),
        onClickSummary,
        stakingTokenSymbol: "TRIBE",
        formOpen,
        showConnectWalletButton: !!!connectedAccount,
        displayYourPositionSection: userTribeUnderlyingFTribe !== "0",
        onClickClaimButton,
        onClickWithdrawButton,
        claimBroadcasting,
        claimConfirming,
        withdrawBroadcasting,
        withdrawConfirming,
        stakedAmount: userTribeUnderlyingFTribe,
        unclaimedRewardAmount: userUnclaimedRewards,
        rewardTokenSymbol: "TRIBE", 
        onClickMax,
        onClickStakeButton,
        inputValue,
        setInputValue,
        depositBroadcasting,
        depositConfirming,
        inputError: null, // TODO
        tokenApprovalTransactionName: "ApproveFTribeTribe",
        requiresTokenTransferApproval: userTribeFTribeAllowance === "0",
        stakingTokenUserBalance: userTribeBalance,
        ...props,
    };
}