import Image from "../ui/Image";
import gUniLogoSource from "../../assets/images/GUni_1.svg";

export default function GUniLogo({ width = "42px", height = "68px", ...props }) {
    return (
        <Image
            src={gUniLogoSource}
            style={{
                width,
                height,
                ...styles.Image
            }}
            {...props}
        />
    );
}

const styles = {
    Image: {

    }
};