import React from "react";

import Svg from "../ui/Svg";

export default function GithubIcon({ width = 21, height = 20, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2812 0C4.84258 0 0.4375 4.5165 0.4375 10.0927C0.4375 14.5588 3.25527 18.3309 7.16816 19.6682C7.66035 19.7565 7.84492 19.4537 7.84492 19.1888C7.84492 18.9491 7.83262 18.1543 7.83262 17.309C5.35938 17.7758 4.71953 16.6909 4.52266 16.1231C4.41191 15.833 3.93203 14.9373 3.51367 14.6975C3.16914 14.5083 2.67695 14.0415 3.50137 14.0289C4.27656 14.0163 4.83027 14.7606 5.01484 15.0634C5.90078 16.5899 7.31582 16.161 7.88184 15.8961C7.96797 15.24 8.22637 14.7985 8.50938 14.5462C6.31914 14.2938 4.03047 13.4233 4.03047 9.56287C4.03047 8.46528 4.41191 7.55694 5.03945 6.85044C4.94102 6.59813 4.59648 5.56362 5.13789 4.17587C5.13789 4.17587 5.9623 3.91094 7.84492 5.21038C8.63242 4.98329 9.46914 4.86975 10.3059 4.86975C11.1426 4.86975 11.9793 4.98329 12.7668 5.21038C14.6494 3.89832 15.4738 4.17587 15.4738 4.17587C16.0152 5.56362 15.6707 6.59813 15.5723 6.85044C16.1998 7.55694 16.5813 8.45267 16.5813 9.56287C16.5813 13.436 14.2803 14.2938 12.09 14.5462C12.4469 14.8616 12.7545 15.4671 12.7545 16.4133C12.7545 17.7632 12.7422 18.8482 12.7422 19.1888C12.7422 19.4537 12.9268 19.7691 13.4189 19.6682C17.3072 18.3309 20.125 14.5462 20.125 10.0927C20.125 4.5165 15.7199 0 10.2812 0Z" fill="white"/>
        </Svg>

    );
}