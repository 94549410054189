import React from "react";

import Svg from "../ui/Svg";

export default function FeiTribeIcon({ width = 48, height = 32, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 96 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="64" cy="32" r="32" fill="#178DD0"/>
            <path d="M46.9254 33.2478C47.0906 31.7745 47.1732 31.0379 47.8171 30.5212C48.461 30.0046 49.196 30.0834 50.6661 30.2408C51.3296 30.3119 52.0997 30.404 52.9815 30.5233C53.7561 30.628 54.4618 30.7901 55.0951 30.9824C56.3869 31.3745 57.0327 31.5706 58.0164 32.8972C59 34.2238 59 35.2905 59 37.4239C59 38.7372 59 40.4038 59 42.5C59 45.4451 59 47.5421 59 49.003C59 50.4152 59 51.1213 58.5607 51.5607C58.1213 52 57.4145 52 56.0008 52C54.9173 52 53.6721 52 52.5 52C52.4189 52 52.3384 52 52.2586 52C49.9346 51.999 48.7726 51.9985 47.9129 51.2105C47.0533 50.4225 46.9578 49.3333 46.767 47.1549C46.6174 45.4471 46.5 43.3565 46.5 41C46.5 37.8523 46.7094 35.1736 46.9254 33.2478Z" fill="white"/>
            <path d="M80.5746 33.2478C80.4094 31.7745 80.3268 31.0379 79.6829 30.5212C79.039 30.0046 78.304 30.0834 76.8339 30.2408C76.1704 30.3119 75.4003 30.404 74.5185 30.5233C73.7439 30.628 73.0382 30.7901 72.4049 30.9824C71.1131 31.3745 70.4673 31.5706 69.4836 32.8972C68.5 34.2238 68.5 35.2905 68.5 37.4239C68.5 38.7372 68.5 40.4038 68.5 42.5C68.5 45.4451 68.5 47.5421 68.5 49.003C68.5 50.4152 68.5 51.1213 68.9393 51.5607C69.3787 52 70.0855 52 71.4992 52C72.5827 52 73.8279 52 75 52C75.0811 52 75.1616 52 75.2414 52C77.5654 51.999 78.7274 51.9985 79.5871 51.2105C80.4467 50.4225 80.5422 49.3333 80.733 47.1549C80.8826 45.4471 81 43.3565 81 41C81 37.8523 80.7906 35.1736 80.5746 33.2478Z" fill="white"/>
            <path d="M47 17.0209C47 14.7181 47 13.5667 47.6656 12.7421C47.7503 12.6373 47.8499 12.53 47.9482 12.4379C48.7213 11.7131 49.8395 11.6301 52.076 11.4643C55.2005 11.2326 59.4664 11 64 11C68.5336 11 72.7995 11.2326 75.924 11.4643C78.1605 11.6301 79.2787 11.7131 80.0518 12.4379C80.1501 12.53 80.2497 12.6373 80.3344 12.7421C81 13.5667 81 14.7181 81 17.0209V17.0209C81 19.3044 81 20.4462 80.3349 21.2707C80.2508 21.3749 80.1503 21.4832 80.0526 21.5749C79.2801 22.2996 78.1762 22.3822 75.9685 22.5473C72.9228 22.7751 68.7203 23 64 23C59.2797 23 55.0772 22.7751 52.0315 22.5473C49.8238 22.3822 48.7199 22.2996 47.9474 21.5749C47.8497 21.4832 47.7492 21.3749 47.6651 21.2707C47 20.4462 47 19.3044 47 17.0209V17.0209Z" fill="white"/>
            <circle cx="32" cy="32" r="32" fill="#22996E"/>
            <path d="M32 46.2568H14.4872C12.1153 46.2568 10.6815 43.6343 11.9618 41.6376L21.9255 26.0978C22.661 24.9506 23.93 24.2568 25.2928 24.2568H38.7072C40.07 24.2568 41.339 24.9506 42.0745 26.0978L52.0382 41.6376C53.3185 43.6343 51.8847 46.2568 49.5128 46.2568H32Z" fill="white"/>
            <path d="M33.7897 10.5105L37.9744 16.9048C38.8449 18.235 37.8906 20 36.3009 20H27.7751C26.1701 20 25.2189 18.2046 26.1204 16.8766L30.4615 10.4823C31.2632 9.30148 33.0082 9.31626 33.7897 10.5105Z" fill="white"/>
        </Svg>

    );
}

