import { hexToRGB } from "../../utils/ui";

export const colors = {
    black: "#000000",
    white: "#FFFFFF",
    purple: "#627EEA",
    green: "#25B882",
    red: {
        error: "#ed0b0b",
        dark: "#B82525",
    },
    blue: {
        link: "#0084CE",
        linkDark: "#0B82B8",
        tribe: "#178DD0",
        connectWallet: "#0E5197",
    },
    grey: {
        lightest: "#EFEFEF",
        light: "#e9e9e9",
        medium: "#8D8C8C",
        dark: "#080C24"
    },
    pink: {
        uniswap: "#FF007A",
    },
    yellow: {
        notice: "#FFBB17"
    },
    darkCardBackground: "#3E4256",
};

export const radii = {
    tiny: "4px",
    small: "8px",
    medium: "12px",
    large: "25px",
};

export const shadows = {
    primary: "5px 5px 10px 1px rgba(0,0,0,0.1)",
};

export const borders = {
    primary: `1px solid ${hexToRGB(colors.grey.light, "0.2")}`,
}

export const fontWeights = {
    light: "300",
    regular: "400",
    bold: "600",
    black: "700",
};

export const fonts = {
    primary: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: '"Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

export const walletConnectTheme = {
    background: "rgb(39, 49, 56)",
    main: "rgb(199, 199, 199)",
    secondary: "rgb(136, 136, 136)",
    border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(16, 26, 32)",
};