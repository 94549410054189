import React, { Fragment} from "react";
import {colors, fonts, radii, shadows} from "../../../../providers/theme/theme";
import Flex from "../../../ui/Flex";
import Input from "../../../ui/Input";
import {BodyText, BoldSpan, LinkSpan, MiniText, TitleText} from "../../../ui/Text";
import ConnectWalletButton from "../../../ui/Button/ConnectWalletButton";
import {CONTEXT_NAMES, EVENTS} from "../../../../providers/analytics/events";
import {PrimaryButton} from "../../../ui/Button";
import TribeIconJustMonoliths from "../../../svgs/TribeIconJustMonoliths";
import EthereumIconBlue from "../../../svgs/EthereumIconBlue";
import FormErrorDisplay from "../../../ui/FormErrorDisplay";
import { hexToRGB } from "../../../../utils/ui";
import { formatCryptoAmount, formatDollarAmount } from "../../../../utils/numbers";
import MaxButton from "../../../ui/Button/MaxButton";
import ArrowEast from "../../../svgs/ArrowEast";

export default function GenesisCommitView({
    fgenBalance,
    fgenAmountInput,
    setFgenAmountInput,
    onClickGenesisCommit,
    formError,
    broadcastingTx,
    confirmingTx,
    connectedAccount,
    onClickMax,
    buttonDisabled,
    formContainerProps,
    preswapPercentBeforeAction,
    preswapPercentAfterAction,
}) {

    const inputErrorStyles = formError ? {
        backgroundColor: hexToRGB(colors.red.dark, "0.3"),
    } : {};

    const noPercentChange = preswapPercentBeforeAction === preswapPercentAfterAction;

    return (
        <Fragment>
            <Flex 
                {...styles.Container}
                {...formContainerProps}
            >
                <Flex {...styles.BalanceRow}>
                    <BodyText mono secondary>
                        <BoldSpan>AVAILABLE: </BoldSpan>{formatCryptoAmount(fgenBalance)} ETH
                    </BodyText>
                    <MaxButton onClick={onClickMax}/>
                </Flex>
                <Flex {...styles.FgenAmountInputContainer}>
                    <EthereumIconBlue {...styles.InputIcon}/>
                    <Input
                        mono
                        type={"number"}
                        value={fgenAmountInput}
                        onChange={setFgenAmountInput}
                        {...styles.FgenAmountInput}
                        {...inputErrorStyles}
                    />
                    <TitleText
                        mono
                        text={"ETH"}
                        {...styles.FgenAmountInputLabel}
                    />
                </Flex>
                <Flex {...styles.BalanceRow}>
                    <BodyText mono secondary bold>
                        PRE-SWAP PERCENTAGE:
                    </BodyText>
                    <Flex {...styles.PercentChangeContainer}>
                        <BodyText text={`${preswapPercentBeforeAction}%`} opacity={"0.6"}/>
                        <ArrowEast
                            fill={"white"}
                            opacity={"0.6"}
                            height={"14px"}
                            width={"auto"}
                            marginLeft={"5px"}
                            marginRight={"5px"}
                        />
                        <BodyText 
                            text={`${preswapPercentAfterAction}%`}
                            opacity={noPercentChange ? "0.6" : "1"}
                        />
                    </Flex>
                </Flex>
                {
                    connectedAccount ? (
                    <PrimaryButton
                        {...styles.PrimaryButton}
                        onClick={onClickGenesisCommit}
                        disabled={buttonDisabled}
                        broadcastingTx={broadcastingTx}
                        confirmingTx={confirmingTx}
                        tracking={{
                            contextName: CONTEXT_NAMES.GENESIS,
                            eventName: EVENTS.GENESIS.COMMIT
                        }}
                    >
                        <Flex {...styles.PrimaryButtonContent}>
                            <TribeIconJustMonoliths width={30} height={30}/>
                            <TitleText
                                text={"Pre-Swap Tribe"}
                                {...styles.PrimaryButtonText}
                            />
                        </Flex>
                    </PrimaryButton>
                    ) : (
                        <ConnectWalletButton 
                            contextName={CONTEXT_NAMES.GENESIS}
                        />
                    )
                }
            </Flex>
            <FormErrorDisplay errorMessage={formError}/>
        </Fragment>
    );
}

const styles = {
    Container: {
        backgroundColor: colors.darkCardBackground,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    FgenAmountInputContainer: {
        width: "100%",
        position: "relative",
        marginTop: "10px",
    },
    FgenAmountInput: {
        width: "100%",
        boxShadow: shadows.primary,
        borderRadius: radii.small,
        marginBottom: 10,
        paddingLeft: 25,
        paddingRight: 70,
        backgroundColor: "#525669",
        color: "white",
        fontFamily: fonts.mono, 
    },
    FgenAmountInputLabel: {
        fontWeight: "bold",
        fontSize: 20,
        position: "absolute",
        height: 20,
        right: 20,
        margin: "auto",
        top: 0,
        bottom: 13,
    },
    InputIcon: {
        width: "30px",
        height: "30px",
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 10,
        left: 20,
    },
    PrimaryButton: {
        width: "100%",
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
        marginTop: 15,
    },
    PrimaryButtonContent: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    PrimaryButtonText: {
        paddingLeft: 10,
    },
    DetailsText: {
        opacity: "0.8",
        textAlign: "center",
        marginTop: "10px",
        maxWidth: "280px"
    },
    BalanceRow: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    PercentSelectorsContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "80px",
        marginTop: "10px"
    },
    PercentChangeContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    }
};