import React from "react";

import Svg from "../ui/Svg";

export default function GoogleCalendar({ width = 20, height = 20, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2629 4.73676H4.73651V15.2631H15.2629V4.73676Z" fill="white"/>
            <path d="M15.2642 20L20.0011 15.2631H15.2642V20Z" fill="#EA4335"/>
            <path d="M20.0011 4.73676H15.2642V15.2631H20.0011V4.73676Z" fill="#FBBC04"/>
            <path d="M15.2629 15.2631H4.73651V20H15.2629V15.2631Z" fill="#34A853"/>
            <path d="M0 15.2631V18.421C0 19.2934 0.706582 20 1.57896 20H4.73687V15.2631H0Z" fill="#188038"/>
            <path d="M20.0011 4.73687V1.57896C20.0011 0.706582 19.2945 0 18.4221 0H15.2642V4.73687H20.0011Z" fill="#1967D2"/>
            <path d="M15.2632 0H1.57896C0.706582 0 0 0.706582 0 1.57896V15.2632H4.73687V4.73687H15.2632V0Z" fill="#4285F4"/>
            <path d="M6.89547 12.9028C6.50205 12.637 6.22968 12.2489 6.08099 11.7357L6.99416 11.3594C7.07705 11.6752 7.22179 11.9199 7.42837 12.0936C7.63363 12.2673 7.88363 12.3528 8.17574 12.3528C8.47443 12.3528 8.73101 12.262 8.94548 12.0804C9.15996 11.8989 9.26785 11.6673 9.26785 11.387C9.26785 11.1002 9.15469 10.866 8.92838 10.6844C8.70206 10.5028 8.41785 10.412 8.07837 10.412H7.55074V9.50807H8.02442C8.31653 9.50807 8.56258 9.42912 8.76259 9.27122C8.96259 9.11333 9.06259 8.89754 9.06259 8.62254C9.06259 8.3778 8.97311 8.18306 8.79416 8.03701C8.61522 7.89096 8.3889 7.81727 8.1139 7.81727C7.84548 7.81727 7.63232 7.88832 7.47442 8.03175C7.31663 8.17555 7.198 8.35712 7.12968 8.55938L6.22573 8.18306C6.34547 7.84359 6.56521 7.54358 6.88758 7.28437C7.20995 7.02516 7.62179 6.8949 8.12179 6.8949C8.49153 6.8949 8.82443 6.96595 9.11917 7.10937C9.4139 7.25279 9.64548 7.45148 9.81259 7.70411C9.9797 7.95806 10.0626 8.24227 10.0626 8.55806C10.0626 8.88043 9.98496 9.1528 9.8297 9.37649C9.67443 9.60017 9.48364 9.77123 9.25732 9.89096V9.94491C9.54955 10.0654 9.8034 10.2633 9.99154 10.5173C10.1823 10.7739 10.2784 11.0804 10.2784 11.4383C10.2784 11.7962 10.1876 12.116 10.006 12.3962C9.82443 12.6765 9.57312 12.8976 9.25469 13.0581C8.93495 13.2186 8.57574 13.3002 8.17706 13.3002C7.71521 13.3015 7.28889 13.1686 6.89547 12.9028ZM12.5047 8.37122L11.5021 9.09622L11.0008 8.33569L12.7994 7.03832H13.4889V13.1581H12.5047V8.37122Z" fill="#4285F4"/>
        </Svg>

    );
}