import { style } from "styled-system";

export const fill = style({
    prop: "fill",
    cssProperty: "fill",
    key: "colors",
});

export const hoverFill = style({
    prop: "hoverFill",
    cssProperty: "fill",
    key: "colors",
});

export const textTransform = style({
    prop: "textTransform",
    cssProperty: "text-transform",
});

export const whiteSpace = style({
    prop: "whiteSpace",
    cssProperty: "white-space",
});

export const stroke = style({
    prop: "stroke",
    cssProperty: "stroke",
    key: "colors",
});

export const placeholderColor = style({
    prop: "placeholderColor",
    cssProperty: "color",
    key: "colors",
});

export const hoverColor = style({
    prop: "hoverColor",
    cssProperty: "color",
    key: "colors",
});

export const hoverOpacity = style({
    prop: "hoverOpacity",
    cssProperty: "opacity",
    key: "opacity",
});

export const hoverBg = style({
    prop: "hoverBg",
    cssProperty: "background-color",
    key: "colors",
});

export const hoverBoxShadow = style({
    prop: "hoverBoxShadow",
    cssProperty: "box-shadow",
    key: "shadows",
});

export const hoverTransform = style({
    prop: "hoverTransform",
    cssProperty: "transform",
});

export const focusColor = style({
    prop: "focusColor",
    cssProperty: "color",
    key: "colors",
});

export const focusBg = style({
    prop: "focusBg",
    cssProperty: "background-color",
    key: "colors",
});

export const focusBoxShadow = style({
    prop: "focusBoxShadow",
    cssProperty: "box-shadow",
    key: "shadows",
});

export const transition = style({
    prop: "transition",
    cssProperty: "transition",
    key: "transitions",
});

export const pointerEvents = style({
    prop: "pointerEvents",
    cssProperty: "pointer-events",
});

export const transform = style({
    prop: "transform",
    cssProperty: "transform",
});

export const cursor = style({
    prop: "cursor",
    cssProperty: "cursor",
});

export const objectFit = style({
    prop: "objectFit",
    cssProperty: "object-fit",
});

export const objectPosition = style({
    prop: "objectPosition",
    cssProperty: "object-position",
});

export const overflow = style({
    prop: "overflow",
    cssProperty: "overflow",
});

export const overflowY = style({
    prop: "overflowY",
    cssProperty: "overflow-y",
});

export const transformOrigin = style({
    prop: "transformOrigin",
    cssProperty: "transform-origin",
});

export const backdropFilter = style({
   prop: "backdropFilter",
    cssProperty: "backdrop-filter",
});

export const filter = style({
    prop: "filter",
    cssProperty: "filter",
});

export const fontFeatureSettings = style({
    prop: "fontFeatureSettings",
    cssProperty: "font-feature-settings",
});
