import React, {Fragment, useEffect} from "react";
import get from "lodash/get";

import config from "../../../config";

import Flex from "../../ui/Flex";
import WavesImageBackground from "../../ui/backgrounds/WavesImageBackground";
import FeiHeader from "../../ui/FeiHeader";
import {CONTEXT_NAMES, EVENTS, EVENT_TYPES} from "../../../providers/analytics/events";
import {colors, radii, borders, fontWeights} from "../../../providers/theme/theme";
import { BodyText, HeaderText, TitleText, MiniText, LinkSpan, BoldSpan } from "../../ui/Text";
import TimerBlock from "./TimerBlock";
import { hexToRGB } from "../../../utils/ui";
import GenesisDeposit from "./Deposit";
import GenesisCommit from "./Commit";
import GenesisRedeem from "./Redeem";
import { MODES } from "./logic";
import { formatCryptoAmount } from "../../../utils/numbers";
import LoadingPage from "../Loading";
import DownCaret from "../../svgs/DownCaret";
import FeiFooter from "../../ui/FeiFooter";
import { track } from "../../../providers/analytics";

const GenesisView = ({
    JSON_MODE,
    genesisDeposits,
    mode,
    setMode,
    selectorConfig,
    loading,
    displayPreswapTooltip,
    setDisplayPreswapTooltip,
}) => {

    useEffect(() => {
        track({
            eventType: EVENT_TYPES.VIEWED_SCREEN,
            contextName: CONTEXT_NAMES.GENESIS,
            eventName: CONTEXT_NAMES.GENESIS,
        });
    }, []);

    const modeConfig = {
        [`${MODES.GENESIS_DEPOSIT}`]: {
            MainComponent: GenesisDeposit,
            headerText: "Join the Fei Genesis Group",
        },
        [`${MODES.IDO_COMMITMENT}`]: {
            MainComponent: GenesisCommit,
            headerText: "Join the Fei Genesis Group",
        },
        [`${MODES.EXPLORE_SCENARIOS}`]: {
            MainComponent: Fragment, // TODO: insert genesis explorer
            headerText: "Explore Scenarios",
        },
    };

    const {
        MainComponent,
        headerText,
    } = modeConfig[mode];

    if (loading) {
        return (<LoadingPage/>);
    }

    return (
        <Flex {...styles.Container}>
            <WavesImageBackground customContainerStyle={{
                top: [
                    config.postGenesis ? 260 : 620, 
                    config.postGenesis ? 210 : 430
                ],
            }}/>
            <FeiHeader contextName={CONTEXT_NAMES.GENESIS}/>
            {
                config.postGenesis ? (
                    <Fragment>
                        <HeaderText 
                            text={"Redeem Genesis Tokens"}
                            {...styles.HeaderText}
                        />
                        <GenesisRedeem/>
                    </Fragment>
                ) : (
                    <Fragment>
                        <HeaderText 
                            text={headerText} 
                            {...styles.HeaderText}
                        />
                        <TimerBlock 
                            customVerb={"Genesis ends @"}
                            customMarginTop={"20px"}
                            customMonth={"April"}
                        />
                        <BalancesDisplay
                            genesisDeposits={genesisDeposits}
                        />
                        <ModeSelector
                            currentMode={mode}
                            setMode={setMode}
                            selectorConfig={selectorConfig}
                            displayPreswapTooltip={displayPreswapTooltip}
                        />
                        <MainComponent
                            formContainerProps={formStyle}
                            setDisplayPreswapTooltip={setDisplayPreswapTooltip}
                        />
                        <InfoBox currentMode={mode}/>
                    </Fragment>
                )
            }
            {
                JSON_MODE && config.jsonMode && (
                    <BodyText 
                        marginTop={300}
                        mono
                        bold 
                        maxWidth={"100%"}
                        text={JSON.stringify(JSON_MODE, null, 2)}
                    />
                )
            }
            <FeiFooter contextName={CONTEXT_NAMES.GENESIS}/>
        </Flex>
    );
};

const BalancesDisplay = ({genesisDeposits}) => {
    const globalFgen = get(genesisDeposits, "OVERALL_DEPOSITS.global", "0");
    const userFgen = get(genesisDeposits, "OVERALL_DEPOSITS.user", "0");
    const globalFgenCommitted = get(genesisDeposits, "FGEN_COMMITTED.global", "0");
    const userFgenCommitted = get(genesisDeposits, "FGEN_COMMITTED.user", "0");

    const columnStyle = {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const firstColumnWidth = "145px";
    return (
        <Flex {...styles.BalancesDisplayContainer}>
            <Flex 
                {...styles.BalancesDisplayRow}
                borderBottom={borders.primary}
            >
                <Flex 
                    {...columnStyle}
                    justifyContent={"flex-start"}
                    width={firstColumnWidth}
                >
                    <BodyText text={"ETH"} lineHeight={"1"}/>
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText text={"ALL"} lineHeight={"1"}/>
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText text={"YOU"} lineHeight={"1"}/>
                </Flex>
            </Flex>
            <Flex {...styles.BalancesDisplayRow}>
                <Flex 
                    {...columnStyle}
                    borderRight={borders.primary}
                    justifyContent={"flex-start"}
                    width={firstColumnWidth}
                >
                    <BodyText 
                        bold secondary
                        text={"COMMITTED"}
                        fontSize={["15px", "15px"]}
                        letterSpacing={"-0.01rem"}
                    />
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText mono text={formatCryptoAmount(globalFgen, {noDecimals: true})} />
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText mono text={formatCryptoAmount(userFgen)} />
                </Flex>
            </Flex>
            <Flex {...styles.BalancesDisplayRow}>
                <Flex 
                    {...columnStyle}
                    borderRight={borders.primary}
                    justifyContent={"flex-start"}
                    width={firstColumnWidth}
                >
                    <BodyText 
                        bold secondary
                        text={"PRE-SWAPPED"} 
                        fontSize={["15px", "15px"]}
                        letterSpacing={"-0.01rem"}
                    />
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText mono text={formatCryptoAmount(globalFgenCommitted, {noDecimals: true})} />
                </Flex>
                <Flex 
                    {...columnStyle}
                    flex={1}
                >
                    <BodyText mono text={formatCryptoAmount(userFgenCommitted)} />
                </Flex>
            </Flex>
        </Flex>
    )
};

const ModeSelector = ({
    currentMode,
    setMode,
    selectorConfig,
    displayPreswapTooltip,
}) => {
    return (
        <Flex {...styles.ModeSelectorContainer}>
            {
                selectorConfig.map(({
                    label,
                    mode,
                    disabled,
                }, i) => {
                    const selectedStyles = currentMode === mode ?
                        styles.SelectedModeSelectorOption : {};
                    const disabledStyles = disabled ?
                        { 
                            cursor: "default",
                            opacity: "0.6"
                        } : {};
                    const onClick = () => {
                        if (disabled) {
                            return;
                        }
                        setMode(mode);
                    };

                    
                    return (
                        <Flex 
                            key={i}
                            onClick={onClick}
                            {...styles.ModeSelectorOption}
                            {...selectedStyles}
                            {...disabledStyles}
                        >
                            <BodyText>
                                {label}
                            </BodyText>
                            {
                                currentMode !== MODES.IDO_COMMITMENT &&
                                mode === MODES.IDO_COMMITMENT && 
                                displayPreswapTooltip && (
                                    <Fragment>
                                        <Flex {...styles.PreswapToolTipArrow}/>
                                        <Flex {...styles.PreSwapTooltip}>
                                            <TitleText text={"You can now pre-swap!"}/>
                                        </Flex>
                                    </Fragment>
                                )
                            }
                        </Flex>
                    );
                })
            }
        </Flex>
    );
};

const InfoBox = ({currentMode}) => {
    const propsByMode = {
        [`${MODES.IDO_COMMITMENT}`]: {
            titleText: "Please make sure you understand the Pre-Swap",
            textRows: [
                <MiniText {...styles.InfoBoxRowText}>
                    🧐 <BoldSpan>Pre-swapping is optional</BoldSpan> and can only be increased
                </MiniText>,
                <MiniText {...styles.InfoBoxRowText} text={"🔀 FEI is pre-swapped from committed ETH for TRIBE"} />,
                <MiniText {...styles.InfoBoxRowText} text={"⚖️ Everyone gets the same exchange rate for TRIBE"} />,
                <MiniText {...styles.InfoBoxRowText} text={"🦄 The swap is the first trade on the FEI-TRIBE pool"} />,
                <MiniText {...styles.InfoBoxRowText} text={"⏱ TRIBE is redeemable immediately after Genesis"} />,
                <MiniText {...styles.InfoBoxRowText}>
                    🚨 Loss of funds is possible, even with{" "}
                    <LinkSpan 
                        onClick={() => {
                            track({
                                contextName: CONTEXT_NAMES.GENESIS,
                                eventType: EVENT_TYPES.CLICKED_LINK,
                                eventName: EVENTS.GENESIS.AUDIT_REPORT
                            });
                            window.open("https://blog.openzeppelin.com/fei-protocol-audit/")
                        }}>audited contracts</LinkSpan>
                </MiniText>,
            ],
        },
        [`${MODES.GENESIS_DEPOSIT}`]: {
            titleText: "Please make sure you understand how Genesis works",
            textRows: [
                <MiniText {...styles.InfoBoxRowText}>
                    🏦 Committed ETH will go to Fei Protocol's reserves
                </MiniText>,
                <MiniText {...styles.InfoBoxRowText} text={"♻️ Your ETH is exchanged for FEI, with a TRIBE airdrop"} />,
                <MiniText {...styles.InfoBoxRowText} text={"⚖️ Everyone gets the same exchange rate"} />,
                <MiniText {...styles.InfoBoxRowText} text={"⏱ FEI and TRIBE are redeemable after Genesis"} />,
                <MiniText {...styles.InfoBoxRowText}>
                    🚨 Loss of funds is possible, even with{" "}
                    <LinkSpan
                        onClick={() => {
                            track({
                                contextName: CONTEXT_NAMES.GENESIS,
                                eventType: EVENT_TYPES.CLICKED_LINK,
                                eventName: EVENTS.GENESIS.AUDIT_REPORT
                            });
                            window.open("https://blog.openzeppelin.com/fei-protocol-audit/")
                        }}
                    >
                        audited contracts
                    </LinkSpan>
                </MiniText>,
                <MiniText {...styles.InfoBoxRowText} text={""}>
                    🔥 Selling after Genesis{" "}
                    <LinkSpan
                        onClick={() => {
                            track({
                                contextName: CONTEXT_NAMES.GENESIS,
                                eventType: EVENT_TYPES.CLICKED_LINK,
                                eventName: EVENTS.GENESIS.CAN_BURN_FEI
                            });
                            window.open("https://twitter.com/feiprotocol/status/1375498459160080387")
                        }}
                    >
                        can burn FEI
                    </LinkSpan>
                </MiniText>,
            ]
        },
    }
    const {
        titleText,
        textRows,
    } = propsByMode[currentMode];
    return (
        <Flex {...styles.InfoBoxContainer}>
            <MiniText bold text={titleText} marginBottom={"10px"}/>
            {textRows}
            <Flex
                {...styles.InfoBoxLinkRow}
                onClick={() => {
                    // TODO
                }}
            >
                <MiniText>
                    <LinkSpan
                        onClick={() => {
                            track({
                                contextName: CONTEXT_NAMES.GENESIS,
                                eventType: EVENT_TYPES.CLICKED_LINK,
                                eventName: EVENTS.GENESIS.RISKS_MEDIUM_POST
                            });
                            window.open("https://medium.com/fei-protocol/what-you-should-know-about-fei-3ccffd4a4bb6");
                        }}
                    >
                        Go to the Fei Protocol Blog to learn more
                    </LinkSpan>
                </MiniText>
                <DownCaret
                    transform={"rotate(270deg)"}
                    marginTop={"3px"}
                    width={"12px"}
                    height={"auto"}
                    opacity={"1"}
                    fill={colors.blue.link}
                />
            </Flex>
        </Flex>
    );
};

const formWidth = ["100%", "370px"];
const formHeight = "190px";
const formStyle = {
    borderRadius: radii.medium,
    padding: "16px",
    marginTop: 30,
    zIndex: 1,
    height: formHeight,
    width: formWidth,
}
const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: colors.grey.dark,
        overflow: "hidden",
        paddingBottom: "40px",
    },
    HeaderText: {
        marginTop: 50,
        fontWeight: fontWeights.regular,
        textAlign: "center",
        maxWidth: [config.postGenesis ? "300px" : "250px", "600px"],
        fontSize: "36px",
        lineHeight: "1.2",
    },
    ModeSelectorContainer: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: ["center", "space-between"],
        borderRadius: radii.small,
        border: borders.primary,
        marginTop: "30px",
        zIndex: 1,
        width: formWidth,
    },
    ModeSelectorOption: {
        paddingLeft: "14px",
        paddingRight: "14px",
        paddingTop: "8px",
        paddingBottom: "8px",
        width: ["100%", "auto"],
        cursor: "pointer",
        borderRadius: "7px",
        flexDirection: "row",
        justifyContent: ["center", "flex-start"],
        alignItems: "center",
        position: "relative",
    },
    PreSwapTooltip: {
        padding: "10px",
        height: "40px",
        backgroundColor: colors.green,
        position: "absolute",
        top: "-50px",
        right: "-30px",
        borderRadius: radii.small,
    },
    PreswapToolTipArrow: {
        top: "-17px",
        right: "15px",
        width: "12px",
        height: "12px",
        backgroundColor: colors.green,
        transform: "rotate(45deg)",
        position: "absolute",
    },
    SelectedModeSelectorOption: {
        backgroundColor: hexToRGB(colors.grey.light, "0.2")
    },
    BalancesDisplayContainer: {
        border: borders.primary,
        borderRadius: radii.small,
        width: formWidth,
        marginTop: "30px",
        flexDirection: "column",
    },
    BalancesDisplayRow: {
        width: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "28px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    InfoBoxContainer: {
        marginTop: "55px",
        backgroundColor: colors.darkCardBackground,
        border: borders.primary,
        width: formWidth,
        borderRadius: radii.medium,
        padding: ["10px", "16px"],
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        zIndex: 1,
    },
    InfoBoxRowText: {
        marginTop: ["15px", "8px"],
    },
    InfoBoxLinkRow: {
        flexDirection: "row",
        marginTop: "15px",
        alignItems: "center",
        pointer: "cursor",
    },
};

export default GenesisView;