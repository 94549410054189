import React, { Fragment } from "react";
import { fonts } from "../../../../providers/theme/theme";
import GoogleCalendar from "../../../svgs/GoogleCalendar";
import Flex from "../../../ui/Flex";
import { BodyText, HeaderText, TitleText } from "../../../ui/Text";
import ReactLoaderSpinner from "react-loader-spinner"


export default function TimerBlockView({
    daysLeft,
    hoursLeft,
    minutesLeft,
    endDate,
    onClickGoogleCalendarLink,
    hideCountdown,
    noCalendar,
    customVerb,
    customMarchDate,
    customTime,
    customMarginTop,
    customMonth,
}) {
    const days = `${daysLeft} ${daysLeft === 1 ? "day" : "days"}`;
    const hours = `${hoursLeft} ${hoursLeft === 1 ? "hour" : "hours"}`;
    const minutes = `${minutesLeft} ${minutesLeft === 1 ? "minute" : "minutes"}`;
    const timeLeft = `${days} ${hours} ${minutes} left`;
    const endingAt = `${customVerb || "Ends"} ${customMonth || "Mar"} ${customMarchDate || "3"}, ${customTime || "12:01 PM PT"}`;

    if (hideCountdown) {
        return (
            <Flex>
                <TitleText text={"Coming very soon"}/>
            </Flex>
        );
    }

    return (
        <Flex 
            {...styles.TimerBlockContainer}
            {...{marginTop: customMarginTop}}
        >
            <Flex {...styles.TimeLeftRow}>
                <Flex marginTop={"2px"}>
                    <ReactLoaderSpinner
                        type={"Watch"}
                        color={"white"}
                        height={"30px"}
                        width={"30px"}
                    />
                </Flex>

                <HeaderText
                    mono
                    text={timeLeft}
                    {...styles.TimeLeftText}
                />
            </Flex>
            <Flex {...styles.SecondRow}>
                <BodyText
                    secondary mono
                    text={endingAt}
                    {...styles.SubtitleText}
                />
                {
                    !noCalendar && (
                        <Flex 
                            {...styles.GoogleCalendarLinkRow}
                            onClick={onClickGoogleCalendarLink}
                        >
                            <GoogleCalendar/>
                            <BodyText
                                text={"Create Calendar Event"}
                                {...styles.GoogleCalText}
                            />
                        </Flex>
                    )
                }
            </Flex>

        </Flex>
    );
}

const styles = {
    TimerBlockContainer: {
        marginTop: "40px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        
    },
    TimeLeftRow: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "center",
        maxWidth: ["220px", "600px"]
    },
    TimeLeftText: {
        marginLeft: [0, 20],
        fontWeight: "400",
        textAlign: "center",
        marginTop: [15, 0],
        fontSize: "22px",
        lineHeight: "30px",
        fontFamily: fonts.mono
    },
    SubtitleText: {
        opacity: 0.4,
        textAlign: "center",
        fontSize: ["13px", "15px"]
    },
    SecondRow: {
        marginTop: ["10px", "20px"],
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
    },
    GoogleCalText: {
        color: "#4285F4",
        marginLeft: "8px",
    },
    GoogleCalendarLinkRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        marginTop: ["15px", "0px"],
        marginLeft: ["0px", "20px"]
    }
};