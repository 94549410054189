import { useEffect, useState } from "react";
import {useEthereum} from "../../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";


export function useGenesisRedeemLogic() {

    const [broadcastingTx, setBroadcastingTx] = useState(false);
    const [confirmingTx, setConfirmingTx] = useState(false);
    const [blockNoTokensMessage, setBlockNoTokensMessage] = useState(false);

    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();

    const redeemableGenesisFei = get(protocolState, "GENESIS.REDEEMABLE_GENESIS_FEI", "0");
    const redeemableGenesisTribe = get(protocolState, "GENESIS.REDEEMABLE_GENESIS_TRIBE", "0");
    const redeemableIdoTribe = get(protocolState, "GENESIS.REDEEMABLE_IDO_TRIBE", "0");

    const redeemableIdoTribeBN = new BigNumber(redeemableIdoTribe);
    const redeemableGenesisTribeBN = new BigNumber(redeemableGenesisTribe);
    const redeemableTribeTotalBN = redeemableIdoTribeBN.plus(redeemableGenesisTribeBN);
    const redeemableTribeTotal = redeemableTribeTotalBN.toString();

    const hasNoRedeemableTokens = redeemableTribeTotal === "0" && redeemableGenesisFei === "0";

    const onClickRedeemButton = async () => {
        // This is to make sure we don't show the "no tokens" message
        // right after the transaction confirms.
        setBlockNoTokensMessage(true);
        setBroadcastingTx(true);
        await transactions.GenesisRedeem(null, {
            onBroadcast: () => {
                setBroadcastingTx(false);
                setConfirmingTx(true);
            },
            onConfirm: () => {
                setConfirmingTx(false);
            },
            onFailure: () => {
                setBroadcastingTx(false);
                setConfirmingTx(false);
            },
        });
    };

    return {
        connectedAccount,
        redeemableGenesisFei,
        redeemableTribeTotal,
        onClickRedeemButton,
        broadcastingTx,
        confirmingTx,
        hasNoRedeemableTokens,
        blockNoTokensMessage,
    };
}