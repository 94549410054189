import { useState } from "react"
import get from "lodash/get";
import BigNumber from "bignumber.js";
import { useEthereum } from "../../../providers/ethereum";
import { useHistory, useLocation} from "react-router-dom";
import {EVENTS} from "../../../providers/analytics/events";
import config from "../../../config";

export function useFeiHeaderLogic({contextName, ...props}) {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    };

    const {
        connectedAccount,
        protocolState,
    } = useEthereum();
    
    const history = useHistory();
    const location = useLocation();

    const pathname = get(location, "pathname", "");
    const redeemableGenesisFei = get(protocolState, "GENESIS.REDEEMABLE_GENESIS_FEI", "0");
    const redeemableGenesisTribe = get(protocolState, "GENESIS.REDEEMABLE_GENESIS_TRIBE", "0");
    const redeemableIdoTribe = get(protocolState, "GENESIS.REDEEMABLE_IDO_TRIBE", "0");

    const redeemableIdoTribeBN = new BigNumber(redeemableIdoTribe);
    const redeemableGenesisTribeBN = new BigNumber(redeemableGenesisTribe);
    const redeemableGenesisFeiBN = new BigNumber(redeemableGenesisFei);
    const isInGenesisPage = pathname.includes("genesis");
    
    const hasRedeemableGenesisTokens = 
        redeemableIdoTribeBN.gt(0) || 
        redeemableGenesisTribeBN.gt(0) || 
        redeemableGenesisFeiBN.gt(0);

    const displayRedeemTokensMessage = 
        config.postGenesis && 
        hasRedeemableGenesisTokens &&
        !isInGenesisPage;

    function goToPath(path) {
        history.push(path);
        setMenuOpen(false);
    }

    let links = [
        // {
        //     displayName: "Genesis",
        //     onClick: () => goToPath("/genesis"),
        //     eventTrackingName: `GENESIS_${EVENTS.GENERIC.HEADER_LINK}`,
        //     notification: displayRedeemTokensMessage ? "Redeem your Genesis tokens!" : "",
        //     onClickNotification: () => goToPath("/genesis"),
        // },
        {
            displayName: "Farm",
            onClick: () => goToPath("/farm"),
            eventTrackingName: `STAKE_${EVENTS.GENERIC.HEADER_LINK}`,
        },
        // {
        //     displayName: "Exchange",
        //     onClick: () => goToPath("/exchange"),
        //     eventTrackingName: `EXCHANGE_${EVENTS.GENERIC.HEADER_LINK}`,
        // },
        {
            displayName: "Redeem",
            onClick: () => goToPath("/redeem"),
            eventTrackingName: `FIP_2_REDEEM_${EVENTS.GENERIC.HEADER_LINK}`,
        },
        // {
        //     displayName: "Analytics",
        //     onClick: () => goToPath("/analytics"),
        //     eventTrackingName: `ANALYTICS_${EVENTS.GENERIC.HEADER_LINK}`,
        // },
        {
            displayName: "Governance",
            onClick: () => {
                window.open("https://www.withtally.com/governance/fei")
            }
        },
    ];

    return {
        menuOpen,
        toggleMenu,
        links,
        connectedAccount,
        contextName,
        ...props,
    };
}