import React, { createContext, useContext, useMemo, useState, useEffect, Fragment, useRef } from "react";
import Web3 from "web3";
import Web3Modal, {getInjectedProvider} from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import get from "lodash/get";
import WrongEthereumNetworkModal from "../../components/ui/Modal/WrongEthereumNetworkModal";
import { useContracts } from "./contracts";
import useTransactions from "./transactions";
import ethConfig from "./config";
import config from "../../config";
import useFeiProtocolWatcher from "./feiProtocolWatcher";
import { hexStringToInt, normalizeChainId } from "../../utils/numbers";
import { walletConnectTheme } from "../theme/theme";

import MetaMaskFoxSvgSource from "../../assets/images/metamask_fox.svg";
import WalletConnectSvgSource from "../../assets/images/walletconnect_circle_blue.svg";

const {
    rpcUrl,
    networkName,
    chainId,
} = ethConfig;

const walletConnectRpcConfig = {
    [hexStringToInt(chainId)]: rpcUrl,
};

const EthereumContext = createContext();
export const useEthereum = () => useContext(EthereumContext);

export default function EthereumProvider({ children }) {
    const [ethState, _setEthState] = useState({
        connectedAccount: null,
        connectedChainId: null,
        alchemyProvider: null,
        web3Modal: null,
        walletProvider: null,
    });
    
    const stateRef = useRef(ethState);
    const setEthState = (newState) => {
        if (typeof(newState.connectedAccount) === "string") {
            newState.connectedAccount = newState.connectedAccount.toLowerCase();
        }
        stateRef.current = newState;
        _setEthState(newState);
    }

    const {
        connectedAccount,
        connectedChainId,
        web3Modal,
        walletProvider,
        alchemyProvider,
    } = ethState;

    const walletProviderName = getWalletProviderName(walletProvider);

    const {
        contracts,
    } = useContracts({
        connectedChainId,
        walletProvider,
        alchemyProvider,
        connectedAccount,
    });

    const {
        protocolState,
        recreateWatcher,
    } = useFeiProtocolWatcher({
        contracts,
        connectedAccount,
    });

    const transactions = useTransactions({
        connectedAccount,
        contracts,
        alchemyProvider,
        recreateWatcher,
    });

    const setupEthereum = async () => {

        const web3Modal = new Web3Modal({
            network: networkName.toLowerCase(), // optional
            cacheProvider: true, // optional // TODO: set to true
            providerOptions: getWeb3ModalProviderOptions(), // required
            theme: walletConnectTheme,
        });

        web3Modal.on("connect", onNewWeb3modalProvider);

        const web3AlchemyProvider = new Web3(rpcUrl);

        setEthState({
            ...stateRef.current,
            alchemyProvider: web3AlchemyProvider,
            web3Modal,
        });

    };

    const requestConnectAccount = async () => {
        if (web3Modal) {
            try {
                await web3Modal._toggleModal();
            } catch (error) {
                // Usually a "Modal closed by user". Ignore
            }
        }
    };

    const disconnectAccount = () => {
        if (walletProviderName === "WALLET_CONNECT") {
            const localStorage = get(window, "localStorage");
            localStorage.removeItem("walletconnect");

            setEthState({
                ...stateRef.current,
                connectedAccount: null,
                connectedChainId: null,
            });
        }
    };


    const onNewWeb3modalProvider = async (modalProvider) => {
        const newConnectedAccount = getConnectedAccount(modalProvider);
        const modalProviderChainId = get(modalProvider, "chainId");

        const walletProvider = new Web3(modalProvider);

        setEthState({
            ...stateRef.current,
            walletProvider,
            connectedAccount: newConnectedAccount,
            connectedChainId: normalizeChainId(modalProviderChainId),
        });

        modalProvider.on("accountsChanged", (accounts) => {
            const newAddress = get(accounts, "0");
            setEthState({
                ...stateRef.current,
                connectedAccount: newAddress,
            });
        });
        
        modalProvider.on("chainChanged", (newChainId) => {
            setEthState({
                ...stateRef.current,
                connectedChainId: normalizeChainId(newChainId),
            });
        });
    };

    async function watchAssetsFromWallet() {
        const assetsToWatch = [
            {
                address: get(contracts, "Fei._address"),
                symbol: "FEI",
                decimals: 18,
                image: "https://assets.fei.money/images/fei-token-v1-32.svg",
            },
            {
                address: get(contracts, "Tribe._address"),
                symbol: "TRIBE",
                decimals: 18,
                image: "https://assets.fei.money/images/tribe-token-v1-32.svg",
            },
            // {
            //     address: get(contracts, "FeiTribeUniswapV2Pair._address"),
            //     symbol: "FEI-TRIBE",
            //     decimals: 18,
            //     image: "https://assets.fei.money/images/fei-tribe-lp-token-64-96.png",
            // },
        ];
        const requestFunc = get(walletProvider, "currentProvider.request");
        if (requestFunc) {
            for (let i = 0; i < assetsToWatch.length; i++) {
                await requestFunc({
                    method: 'wallet_watchAsset',
                    params: {
                      type: 'ERC20',
                      options: assetsToWatch[i],
                    },
                });
            }
    
        }
    }
    
    useEffect(() => {
        // if there is already a provider that we have used on this browser
        if (web3Modal && web3Modal.cachedProvider) {
            // TODO: turn this on to get the wallet to autoconnect on load
            // connectToWeb3ModalWallet();
        }
    }, [web3Modal]);

    // On setup: connect to wallet, get selected address and setup event listeners
    useEffect(() => {
        setupEthereum();
    }, []);

    const value = useMemo(() => {
        return {
            connectedAccount,
            requestConnectAccount,
            disconnectAccount,
            contracts,
            protocolState,
            transactions,
            loading: !contracts,
            walletProviderName,
            watchAssetsFromWallet,
            connectedChainId,
        };
    }, [ethState, transactions, contracts, protocolState]);

    return (
        <Fragment>
            <WrongEthereumNetworkModal 
                connectedChainId={connectedChainId}
                disconnectAccount={disconnectAccount}
            />
            <EthereumContext.Provider value={value}>
                {children}
            </EthereumContext.Provider>
        </Fragment>
    );
}

// Helpers

function getWeb3ModalProviderOptions() {

    const injectedProvider = getInjectedProvider();
    const injectedProviderName = get(injectedProvider, "name", "");
    const injectedProviderLogo = injectedProviderName === "MetaMask" ?
        MetaMaskFoxSvgSource:
        get(injectedProvider, "logo", "");

    return {
        walletconnect: {
            package: WalletConnectProvider, // required
            display: {
                logo: WalletConnectSvgSource,
            },
            options: {
                bridge: config.dedicatedWalletConnectBridgeUrl,
                rpc: walletConnectRpcConfig,
            }
        },
        injected: {
            display: {
              logo: injectedProviderLogo,
              name: injectedProviderName,
              description: `Connect with ${injectedProviderName} in your Browser`
            },
            package: null
        },
    };
}


// TODO: take the below functions and abstract them into something that standardizes
// the differences between the different provider interfaces in each kind of wallet.

function getConnectedAccount(modalProvider) {
    // This is where metamask puts it
    const selectedAddress = get(modalProvider, "selectedAddress");
    // This is where wallletconnect puts it
    const accountsAtZero = get(modalProvider, "accounts.0"); 
    // Only one provider connected at a time, so this will always work
    return selectedAddress || accountsAtZero; 
}

function getWalletProviderName(walletProvider) {
    const isMetamask = get(walletProvider, "currentProvider.isMetaMask");
    if (isMetamask) {
        return "METAMASK";
    }

    const isWalletConnect = "wc" === get(walletProvider, "currentProvider.wc.protocol");
    if (isWalletConnect) {
        return "WALLET_CONNECT";
    }

    return "";


}