import React, {Fragment} from "react";
import { colors, radii, borders } from "../../../../providers/theme/theme";
import { PrimaryButton } from "../../../ui/Button";
import ConnectWalletButton from "../../../ui/Button/ConnectWalletButton";
import Flex from "../../../ui/Flex";
import { BoldSpan, MiniText, BodyText, TitleText } from "../../../ui/Text";
import {CONTEXT_NAMES, EVENTS} from "../../../../providers/analytics/events";
import { formatCryptoAmount, formatDollarAmount } from "../../../../utils/numbers";
import TribeIcon from "../../../svgs/TribeIcon";
import FeiIcon from "../../../svgs/FeiIcon";
import FeiIconJustPyramid from "../../../svgs/FeiIconJustPyramid";
import TribeIconJustMonoliths from "../../../svgs/TribeIconJustMonoliths";


export default function GenesisRedeemView({
    connectedAccount,
    redeemableGenesisFei,
    redeemableTribeTotal,
    onClickRedeemButton,
    broadcastingTx,
    confirmingTx,
    hasNoRedeemableTokens,
    blockNoTokensMessage,
}) {
    const displayNoTokensMessage = 
        connectedAccount && 
        hasNoRedeemableTokens && 
        !blockNoTokensMessage;

    return (
        <Fragment>
            <Flex {...styles.Container}>
                <RedeemableGenesisTokenRow
                    IconComponent={FeiIcon}
                    amount={formatDollarAmount(redeemableGenesisFei)}
                    tokenSymbol={"FEI"}
                />
                <RedeemableGenesisTokenRow
                    IconComponent={TribeIcon}
                    amount={formatCryptoAmount(redeemableTribeTotal)}
                    tokenSymbol={"TRIBE"}
                />
                {
                    connectedAccount ? (
                    <PrimaryButton
                        {...styles.PrimaryButton}
                        onClick={onClickRedeemButton}
                        broadcastingTx={broadcastingTx}
                        confirmingTx={confirmingTx}
                        disabled={hasNoRedeemableTokens}
                        tracking={{
                            contextName: CONTEXT_NAMES.GENESIS,
                            eventName: EVENTS.GENESIS.REDEEM
                        }}
                    >
                        <Flex {...styles.PrimaryButtonContent}>
                            <FeiIconJustPyramid
                                fill={"white"}
                                marginTop={"3px"}
                            />
                            <TitleText
                                bold
                                text={"Redeem"}
                                {...styles.PrimaryButtonText}
                            />
                            <TribeIconJustMonoliths 
                                fill={"white"}
                            />
                        </Flex>
                    </PrimaryButton>
                    
                    ) : (
                        <ConnectWalletButton contextName={CONTEXT_NAMES.GENESIS}/>
                    )
                }
            </Flex>
            {
                displayNoTokensMessage && (
                    <Flex 
                        {...styles.InfoBoxContainer}
                    >
                        <BodyText
                            bold 
                            text={"You don't have any redeemable tokens"}
                        />
                        <MiniText
                            paragraph
                            text={"Connected wallet did not participate in Fei Genesis or has already redeemed Genesis tokens."}
                            marginTop={"10px"}
                        />
                    </Flex>
                )
            }
        </Fragment>
    );
}

const RedeemableGenesisTokenRow = ({
    IconComponent,
    amount,
    tokenSymbol
}) => {
    return (
        <Flex {...styles.RedeemableGenesisTokenRow}>
            <IconComponent width={"30px"} height={"43px"}/>
            <TitleText mono light>
                {amount} <BoldSpan>{tokenSymbol}</BoldSpan>
            </TitleText>
        </Flex>
    );
}

const styles = {
    Container: {
        backgroundColor: "#3E4256",
        borderRadius: radii.medium,
        padding: "16px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: 80,
        width: ["100%", "330px"],
        zIndex: 1,
    },
    PrimaryButton: {
        width: "100%",
        backgroundColor: colors.green,
        hoverBg: colors.green,
        marginTop: 15,
    },
    PrimaryButtonContent: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    PrimaryButtonText: {
        marginLeft: 10,
        marginRight: 10,
    },
    RedeemableGenesisTokenRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    InfoBoxContainer: {
        marginTop: "30px",
        backgroundColor: colors.darkCardBackground,
        border: borders.primary,
        width: ["100%", "330px"],
        borderRadius: radii.medium,
        padding: ["10px", "16px"],
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        zIndex: 1,
    },
};