import React from "react";

import Svg from "../ui/Svg";

export default function DownloadIcon({ width = 20, height = 20, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.7271 19.9998H2.2727C1.66994 19.9998 1.09187 19.7603 0.665659 19.3341C0.239445 18.9079 6.21214e-09 18.3298 6.21214e-09 17.7271V14.2397C-1.48464e-05 13.8378 0.0266036 13.4364 0.0796807 13.0381L1.5553 1.97234C1.62809 1.42643 1.89663 0.925519 2.31101 0.562736C2.72538 0.199954 3.25739 -1.74041e-05 3.80814 1.13611e-09H16.192C16.7427 4.62543e-06 17.2747 0.199986 17.6891 0.562766C18.1034 0.925547 18.372 1.42644 18.4447 1.97234L19.9201 13.0381C19.9733 13.4364 20 13.8378 20 14.2397V17.7271C20 18.0256 19.9412 18.3211 19.827 18.5969C19.7127 18.8726 19.5453 19.1232 19.3342 19.3342C19.1232 19.5452 18.8726 19.7126 18.5969 19.8268C18.3211 19.941 18.0255 19.9998 17.7271 19.9998ZM18.0968 13.1216L16.6423 2.21261C16.6278 2.10344 16.5741 2.00328 16.4912 1.93073C16.4083 1.85817 16.3019 1.81817 16.1918 1.81816H3.80805C3.69792 1.81819 3.59155 1.85819 3.5087 1.93074C3.42585 2.00329 3.37216 2.10345 3.3576 2.21261L1.90307 13.1216C1.89451 13.1858 1.89976 13.2511 1.91848 13.3131C1.93721 13.3751 1.96896 13.4323 2.01162 13.4811C2.05428 13.5298 2.10687 13.5689 2.16585 13.5956C2.22483 13.6224 2.28884 13.6362 2.35361 13.6362H5.58175C5.67716 13.6362 5.77014 13.6662 5.84754 13.722C5.92493 13.7778 5.98281 13.8565 6.01298 13.947L6.71488 16.0526C6.74504 16.1431 6.80292 16.2219 6.88032 16.2776C6.95771 16.3334 7.0507 16.3635 7.1461 16.3635H12.8544C12.9498 16.3635 13.0428 16.3334 13.1202 16.2776C13.1976 16.2219 13.2555 16.1431 13.2856 16.0526L13.9875 13.947C14.0177 13.8565 14.0756 13.7778 14.153 13.722C14.2304 13.6662 14.3233 13.6362 14.4187 13.6362H17.6467C17.7115 13.6362 17.7755 13.6223 17.8344 13.5956C17.8934 13.5688 17.9459 13.5297 17.9886 13.481C18.0312 13.4323 18.063 13.375 18.0817 13.313C18.1004 13.251 18.1054 13.1858 18.0968 13.1216Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.45148 3.68048V8.75825H6.41823C6.32159 8.7584 6.22699 8.78606 6.14549 8.83801C6.064 8.88995 5.99898 8.96403 5.95805 9.05158C5.91712 9.13912 5.90196 9.23652 5.91436 9.33236C5.92675 9.4282 5.96619 9.51854 6.02804 9.59279L9.83813 14.1721C9.88575 14.2294 9.94539 14.2755 10.0128 14.3071C10.0803 14.3388 10.1539 14.3552 10.2283 14.3552C10.3028 14.3552 10.3764 14.3388 10.4439 14.3071C10.5113 14.2755 10.5709 14.2294 10.6186 14.1721L14.4286 9.59279C14.4904 9.51852 14.5298 9.42818 14.5422 9.33234C14.5546 9.23649 14.5394 9.1391 14.4985 9.05156C14.4576 8.96402 14.3925 8.88995 14.311 8.838C14.2295 8.78606 14.1349 8.7584 14.0383 8.75825H12.0046V3.68048C12.0046 3.61379 11.9915 3.54775 11.966 3.48613C11.9406 3.4245 11.9032 3.3685 11.856 3.32133C11.8089 3.27415 11.7529 3.23672 11.6913 3.21117C11.6297 3.18562 11.5637 3.17245 11.497 3.17242H8.95903C8.82436 3.17251 8.69523 3.22607 8.60005 3.32135C8.50487 3.41663 8.45143 3.54581 8.45148 3.68048Z" fill="white"/>
        </Svg>
    );
}
