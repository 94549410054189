import {useEffect, useState} from "react";
import get from "lodash/get";
import BigNumber from "bignumber.js";

import { useEthereum } from "../../../providers/ethereum";

export const MODES = {
    GENESIS_DEPOSIT: "GENESIS_DEPOSIT",
    IDO_COMMITMENT: "IDO_COMMITMENT",
    EXPLORE_SCENARIOS: "EXPLORE_SCENARIOS",
};

export function useGenesisLogic() {

    const {
        protocolState,
        loading,
    } = useEthereum();

    const JSON_MODE = protocolState;

    const [mode, setMode] = useState(MODES.GENESIS_DEPOSIT);
    const [displayPreswapTooltip, setDisplayPreswapTooltip] = useState(false);

    useEffect(() => {
        if (mode === MODES.IDO_COMMITMENT) {
            setDisplayPreswapTooltip(false);
        }
    }, [mode]);

    const userFgen = get(protocolState, "BALANCES.FGEN", "0");
    const userFgenCommitted = get(protocolState, "BALANCES.FGEN_COMMITTED", "0");
    const globalFgenSupply = get(protocolState, "GENESIS.FGEN_TOTAL_SUPPLY", "0");
    const globalFgenCommittedSupply = get(protocolState, "GENESIS.FGEN_TOTAL_COMMITTED", "0");

    const userFgenBN = new BigNumber(userFgen);
    const userFgenCommittedBN = new BigNumber(userFgenCommitted);
    const globalFgenSupplyBN = new BigNumber(globalFgenSupply);
    const globalFgenCommittedSupplyBN = new BigNumber(globalFgenCommittedSupply);

    const totalDepositedInGenesisBN = globalFgenSupplyBN.plus(globalFgenCommittedSupplyBN);
    const userDepositedInGenesisBN = userFgenBN.plus(userFgenCommittedBN);

    const userHasDeposits = userFgenBN.gt(0) || userFgenCommittedBN.gt(0);
    const preswapDisabled = !userHasDeposits;
    const genesisDeposits = {
        OVERALL_DEPOSITS: {
            user: userDepositedInGenesisBN.toString(),
            global: totalDepositedInGenesisBN.toString(),
        },
        FGEN_COMMITTED: {
            user: userFgenCommitted,
            global: globalFgenCommittedSupply,
        },
    };
    

    const selectorConfig = [
        {
            label: "1. Commit ETH 🏦",
            mode: MODES.GENESIS_DEPOSIT,
        },
        {
            label: "2. Pre-Swap for TRIBE 🌲",
            mode: MODES.IDO_COMMITMENT,
            disabled: preswapDisabled,
        },
        // {
        //     label: "🔭 Explore Scenarios",
        //     mode: MODES.EXPLORE_SCENARIOS,
        // }
    ];

    return {
        JSON_MODE,
        mode,
        setMode,
        selectorConfig,
        genesisDeposits,
        loading,
        displayPreswapTooltip,
        setDisplayPreswapTooltip,
    }
}