import React from "react";
import Flex from "./Flex";
import { borders, colors, radii } from "../../providers/theme/theme";
import {BodyText, MiniText} from "./Text";

import UniswapUnicornIcon from "../svgs/UniswapUnicornIcon";
import { PrimaryButton } from "./Button";

export default function UniswapLinkBox({
    url,
    buttonText,
    bodyText,
    titleText
}) {
    return (
        <Flex {...styles.UniswapLinkContainer}>
            <BodyText 
                bold 
                text={titleText}
            />
            <MiniText 
                paragraph
                text={bodyText}
                marginTop={"10px"}
                maxWidth={"250px"}
                textAlign={"center"}
            />
            <PrimaryButton
                {...styles.UniswapButton}
                onClick={() => {
                    window.open(url);
                }}
            >
                <Flex {...styles.ButtonContent}>
                    <UniswapUnicornIcon
                        marginRight={"8px"}
                        marginBottom={"2px"}
                    />
                    <MiniText
                        bold
                        text={buttonText}
                    />
                </Flex>
            </PrimaryButton>
        </Flex>
    );
}

const styles = {
    UniswapLinkContainer: {
        backgroundColor: colors.darkCardBackground,
        // border: borders.primary,
        padding: "15px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: radii.small,
        marginTop: "100px",
        // width: ["100%", "300px"]
    },  
    UniswapButton: {
        marginTop: "15px",
        height: "35px",
        minHeight: "35px",
        paddingLeft: "10px",
        paddingRight: "10px",
        backgroundColor: colors.pink.uniswap,
        hoverBg: colors.pink.uniswap,
        width: "100%",
    },
    ButtonContent: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    }
}