import React from "react";

import Svg from "../ui/Svg";

export default function ExchangeIcon({ width = 22, height = 18, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3001 17.2808V14.0843H0.73334C0.328327 14.0843 0 13.7174 0 13.2647V12.4451C0 11.9924 0.328327 11.6255 0.73334 11.6255H21.5602C22.0002 11.6255 22.1102 12.2402 21.7802 12.4861L14.9601 17.6497C14.6301 17.8955 14.3001 17.6497 14.3001 17.2808Z" fill="white"/>
            <path d="M0.21971 5.84727L7.03977 0.683693C7.36977 0.437808 7.69978 0.683693 7.69978 1.05252V4.24902H21.2666C21.6716 4.24902 21.9999 4.61597 21.9999 5.06863V5.88825C21.9999 6.34091 21.6716 6.70786 21.2666 6.70786H0.439712C-0.000291582 6.70786 -0.110293 6.09315 0.21971 5.84727Z" fill="white"/>
        </Svg>
    );
}








