import {useRef, useState} from "react";
import { useToasts } from "react-toast-notifications";
import get from "lodash/get";
import set from "lodash/set";
import {CONTEXT_NAMES, EVENT_TYPES } from "../analytics/events";
import {track} from "../analytics";

// Check every 6 seconds for 5 minutes (50 checks)
const MAX_TX_STATUS_CHECK_ATTEMPTS = 50;
const STATUS_CHECK_INTERVAL = 6000;

export default function useTransactionStatusManager({
    alchemyProvider,
}) {

    const { addToast } = useToasts();
    // This is a mapping of tx hashes to status check attempt counts
    const [txStatus, _setTxStatus] = useState({});
    const txStatusRef = useRef(txStatus);

    const getCurrentStatusCheckAttempt = (lowerCaseTxHash) => {
        return get(txStatusRef, `current.${lowerCaseTxHash}.attemptCount`);
    };

    const getBroadcast = (lowerCaseTxHash) => {
        return get(txStatusRef, `current.${lowerCaseTxHash}.broadcast`);
    };

    const incrementStatusCheckAttempt = (lowerCaseTxHash) => {
        let currentAttemptCount = get(txStatusRef, `current.${lowerCaseTxHash}.attemptCount`);
        if (!currentAttemptCount) {
            currentAttemptCount = 0;
        }
        currentAttemptCount += 1;
        const currentTxStatus = get(txStatusRef, "current");

        set(currentTxStatus, `${lowerCaseTxHash}.attemptCount`, currentAttemptCount);
        txStatusRef.current = currentTxStatus;
        _setTxStatus(currentTxStatus)
    };

    const setBroadcast = (lowerCaseTxHash) => {
        const currentTxStatus = get(txStatusRef, "current");

        set(currentTxStatus, `${lowerCaseTxHash}.broadcast`, true);
        txStatusRef.current = currentTxStatus;
        _setTxStatus(currentTxStatus);
    };

    const handleTransactionSubmissionError = ({
        error,
        txActionMessage,
        trackingData,
    }) => {
        console.log(error);
        let errorNotification = "";
        const errorMessage = get(error, "message", "");
        if (error.code === 4001 || errorMessage.includes("User rejected the transaction")) {
            errorNotification = `You rejected the transaction to ${txActionMessage}. Try again.`;
        } else if (error.message && error.message.toLowerCase().includes("revert")) {
            errorNotification = `Transaction to ${txActionMessage} reverted. Try again`;
        } else {
            errorNotification = `There was a problem submitting your transaction to ${txActionMessage}.`;
        }
        addToast(errorNotification, {appearance: "error"});
        trackingData.errorMessage = errorNotification;
        track({
            eventType: EVENT_TYPES.TX_ERROR,
            contextName: CONTEXT_NAMES.TRANSACTIONS,
        }, trackingData);
    };

    const monitorSubmittedTransaction = ({
        txHash,
        txActionMessage,
        onTxBroadcast = () => {},
        onTxSuccess = () => {},
        onTxRevert = () => {},
        trackingData,
    }) => {
        // Check for transaction mined
        if (!txHash || !alchemyProvider) {
            return;
        }

        const lowerCaseTxHash = txHash.toLowerCase();

        const interval = setInterval(async () => {
            
            const txReceipt = await alchemyProvider.eth.getTransactionReceipt(txHash);
            incrementStatusCheckAttempt(txHash);
            
            if (!txReceipt) {
                return;
            }

            // If its the first time seeing the tx receipt,
            // show "submitted" notification and mark the tx as
            // having been broadcast
            const hasBeenBroadcast = getBroadcast(lowerCaseTxHash);
            if (txReceipt && !hasBeenBroadcast) {
                addToast(`Transaction to ${txActionMessage} submitted`, {
                    appearance: "info",
                    transactionHash: txHash,
                });
                onTxBroadcast();
                setBroadcast(lowerCaseTxHash);
                return;
            }

            const {
                blockHash,
                status,
            } = txReceipt;

            // Successfully mined
            if (blockHash && status) {
                const message = `Transaction to ${txActionMessage} mined! 🌲`;
                addToast(
                    message,
                    {
                        appearance: "success",
                        transactionHash: txHash,
                    }
                );
                onTxSuccess();
                trackingData.message = message;
                track({
                    eventType: EVENT_TYPES.TX_SUCCESS,
                    contextName: CONTEXT_NAMES.TRANSACTIONS,
                }, trackingData);
            }


            // Mined but reverted
            if (blockHash && !status) {
                const errorMessage = `Transaction to ${txActionMessage} reverted`;
                addToast(errorMessage, {
                    appearance: "error",
                    transactionHash: txHash,
                });
                onTxRevert();
                trackingData.errorMessage = errorMessage;
                track({
                    eventType: EVENT_TYPES.TX_ERROR,
                    contextName: CONTEXT_NAMES.TRANSACTIONS,
                }, trackingData);
            }

            // Stop checking if mined or rate limited
            const currentStatusCheckAttempt = getCurrentStatusCheckAttempt(lowerCaseTxHash);
            if (blockHash || currentStatusCheckAttempt > MAX_TX_STATUS_CHECK_ATTEMPTS) {
                clearInterval(interval)
            }

        }, STATUS_CHECK_INTERVAL);
    };


    return {
        handleTransactionSubmissionError,
        monitorSubmittedTransaction,
    }
}