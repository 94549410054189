import React, { Fragment }  from "react";
import { colors, radii } from "../../../providers/theme/theme";
import { hexToRGB, truncateEthAddress } from "../../../utils/ui";
import MetaMaskFox from "../../svgs/MetaMaskFox";
import WalletConnectIcon from "../../svgs/WalletConnectIcon";
import FeiIcon from "../../svgs/FeiIcon";
import TribeIcon from "../../svgs/TribeIcon";
import ArrowSouthGlowing from "../../svgs/ArrowSouthGlowing";
import Flex from "../Flex";
import { BodyText, MiniText } from "../Text";
import { formatCryptoAmount } from "../../../utils/numbers";
import { PrimaryButton } from "../Button";
import {isBrowser} from "react-device-detect";
import { EVENTS } from "../../../providers/analytics/events";

export default function WalletInfoView({
    connectedAccount,
    hasConnectedAccount,
    walletProviderName,
    disconnectAccount,
    feiBalance,
    tribeBalance,
    walletMenuOpen,
    setWalletMenuOpen,
    contextName,
}) {
    

    if (!hasConnectedAccount) {
        return (<Fragment/>);
    }

    const formattedConnectedAccount = truncateEthAddress(connectedAccount, isBrowser ? 6 : 8);

    const arrowStyles = walletMenuOpen ? {
        ...styles.ArrowSouthGlowing,
        ...styles.ArrowSouthGlowingClose,
    } : styles.ArrowSouthGlowing;

    const isMetamask = walletProviderName === "METAMASK";
    let openMenuHeight = "132px";
    if (isMetamask) { // No disconnect button for metamask
        openMenuHeight = "100px";
    }

    return (
        <Flex 
            {...styles.Container}
            height={isBrowser ? "32px" : openMenuHeight}
        >
            <Flex 
                {...styles.WalletInfoBox}
                height={walletMenuOpen ? openMenuHeight : "100%"}
            >
                <Flex 
                    {...styles.WalletInfoBoxRow}
                    justifyContent={"space-between"}
                >
                    <WalletIcon walletProviderName={walletProviderName} />
                    <Flex {...styles.AddressTextContainer}>
                        <BodyText
                            text={formattedConnectedAccount}
                            {...styles.AddressText}
                        />
                    </Flex>
                    {
                        isBrowser && (
                            <ArrowSouthGlowing
                                {...arrowStyles}
                                onClick={() => setWalletMenuOpen(!walletMenuOpen)}
                            />
                        )
                    }

                </Flex>
                {
                    walletMenuOpen && (
                        <Fragment>
                            <Flex 
                                {...styles.WalletInfoBoxRow}
                            >
                                <FeiIcon/>
                                <MiniText mono bold text={formatCryptoAmount(feiBalance)} {...styles.BalanceText}/>
                            </Flex>
                            <Flex 
                                {...styles.WalletInfoBoxRow}
                                paddingBottom={"10px"}
                            >
                                <TribeIcon/>
                                <MiniText mono bold text={formatCryptoAmount(tribeBalance)} {...styles.BalanceText}/>
                            </Flex>
                            {
                                !isMetamask && (
                                    <PrimaryButton
                                        {...styles.DisconnectButton}
                                        onClick={disconnectAccount}
                                        tracking={{
                                            contextName,
                                            eventName: EVENTS.GENERIC.DISCONNECT_WALLET,
                                        }}
                                    >
                                        <MiniText
                                            text={"Disconnect"}
                                            {...styles.DisconnectButtonText}
                                        />
                                    </PrimaryButton>
                                )
                            }

                        </Fragment>
                    )
                }
            </Flex>
        </Flex>
    );
}

function WalletIcon({walletProviderName}) {
    if (walletProviderName === "METAMASK") {
        return (<MetaMaskFox/>);
    }
    
    if (walletProviderName === "WALLET_CONNECT") {
        return (<WalletConnectIcon {...styles.WalletConnectIcon}/>);
    }

    return (<Fragment/>)
}

const styles = {
    Container: {
        width: "160px",
        height: "32px",
        position: "relative",
    },
    WalletInfoBox: {
        backgroundColor: colors.darkCardBackground,
        borderRadius: radii.small,
        width: "100%",
        minHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        paddingLeft: "10px",
        paddingRight: "10px",
        flexDirection: "column",
        transition: "height 0.2s"
    },
    WalletInfoBoxRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: "32px",
    },
    WalletConnectIcon: {
        width: "22px",
        height: "auto"
    },
    AddressTextContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    AddressText: {
        fontSize: "14px",
    },
    ArrowSouthGlowing: {
        height: "18px",
        width: "auto",
        cursor: "pointer",
        display: ["none", "flex"],
    },
    ArrowSouthGlowingClose: {
        opacity: "0.6",
        transform: "rotate(180)",
    },
    BalanceText: {
        marginLeft: "10px",
    },
    DisconnectButton: {
        width: "100%",
        height: "27px",
        minHeight: "27px",
        backgroundColor: hexToRGB("#000000", "0.2"),
        hoverBg: hexToRGB("#000000", "0.2"),
        borderRadius: "7px",
    },
    DisconnectButtonText: {
        letterSpacing: "0.5px",
        fontSize: "13px",
    },
};