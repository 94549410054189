import React from "react";

import Svg from "../ui/Svg";

export default function UploadIcon({ width = 21, height = 20, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.1646 19.9998H2.7102C2.10744 19.9998 1.52937 19.7603 1.10316 19.3341C0.676945 18.9079 0.4375 18.3298 0.4375 17.7271V14.2397C0.437485 13.8378 0.464104 13.4364 0.517181 13.0381L1.9928 1.97234C2.06559 1.42643 2.33413 0.925519 2.74851 0.562736C3.16288 0.199954 3.69489 -1.74041e-05 4.24564 1.13611e-09H16.6295C17.1802 4.62543e-06 17.7122 0.199986 18.1266 0.562766C18.5409 0.925547 18.8095 1.42644 18.8822 1.97234L20.3576 13.0381C20.4108 13.4364 20.4375 13.8378 20.4375 14.2397V17.7271C20.4375 18.0256 20.3787 18.3211 20.2645 18.5969C20.1502 18.8726 19.9828 19.1232 19.7717 19.3342C19.5607 19.5452 19.3101 19.7126 19.0344 19.8268C18.7586 19.941 18.463 19.9998 18.1646 19.9998ZM18.5343 13.1216L17.0798 2.21261C17.0653 2.10344 17.0116 2.00328 16.9287 1.93073C16.8458 1.85817 16.7394 1.81817 16.6293 1.81816H4.24555C4.13542 1.81819 4.02905 1.85819 3.9462 1.93074C3.86335 2.00329 3.80966 2.10345 3.7951 2.21261L2.34057 13.1216C2.33201 13.1858 2.33726 13.2511 2.35598 13.3131C2.37471 13.3751 2.40646 13.4323 2.44912 13.4811C2.49178 13.5298 2.54437 13.5689 2.60335 13.5956C2.66233 13.6224 2.72634 13.6362 2.79111 13.6362H6.01925C6.11466 13.6362 6.20764 13.6662 6.28504 13.722C6.36243 13.7778 6.42031 13.8565 6.45048 13.947L7.15238 16.0526C7.18254 16.1431 7.24042 16.2219 7.31782 16.2776C7.39521 16.3334 7.4882 16.3635 7.5836 16.3635H13.2919C13.3873 16.3635 13.4803 16.3334 13.5577 16.2776C13.6351 16.2219 13.693 16.1431 13.7231 16.0526L14.425 13.947C14.4552 13.8565 14.5131 13.7778 14.5905 13.722C14.6679 13.6662 14.7608 13.6362 14.8562 13.6362H18.0842C18.149 13.6362 18.213 13.6223 18.2719 13.5956C18.3309 13.5688 18.3834 13.5297 18.4261 13.481C18.4687 13.4323 18.5005 13.375 18.5192 13.313C18.5379 13.251 18.5431 13.1858 18.5346 13.1216H18.5343Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.443 13.8471L12.443 8.76934L14.4763 8.76934C14.5729 8.76919 14.6675 8.74153 14.749 8.68958C14.8305 8.63763 14.8955 8.56356 14.9365 8.47601C14.9774 8.38846 14.9926 8.29107 14.9802 8.19523C14.9678 8.09938 14.9283 8.00905 14.8665 7.9348L11.0564 3.35546C11.0088 3.29818 10.9491 3.25208 10.8817 3.22044C10.8143 3.1888 10.7407 3.1724 10.6662 3.1724C10.5917 3.1724 10.5181 3.1888 10.4507 3.22044C10.3832 3.25208 10.3236 3.29818 10.276 3.35546L6.46594 7.9348C6.4041 8.00906 6.36468 8.0994 6.3523 8.19525C6.33992 8.29109 6.35509 8.38848 6.39603 8.47603C6.43697 8.56357 6.50199 8.63764 6.58349 8.68958C6.66499 8.74153 6.75959 8.76919 6.85623 8.76934L8.88991 8.76934L8.88991 13.8471C8.88988 13.9138 8.90299 13.9798 8.92848 14.0415C8.95398 14.1031 8.99136 14.1591 9.03849 14.2063C9.08563 14.2534 9.14159 14.2909 9.20319 14.3164C9.2648 14.342 9.33083 14.3551 9.39752 14.3552L11.9355 14.3552C12.0702 14.3551 12.1993 14.3015 12.2945 14.2062C12.3897 14.111 12.4431 13.9818 12.443 13.8471Z" fill="white"/>
        </Svg>
    );
}




