import React, {Suspense} from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppNavigator from "./navigation/AppNavigator";
import ApolloProvider from "./providers/apollo";
import LoadingPage from "./components/pages/Loading";
import EthereumProvider from "./providers/ethereum";
import NotificationsProvider from "./providers/notifications";

function App() {
  return (
      <ApolloProvider>
          <NotificationsProvider>
              <EthereumProvider>
                  <Suspense fallback={<LoadingPage/>}>
                      <BrowserRouter>
                          <AppNavigator/>
                      </BrowserRouter>
                  </Suspense>
              </EthereumProvider>
          </NotificationsProvider>
      </ApolloProvider>
  );
}

export default App;
