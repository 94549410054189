import React, {useState, Fragment} from "react";
import { colors, radii } from "../../providers/theme/theme";
import TooltipIcon from "../svgs/TooltipIcon";
import Flex from "./Flex";
import get from "lodash/get";
import { MiniText } from "./Text";

export default function Tooltip({
    tooltipText,
    tooltipIconStyles = {},
}) {

    const windowWidth = get(window, "innerWidth", 0);
    const isRightSideTooltip = windowWidth > 600 && tooltipText.length < 20;

    const conditionalStyles = isRightSideTooltip ? styles.RightSideTooltip : styles.BottomTooltip;
    const arrowComponent = isRightSideTooltip ? (
        <Flex 
            {...styles.TooltipArrow} 
            {...styles.PointingLeftArrow}
        />
        ) : (
        <Flex 
            {...styles.TooltipArrow} 
            {...styles.PointingUpArrow}
        />
    );

    const [displayTooltip, setDisplayTooltip] = useState(false);

    return (
        <Flex {...styles.Container}>
            <TooltipIcon
                width={tooltipIconDiameter}
                height={tooltipIconDiameter}
                onMouseEnter={() => setDisplayTooltip(true)}
                onMouseLeave={() => setDisplayTooltip(false)}
                {...tooltipIconStyles}
            />
            {
                displayTooltip && (
                    <Fragment>
                        {arrowComponent}
                        <Flex 
                            {...styles.TooltipContainer}
                            {...conditionalStyles}
                        >
                            <MiniText 
                                secondary bold dark truncated
                                text={tooltipText}
                                {...styles.TooltipText}
                            />
                        </Flex>
                    </Fragment>

                )
            }
        </Flex>
    );
}

const tooltipIconDiameter = 12;
const tooltipArrowDisplacement = tooltipIconDiameter + 10;
const tooltipDisplacement = tooltipArrowDisplacement + 4;

const styles = {
    Container: {
        position: "relative"
    },
    TooltipContainer: {
        position: "absolute",
        backgroundColor: colors.grey.lightest,
        borderRadius: radii.tiny,
        minHeight: "20px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 3,
    },
    RightSideTooltip: {
        left: tooltipDisplacement,
        top: 0,
        bottom: 0,
        margin: "auto",
        paddingTop: "17px",
        paddingBottom: "15px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    BottomTooltip: {
        top: tooltipDisplacement,
        right: -20,
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
    },
    TooltipArrow: {
        position: "absolute",
        backgroundColor: colors.grey.lightest,
        width: "8px",
        height: "8px",
        transform: "rotate(45deg)",
        zIndex: 3,
    },
    PointingLeftArrow: {
        left: tooltipArrowDisplacement,
        top: 0,
        bottom: "2px",
        margin: "auto",
    },
    PointingUpArrow: {
        top: tooltipArrowDisplacement,
        left: "4px",
        right: 0,
        margin: "auto"
    },
    TooltipText: {
        letterSpacing: "0.5px"
    }
};