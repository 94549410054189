import React, { useEffect } from "react";
import styled from "styled-components";
import {colors, fonts, fontWeights} from "../../providers/theme/theme";
import { getPixelValueFloat } from "../../utils/ui";

import {
    borderRadius,
    boxShadow,
    color,
    display,
    fontFamily,
    fontSize,
    fontWeight,
    height,
    lineHeight,
    minWidth,
    maxWidth,
    space,
    textAlign,
    width,
} from "styled-system";

import {
    focusBg,
    focusBoxShadow,
    focusColor,
    hoverBg,
    hoverBoxShadow,
    hoverColor,
    placeholderColor,
} from "../../providers/theme/customProperties";


export const InputElement = styled.input`
    ${borderRadius};
    ${boxShadow};
    ${color};
    ${display};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${height};
    ${lineHeight};
    ${minWidth};
    ${maxWidth};
    ${space};
    ${textAlign};
    ${width};

    appearance: none;
    border-width: 0;
    box-sizing: border-box;
    outline-color: transparent;
    outline-offset: 4px;
    outline-style: dashed;
    outline-style: none;
    outline-width: 1px;
    transition-duration: 150ms;
    transition-property: all;
    transition-timing-function: ease-in-out;

    @media (pointer: fine) {
        &:hover {
            ${hoverColor};
            ${hoverBoxShadow};
            ${hoverBg};
        }
    }

    &:focus {
        ${focusColor};
        ${focusBoxShadow};
        ${focusBg};
    }

    ::-webkit-input-placeholder {
        ${placeholderColor};
        line-height: normal;
    }

    ::-moz-input-placeholder {
        ${placeholderColor};
        line-height: normal;
    }

    ::placeholder {
        ${placeholderColor};
        line-height: normal;
    }

    /* Settings to remove arrows from numeric input */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    [type=number] {
        -moz-appearance: textfield;
    }
`;

function measureText({
    value = "", 
    placeholder = "",
    fontSize = 10,
}) {
    const unitOfMeasure = value || placeholder;
    return (unitOfMeasure || "")
        .split("")
        .map(x => 0.8)
        .reduce((x, y) => x + y, 0) * parseInt(fontSize);
}

function getAutoSizeProps({
    value = "", 
    placeholder = "",
    fontSize = 10,
    paddingLeft = "0",
    paddingRight = "0",
}) {
    const inputCharactersPixelWidth = measureText({value, placeholder, fontSize});
    const horizontalPadding = getPixelValueFloat(paddingLeft) + getPixelValueFloat(paddingRight);
    const overallWidth = inputCharactersPixelWidth + horizontalPadding;
    return {
        width: `${overallWidth}px`,
        textAlign: "center",
    };
}

export default function Input({
    withRef,
    autoSize,
    value,
    onChange,
    placeholder,
    fontSize,
    mono,
    bold,
    paddingLeft = "15px",
    paddingRight = "15px",
    ...props
}) {
    const autoSizeProps = autoSize ? getAutoSizeProps({
        value,
        placeholder,
        fontSize,
        paddingLeft,
        paddingRight,
    }) : {};

    const monoFontProps = mono ? { fontFamily: fonts.mono } : {};
    const boldFontProps = bold ? { fontWeight: fontWeights.bold } : {};

    // TODO: This adds an event listener for every input on the screen.
    // TODO: might produce janky behavior if there is more than 1 input
    useEffect(() => {
        document.addEventListener("wheel", function(event){
            if(document.activeElement.type === "number"){
                document.activeElement.blur();
            }
        });
    }, []);



    return (
        <InputElement
            ref={withRef}
            value={value}
            onChange={(e) => {
                onChange && onChange(e.target.value);
            }}
            placeholder={placeholder}
            fontSize={fontSize}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            {...styles.Base}
            {...autoSizeProps}
            {...monoFontProps}
            {...boldFontProps}
            {...props}
        />
    );
};

const styles = {
    Base: {
        bg: colors.white,
        color: colors.black,
        placeholderColor: colors.grey.medium,
        fontFamily: fonts.primary,
        fontWeight: "400",
        spellCheck: false,
        borderRadius: 1,
        fontSize: "18px",
        height: "50px",
        lineHeight: "normal",
        textAlign: "right",
    }
};

