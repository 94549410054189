import config from "../../config";

const ethereumConfig = {
    LOCAL: {
        rpcUrl:  "http://localhost:7545",
        etherscanUrl: "https://etherscan.io",
        chainId: "0x539",
        networkName: "Local",
    },
    ROPSTEN: {
        rpcUrl: "https://eth-ropsten.alchemyapi.io/v2/ktmgjOlXjghPki2srUIYNuf1ddFHCafN",
        etherscanUrl: "https://ropsten.etherscan.io",
        chainId: "0x3",
        networkName: "Ropsten",
        alchemyApiKey: "ktmgjOlXjghPki2srUIYNuf1ddFHCafN",
    },
    RINKEBY: {
        rpcUrl: "https://eth-rinkeby.alchemyapi.io/v2/cyiu10kvi46vYpih-MRH9jpjcbBvJzYn",
        etherscanUrl: "https://rinkeby.etherscan.io",
        chainId: "0x4",
        networkName: "Rinkeby",
        alchemyApiKey: "cyiu10kvi46vYpih-MRH9jpjcbBvJzYn",
    },
    MAINNET: {
        rpcUrl: "https://eth-mainnet.alchemyapi.io/v2/j_3-bAkIKdD0t1zwy-BXi2m5RGe2xUA_",
        etherscanUrl: "https://etherscan.io",
        chainId: "0x1",
        networkName: "Mainnet",
        alchemyApiKey: "j_3-bAkIKdD0t1zwy-BXi2m5RGe2xUA_",
    },
};

export default ethereumConfig[config.network];