import React from "react";

import Svg from "../ui/Svg";

export default function EthereumIconBlue({ width = 26, height = 26, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#627EEA"/>
            <path d="M13.4062 3.25V10.4569L19.4976 13.1787L13.4062 3.25Z" fill="white" opacity="0.602"/>
            <path d="M13.4046 3.25L7.3125 13.1787L13.4046 10.4569V3.25Z" fill="white"/>
            <path d="M13.4062 17.85V22.7469L19.5016 14.314L13.4062 17.85Z" fill="white" opacity="0.602"/>
            <path d="M13.4046 22.7469V17.8492L7.3125 14.314L13.4046 22.7469Z" fill="white"/>
            <path d="M13.4062 16.7154L19.4976 13.1786L13.4062 10.4584V16.7154Z" fill="white" opacity="0.2"/>
            <path d="M7.3125 13.1786L13.4046 16.7154V10.4584L7.3125 13.1786Z" fill="white" opacity="0.602"/>
        </Svg>
    );
}






