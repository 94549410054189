import React from "react";

import Svg from "../ui/Svg";

export default function DiscordIcon({ width = 17, height = 12, fill = "black", ...props }) {
    return (
        <Svg 
            width={width} 
            height={height} 
            fill={fill}
            {...props}
             viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2584 11.9856C12.2584 11.9856 11.7465 11.3662 11.3199 10.8331C13.1828 10.3001 13.8938 9.13328 13.8938 9.13328C13.3108 9.52223 12.7562 9.79594 12.2584 9.98321C11.5474 10.2857 10.8648 10.473 10.1964 10.6027C8.8312 10.862 7.57975 10.7899 6.51319 10.5883C5.70259 10.4298 5.00577 10.2137 4.42271 9.96881C4.09563 9.83916 3.7401 9.6807 3.38458 9.47902C3.34192 9.45021 3.29926 9.4358 3.25659 9.40699C3.22815 9.39258 3.21393 9.37818 3.19971 9.37818C2.94373 9.23412 2.80152 9.13328 2.80152 9.13328C2.80152 9.13328 3.48413 10.2713 5.29018 10.8187C4.86356 11.3662 4.33738 12 4.33738 12C1.19456 11.8992 0 9.82475 0 9.82475C0 5.22936 2.04781 1.49831 2.04781 1.49831C4.09563 -0.043095 6.02967 0.000121843 6.02967 0.000121843L6.17188 0.172989C3.61212 0.907676 2.446 2.04572 2.446 2.04572C2.446 2.04572 2.75886 1.87285 3.28503 1.64236C4.80667 0.965298 6.01545 0.792431 6.51319 0.734808C6.59851 0.720403 6.66961 0.705997 6.75494 0.705997C7.62242 0.590752 8.60366 0.561941 9.62757 0.677186C10.9786 0.835648 12.4291 1.239 13.9081 2.04572C13.9081 2.04572 12.7846 0.965298 10.3671 0.230612L10.5661 0.000121843C10.5661 0.000121843 12.5144 -0.043095 14.548 1.49831C14.548 1.49831 16.5958 5.22936 16.5958 9.82475C16.5958 9.81035 15.4013 11.8848 12.2584 11.9856ZM5.64571 5.31579C4.83512 5.31579 4.19517 6.02167 4.19517 6.90041C4.19517 7.77915 4.84934 8.48503 5.64571 8.48503C6.4563 8.48503 7.09624 7.77915 7.09624 6.90041C7.11046 6.02167 6.4563 5.31579 5.64571 5.31579ZM10.8363 5.31579C10.0258 5.31579 9.38581 6.02167 9.38581 6.90041C9.38581 7.77915 10.04 8.48503 10.8363 8.48503C11.6469 8.48503 12.2869 7.77915 12.2869 6.90041C12.2869 6.02167 11.6327 5.31579 10.8363 5.31579Z" fill="white"/>
        </Svg>
    );
}