import React from "react";
import {colors, fontWeights, radii, shadows} from "../../../../providers/theme/theme";
import Flex from "../../../ui/Flex";
import Input from "../../../ui/Input";
import {Span, BoldSpan, BodyText, MiniText, TitleText} from "../../../ui/Text";
import {formatCryptoAmount, formatDollarAmount} from "../../../../utils/numbers";
import ConnectWalletButton from "../../../ui/Button/ConnectWalletButton";
import {CONTEXT_NAMES, EVENTS} from "../../../../providers/analytics/events";
import {PrimaryButton} from "../../../ui/Button";
import EthereumIconBlue from "../../../svgs/EthereumIconBlue";
import FeiIconJustPyramid from "../../../svgs/FeiIconJustPyramid";
import TribeIconJustMonoliths from "../../../svgs/TribeIconJustMonoliths";
import { Fragment } from "react";
import FormErrorDisplay from "../../../ui/FormErrorDisplay";
import { hexToRGB } from "../../../../utils/ui";
import MaxButton from "../../../ui/Button/MaxButton";

export default function GenesisDepositView({
    ethAmountInput,
    setEthAmountInput,
    ethAmountUsdValue,
    ethBalance,
    onClickMax,
    formError,
    broadcastingTx,
    confirmingTx,
    connectedAccount,
    onClickGenesisPurchase,
    userHasDeposits,
    buttonDisabled,
    formContainerProps,
}) {

    const inputErrorStyles = formError ? {
        bg: hexToRGB(colors.red.dark, "0.3"),
    } : {};

    return (
        <Fragment>
            <Flex 
                {...styles.Container}
                {...formContainerProps}
            >
                <Flex {...styles.BalanceRow}>
                    <BodyText mono dark secondary>
                       <Span opacity={"0.7"} fontWeight={fontWeights.bold}>BALANCE:</Span> {formatCryptoAmount(ethBalance)} <BoldSpan>ETH</BoldSpan>
                    </BodyText>
                    <MaxButton onClick={onClickMax}/>
                </Flex>
                <Flex {...styles.EthAmountInputContainer}>
                    <EthereumIconBlue {...styles.InputIcon}/>
                    <Input
                        mono
                        type={"number"}
                        value={ethAmountInput}
                        onChange={setEthAmountInput}
                        {...styles.EthAmountInput}
                        {...inputErrorStyles}
                    />
                    <TitleText
                        dark mono
                        text={"ETH"}
                        {...styles.EthAmountInputLabel}
                    />
                </Flex>
                <Flex {...styles.BalanceRow}>
                    <BodyText 
                        mono 
                        secondary 
                        dark
                        bold
                        text={"DOLLAR VALUE:"}
                        opacity={"0.7"}
                    />
                    <BodyText 
                        dark mono
                        text={`$${formatDollarAmount(ethAmountUsdValue)}`}
                    />

                </Flex>
                {
                    connectedAccount ? (
                    <PrimaryButton
                        {...styles.PrimaryButton}
                        onClick={onClickGenesisPurchase}
                        disabled={buttonDisabled}
                        broadcastingTx={broadcastingTx}
                        confirmingTx={confirmingTx}
                        tracking={{
                            contextName: CONTEXT_NAMES.GENESIS,
                            eventName: EVENTS.GENESIS.PURCHASE
                        }}
                    >
                        <Flex {...styles.PrimaryButtonContent}>
                            <FeiIconJustPyramid
                                fill={"white"}
                                marginTop={"3px"}
                            />
                            <TitleText
                                text={userHasDeposits ? "Commit More" : "Commit to Genesis"}
                                {...styles.PrimaryButtonText}
                            />
                            <TribeIconJustMonoliths 
                                fill={"white"}
                            />
                        </Flex>
                    </PrimaryButton>
                    ) : (
                        <ConnectWalletButton contextName={CONTEXT_NAMES.GENESIS}/>
                    )
                }
            </Flex>
            <FormErrorDisplay 
                light
                errorMessage={formError}
            />
        </Fragment>
        
    );
}

const styles = {
    Container: {
        backgroundColor: colors.grey.lightest,
        boxShadow: shadows.primary,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    EthAmountInputContainer: {
        width: "100%",
        position: "relative",
        marginTop: "10px",
    },
    EthAmountInputLabel: {
        fontWeight: "bold",
        fontSize: 20,
        position: "absolute",
        height: 20,
        right: 20,
        margin: "auto",
        top: 0,
        bottom: 10,
    },
    InputIcon: {
        width: "30px",
        height: "30px",
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 10,
        left: 20,
    },
    EthAmountInput: {
        width: "100%",
        boxShadow: shadows.primary,
        borderRadius: radii.small,
        marginBottom: 10,
        paddingLeft: 25,
        paddingRight: 70,
    },
    PrimaryButton: {
        width: "100%",
        backgroundColor: colors.green,
        hoverBg: colors.green,
        marginTop: 15,
    },
    PrimaryButtonContent: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    PrimaryButtonText: {
        marginLeft: "10px",
        marginRight: "10px",
    },
    BalanceRow: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
};