import React from "react";
import { colors } from "../../providers/theme/theme";
import Flex from "../ui/Flex";
import Loader from "../ui/Loader";

export default function LoadingPage() {
    return (
        <Flex {...styles.Container}>
            <Loader/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.grey.dark
    }
}
