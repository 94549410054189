import development from "./development";
import staging from "./staging";
import production from "./production";
import shared from "./shared";

const configByEnvironment = {
    development,
    staging,
    production,
};

export const environment = process.env.REACT_APP_ENV || "development";

const config = configByEnvironment[environment];

const mergedConfig = {
    ...shared,
    ...config,
};

export default mergedConfig;
