import React from "react";

import Svg from "../ui/Svg";

export default function PlusIcon({ width = 20, height = 20, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 23 23" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.5 23C9.22552 23 7.00211 22.3255 5.11095 21.0619C3.21978 19.7983 1.7458 18.0022 0.87539 15.9009C0.00498279 13.7995 -0.222756 11.4872 0.220974 9.25646C0.664704 7.02568 1.75997 4.97658 3.36828 3.36828C4.97658 1.75997 7.02568 0.664704 9.25646 0.220974C11.4872 -0.222756 13.7995 0.00498279 15.9009 0.87539C18.0022 1.7458 19.7983 3.21978 21.0619 5.11095C22.3255 7.00211 23 9.22552 23 11.5C23 14.55 21.7884 17.4751 19.6317 19.6317C17.4751 21.7884 14.55 23 11.5 23ZM12.4583 17.7292C12.4583 17.9833 12.3574 18.2271 12.1776 18.4068C11.9979 18.5865 11.7542 18.6875 11.5 18.6875C11.2458 18.6875 11.0021 18.5865 10.8224 18.4068C10.6426 18.2271 10.5417 17.9833 10.5417 17.7292V12.4583H5.27084C5.01667 12.4583 4.77292 12.3574 4.59319 12.1776C4.41347 11.9979 4.3125 11.7542 4.3125 11.5C4.3125 11.2458 4.41347 11.0021 4.59319 10.8224C4.77292 10.6426 5.01667 10.5417 5.27084 10.5417H10.5417V5.27084C10.5417 5.01667 10.6426 4.77292 10.8224 4.59319C11.0021 4.41347 11.2458 4.3125 11.5 4.3125C11.7542 4.3125 11.9979 4.41347 12.1776 4.59319C12.3574 4.77292 12.4583 5.01667 12.4583 5.27084V10.5417H17.7292C17.9833 10.5417 18.2271 10.6426 18.4068 10.8224C18.5865 11.0021 18.6875 11.2458 18.6875 11.5C18.6875 11.7542 18.5865 11.9979 18.4068 12.1776C18.2271 12.3574 17.9833 12.4583 17.7292 12.4583H12.4583V17.7292Z"
                  fill="white"/>
        </Svg>
    );
}