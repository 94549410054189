import config from "../../../config";
import GenesisGroupAbi from "./abis/GenesisGroup.json";
import UniswapIncentiveAbi from "./abis/UniswapIncentive.json";
import BondingCurveOracleAbi from "./abis/BondingCurveOracle.json";
import FeiRouterAbi from "./abis/FeiRouter.json";
import FeiAbi from "./abis/Fei.json";
import TribeAbi from "./abis/Tribe.json";
import FeiStakingRewardsAbi from "./abis/FeiStakingRewards.json";
import IdoAbi from "./abis/IDO.json";
import IUniswapV2RouterO2Abi from "./abis/IUniswapV2Router02.json";
import IUniswapV2PairAbi from "./abis/IUniswapV2Pair.json";
import EthUniswapPCVDepositAbi from "./abis/EthUniswapPCVDeposit.json";
import EthReserveStabilizerFipTwoAbi from "./abis/EthReserveStabilizerFipTwo.json";
import EthReserveStabilizerFipTwentyAbi from "./abis/EthReserveStabilizerFipTwenty.json"
import EthPCVDripperAbi from "./abis/EthPCVDripper.json";
import EthBondingCurveAbi from "./abis/EthBondingCurve.json";
import EthUniswapPCVControllerAbi from "./abis/EthUniswapPCVController.json";
import ChainlinkAggregatorV3InterfaceAbi from "./abis/AggregatorV3Interface.json";
import TimelockAbi from "./abis/Timelock.json";
import CErc20DelegatorAbi from "./abis/CErc20Delegator.json";
import EthLidoPCVDepositAbi from "./abis/EthLidoPCVDeposit.json";
import TribalChiefAbi from "./abis/TribalChief.json";
import ERC20Abi from "./abis/ERC20.json";
import ATokenAbi from "./abis/AToken.json";
import CEtherAbi from "./abis/CEther.json";
import IStableSwap3Abi from "./abis/IStableSwap3.json";
import GelatoUniswapDaiFeiLpAbi from "./abis/GelatoUniswapDaiFeiLpAbi.json";
import { useEffect, useState } from "react";
import AaveIncentivesControllerAbi from "./abis/AaveIncentivesController.json";
import FeiRariTribeAbi from "./abis/FeiRariTribe.json";
import RariFTribeRewardsDistributorDelegatorAbi from "./abis/RariFTribeRewardsDistributorDelegator.json"

export const contractsConfig = [
    {
        name: "GenesisGroup",
        abi: GenesisGroupAbi,
        addresses: {
            LOCAL: "0xff6EEcBd3091C6D0BF2E71D7c9741D99c9d88C09",
            ROPSTEN: "0xac3666d2A4c6D0055eC6B0ea3A112Ca7bb111316",
            RINKEBY: "0xe3AEd33E28A2D0C40986900A89f78CBC6A9bc54A",
            MAINNET: "0xBFfB152b9392e38CdDc275D818a3Db7FE364596b"
        },
    },
    {
        name: "UniswapIncentive",
        abi: UniswapIncentiveAbi,
        addresses: {
            LOCAL: "0x4779251FaC84E5F5969564A0c5cFE24Cb83A8Aef",
            ROPSTEN: "0x0a740658a2FD14c2c18318942a29d0d1DC419e98",
            RINKEBY: "0x28A87fB62065c990f673D143163bDC3cfeE994D6",
            MAINNET: "0xfe5b6c2a87A976dCe20130c423C679f4d6044cD7",
        },
    },
    {
        name: "EthBondingCurve",
        abi: EthBondingCurveAbi,
        addresses: {
            MAINNET: "0xB783c0E21763bEf9F2d04E6499abFbe23AdB7e1F"
        },
    },
    {
        name: "BondingCurveOracle",
        abi: BondingCurveOracleAbi,
        addresses: {
            LOCAL: "0x55f0293CBB912Aa668461d4a9f519797285B1b3E",
            ROPSTEN: "0x01D9c16da07f3e09DECf70327E109EaeFa32b254",
            RINKEBY: "0xfbEC54baC2D8C7d47896ba74F1dE3A2fdAAbeaEF",
            MAINNET: "0x89714d3AC9149426219a3568543200D1964101C4",
        },
    },
    {
        name: "FeiRouter",
        abi: FeiRouterAbi,
        addresses: {
            LOCAL: "0xb172F6a76b502506456E3EF0632C20E967F455fA",
            ROPSTEN: "0x72d2c89A6495Ab904602FCDa4BA4517ce8af9AeA",
            RINKEBY: "0x6f6D84d9119E77Df74773350a1F41079CA20753c",
            MAINNET: "0x9271D303b57c204636C38Df0eD339b18Bf98f909",
        },
    },
    {
        name: "Fei",
        abi: FeiAbi,
        addresses: {
            LOCAL: "0xc153b8E404C3B3bD02621c285F2112B453bf6F0f",
            ROPSTEN: "0x434D86cd4bD95036179936A8aeDB4B3152Aeae8b",
            RINKEBY: "0x40C1c9251b5AA4fD3be0D442bBC2467e9b21Ab2b",
            MAINNET: "0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
        },

    },
    {
        name: "Tribe",
        abi: TribeAbi,
        addresses: {
            LOCAL: "0xa3462D12DDd348F755cCaA53aa2003c88842281a",
            ROPSTEN: "0xB92348B73473211D68AA716c9Df6F9dd13149616",
            RINKEBY: "0x7506b01145e59078F0B2e0F4Bc3fb880C54d03De",
            MAINNET: "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B",
        },
    },
    {
        name: "FeiStakingRewards",
        abi: FeiStakingRewardsAbi,
        addresses: {
            LOCAL: "0xefeDD6430b8c2aE431383274643B3ed1DAAc3fe4",
            ROPSTEN: "0xaA246591d7a11Eba40692136647fA004b06F816D",
            RINKEBY: "0x53A17b946ca77362790EDa8A1106c3C49eB31A5B",
            MAINNET: "0x18305DaAe09Ea2F4D51fAa33318be5978D251aBd",
        }
    },
    {
        // TribalChief Pool 0
        name: "FeiTribeUniswapV2Pair",
        abi: IUniswapV2PairAbi,
        addresses: {
            LOCAL: "0xf5e4b85CE59200D7ddc29F0eDC515BD7723C9b8d",
            ROPSTEN: "0x3E8c27e8261213788049187C5Ed54492309d6D0a",
            RINKEBY: "0x67f9537ba4429Ca6d41CE8EB6fF39eFCE4b9F581",
            MAINNET: "0x9928e4046d7c6513326cCeA028cD3e7a91c7590A",
        },
    },
    {
        name: "FeiEthUniswapV2Pair",
        abi: IUniswapV2PairAbi,
        addresses: {
            RINKEBY: "0x88C7Ca09796422938D8D680BB374607405553c32",
            MAINNET: "0x94B0A3d511b6EcDb17eBF877278Ab030acb0A878",
        },
    },
    {
        name: "IDO",
        abi: IdoAbi,
        addresses: {
            LOCAL: "0x65eEd1c0deb576a4B14D90a559C2400E99f17Cd5",
            ROPSTEN: "",
            RINKEBY: "0x904F129Ee714de55b32D86373E53B2aeAaeED291",
            MAINNET: "0x7D809969f6A04777F0A87FF94B57E56078E5fE0F",
        },
    },
    {
        name: "EthUniswapPCVDeposit",
        abi: EthUniswapPCVDepositAbi,
        addresses: {
            RINKEBY: "0xA1D0aD2D328D84C203cd5089cAD6D104f0077744",
            MAINNET: "0x15958381E9E6dc98bD49655e36f524D2203a28bD",
        },
    },
    {
        name: "EthReserveStabilizerFipTwo",
        abi: EthReserveStabilizerFipTwoAbi,
        addresses: {
            RINKEBY: "0xa970B31545Daf5e0D99f0c7561488C096847e131",
            MAINNET: "0xa08A721dFB595753FFf335636674D76C455B275C",
        },
    },
    {
        name: "EthReserveStabilizerFipTwenty",
        abi: EthReserveStabilizerFipTwentyAbi,
        addresses: {
            MAINNET: "0x17305f0e18318994a57b494078CAC866A857F7b6",
        },
    },
    {
        name: "EthPCVDripper",
        abi: EthPCVDripperAbi,
        addresses: {
            RINKEBY: "0xB152DF12AEC3CB23E10864755b21266B8195bC6d",
            MAINNET: "0xDa079A280FC3e33Eb11A78708B369D5Ca2da54fE",
        },
    },
    {
        name: "EthUniswapPCVController",
        abi: EthUniswapPCVControllerAbi,
        addresses: {
            MAINNET: "0x0760dfe09bd6d04d0df9a60c51f01ecedceb5132"
        }
    },
    // The contracts below are external. We use the mainnet address for localhost since we are forking from mainnet
    {
        name: "UniswapV2Router02",
        abi: IUniswapV2RouterO2Abi,
        addresses: {
            LOCAL: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            ROPSTEN: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            RINKEBY: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
            MAINNET: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        },
    },
    {
        name: "UsdcEthUniswapV2Pair",
        abi: IUniswapV2PairAbi,
        addresses: {
            LOCAL: "0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc",
            RINKEBY: "0x78AB2e85EAf22Dc7B6981E54432e17521BdadC23",
            ROPSTEN: "0x681A4164703351d6AceBA9D7038b573b444d3353",
            MAINNET: "0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc",
        },
    },
    {
        name: "TribalChief",
        abi: TribalChiefAbi,
        addresses: {
            MAINNET: "0x9e1076cC0d19F9B0b8019F384B0a29E48Ee46f7f",
            RINKEBY: "0x3E199C4D2519D91b035F681718027B34a8B478F5"
        }
    },
    {
        name: "ChainlinkEthUsdAggregator",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
        },
    },
    {
        name: "Timelock",
        abi: TimelockAbi,
        addresses: {
            MAINNET: "0x639572471f2f318464dc01066a56867130e45E25"
        }
    },
    {
        name: "RariFusePoolEightFei",
        abi: CErc20DelegatorAbi,
        addresses: {
            MAINNET: "0xd8553552f8868C1Ef160eEdf031cF0BCf9686945"
        }
    },
    {
        name: "RariFusePoolNineRai",
        abi: CErc20DelegatorAbi,
        addresses: {
            MAINNET: "0x752F119bD4Ee2342CE35E2351648d21962c7CAfE"
        }
    },
    {
        name: "RariFusePoolNineteenDpi",
        abi: CErc20DelegatorAbi,
        addresses: {
            MAINNET: "0xF06f65a6b7D2c401FcB8B3273d036D21Fe2a5963",
        },
    },
    {
        name: "EthLidoPCVDeposit",
        abi: EthLidoPCVDepositAbi,
        addresses: {
            MAINNET: "0xac38ee05c0204a1e119c625d0a560d6731478880",
        },
    },
    {
        name: "DpiUsdChainlinkOracle",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0xd2a593bf7594ace1fad597adb697b5645d5eddb2",
        }
    },
    {
        name: "FeiUsdChainlinkOracle",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0x31e0a88fecB6eC0a411DBe0e9E76391498296EE9"
        },
    },
    {
        name: "RaiEthChainlinkOracle",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0x4ad7B025127e89263242aB68F0f9c4E5C033B489",
        }
    },
    {
        name: "DaiUsdChainlinkOracle",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9",
        }
    },
    {
        name: "TribeEthChainlinkOracle",
        abi: ChainlinkAggregatorV3InterfaceAbi,
        addresses: {
            MAINNET: "0x84a24deCA415Acc0c395872a9e6a63E27D6225c8",
        },
    },
    {
        name: "DpiSushiPcvDeposit",
        abi: EthUniswapPCVDepositAbi,
        addresses: {
            MAINNET: "0x902199755219A9f8209862d09F1891cfb34F59a3",
        },
    },
    {
        name: "DpiFusePcvDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x3dD3d945C4253bAc5B4Cc326a001B7d3f9C4DD66"
        }
    },
    // {
    //     // TribalChief Pool 3
    //     name: "FuseTribeOnTribeStakingWrapperToken",
    //     abi: ERC20Abi, 
    //     addresses: {
    //         MAINNET: "0x2B0d36FACD61B71CC05ab8F3D2355ec3631C0dd5"
    //     },
    // },
    {
        // TribalChief Pool 1
        name: "Curve3MetapoolToken",
        abi: IStableSwap3Abi,
        addresses: {
            MAINNET: "0x06cb22615ba53e60d67bf6c341a0fd5e718e1655"
        },
    },
    {
        name: "AaveEthPCVDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x5B86887e171bAE0C2C826e87E34Df8D558C079B9"
        }
    },
    {
        name: "AaveRaiPCVDeposit",
        abi: [],
        addresses: {
            MAINNET: "0xd2174d78637a40448112aa6B30F9B19e6CF9d1F9"
        }
    },
    {
        name: "aWETH",
        abi: ATokenAbi,
        addresses: {
            MAINNET: "0x030bA81f1c18d280636F32af80b9AAd02Cf0854e"
        }
    },
    {
        name: "aRAI",
        abi: ATokenAbi,
        addresses: {
            MAINNET: "0xc9BC48c72154ef3e5425641a3c747242112a46AF"
        }
    },
    {
        name: "EthCompoundPCVDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x4fCB1435fD42CE7ce7Af3cB2e98289F79d2962b3"
        }
    },
    {
        name: "DaiCompoundPCVDeposit",
        abi: [],
        addresses: {
            MAINNET: "0xe0f73b8d76D2Ad33492F995af218b03564b8Ce20"
        },
    },
    {
        name: "CEther",
        abi: CEtherAbi,
        addresses: {
            MAINNET: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5",
        }
    },
    {
        name: "CDai",
        abi: CErc20DelegatorAbi,
        addresses: {
            MAINNET: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643"
        }
    },
    {
        name: "RaiFusePcvDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x9aAdFfe00eAe6d8e59bB4F7787C6b99388A6960D"
        }
    },
    {
        name: "IndexSnapshotDelegatorPCVDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x0ee81df08B20e4f9E0F534e50da437D24491c4ee",
        }
    },
    {
        name: "Index",
        abi: FeiAbi, // TO DO: create an ERC20 ABI
        addresses: {
            MAINNET: "0x0954906da0Bf32d5479e25f46056d22f08464cab"
        }
    },
    {
        name: "IndexEthSushiswapV2Pair",
        abi: IUniswapV2PairAbi,
        addresses: {
            MAINNET: "0xA73DF646512C82550C2b3C0324c4EEdEE53b400C",
        }
    },
    {
        name: "DpiFeiSushiswapV2Pair",
        abi: IUniswapV2PairAbi,
        addresses: {
            MAINNET: "0x8775aE5e83BC5D926b6277579c2B0d40c7D9b528",
        }
    },
    {
        name: "GelatoUniswapDaiFeiLp",
        abi: GelatoUniswapDaiFeiLpAbi,
        addresses: {
            MAINNET: "0x3D1556e84783672f2a3bd187a592520291442539"
        }
    },
    {
        name: "AaveTribeIncentivesController",
        abi: AaveIncentivesControllerAbi,
        addresses: {
            MAINNET: "0xDee5c1662bBfF8f80f7c572D8091BF251b3B0dAB"
        }
    },
    {
        name: "AFeiVariableDebt",
        abi: [],
        addresses: {
            MAINNET: "0xC2e10006AccAb7B45D9184FcF5b7EC7763f5BaAe"
        }
    },
    {
        name: "OAMultisig",
        abi: [],
        addresses: {
            MAINNET: "0xbc9c084a12678ef5b516561df902fdc426d95483",
        },
    },
    {
        name: "FeiRariTribe",
        abi: FeiRariTribeAbi,
        addresses: {
            MAINNET: "0xFd3300A9a74b3250F1b2AbC12B47611171910b07",
        }
    },
    {
        name: "RariRewardsDistributor",
        abi: [],
        addresses: {
            MAINNET: "0xc76190E04012f26A364228Cfc41690429C44165d",
        }
    },
    {
        name: "RariFTribeRewardsDistributorDelegator",
        abi: RariFTribeRewardsDistributorDelegatorAbi,
        addresses: {
            MAINNET: "0x73F16f0c0Cd1A078A54894974C5C054D8dC1A3d7",
        }
    },
    {
        name: "BalancerVault",
        abi: [],
        addresses: {
            MAINNET: "0xba12222222228d8ba445958a75a0704d566bf2c8"
        }
    },
    {
        name: "Lusd",
        abi: [],
        addresses: {
            MAINNET: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0"
        }
    },
    {
        name: "LusdUsdChainlinkOracle",
        abi: [],
        addresses: {
            MAINNET: "0x3D7aE7E594f2f2091Ad8798313450130d0Aba3a0",
        },
    },
    {
        name: "TokemakWethPcvDeposit",
        abi: [],
        addresses: {
            MAINNET: "0x0961d2a545e0c1201b313d14c57023682a546b9d",
        }
    },
    {
        name: "TokemakWeth",
        abi: [],
        addresses: {
            MAINNET: "0xd3d13a578a53685b4ac36a1bab31912d2b2a2f36"
        }
    }
].map(({name, abi, addresses}) => {
    return {
        name,
        abi,
        address: addresses[config.network],
    };
}).filter(({address}) => {
    return !!address;
});

export function useContracts({
    connectedAccount,
    connectedChainId,
    walletProvider,
    alchemyProvider,
}) {

    const [contracts, setContracts] = useState(null);
    // We are going to be submitting transactions with this contracts
    // instance so lets use the wallet provider if available
    const web3Provider = walletProvider || alchemyProvider;

    useEffect(() => {
        updateContracts();
    }, [
        connectedChainId,
        connectedAccount,
        web3Provider,
        walletProvider
    ]);

    const updateContracts = async () => {
        if (!web3Provider) {
            return;
        }
        const newContracts = {};
        for (let i = 0; i < contractsConfig.length; i++) {
            let {
                name,
                abi,
                address,
            } = contractsConfig[i];
            const contractOptions = {
                from: connectedAccount,
            };
            newContracts[name] = await new web3Provider.eth.Contract(abi, address, contractOptions);
        }
        setContracts(newContracts);
    }
    
    return {
        contracts,
    };
}