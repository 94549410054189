import React from "react";
import GreyLinesImageSource1x from "../../../assets/images/grey_lines_1x.png";
import GreyLinesImageSource2x from "../../../assets/images/grey_lines_1.5x.png";
import Image from "../Image"
import Flex from "../Flex";

export default function WavesImageBackground({
    customContainerStyle,
}) {
    const containerStyle = {
        ...styles.GreyLinesImageContainer,
        ...customContainerStyle,  
    };

    return (
        <Flex {...containerStyle}>
            <Image
                src={GreyLinesImageSource1x}
                src2x={GreyLinesImageSource2x}
                {...styles.GreyLinesImage}
            />
        </Flex>
    );
}

const styles = {
    GreyLinesImageContainer: {
        overflow: "hidden",
        position: "absolute",
        top: 200,
        width: "100vw",
        zIndex: 1,
    },
    GreyLinesImage: {
        width: ["200vw","100vw"],
        marginTop: ["35px", "50px"]
    },
};