import React, { useEffect } from "react";
import { colors } from "../../providers/theme/theme";
import Flex from "../ui/Flex";
import { HeaderText } from "../ui/Text";
import { useHistory, useLocation} from "react-router-dom";


export default function NotFound() {
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            // Send the user to the homepage after a few seconds
            history.push("/");
        }, 5000);
    }, [])
    return (
        <Flex {...styles.Container}>
            <HeaderText
                mono
                text={"Page Not Found"}
            />
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.grey.dark,
    },
};