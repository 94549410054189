import { useEffect, useState } from "react";
import {useEthereum} from "../../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";

export function useGenesisDepositLogic({
    setDisplayPreswapTooltip,
    formContainerProps,
}) {
    // Setup state
    const [inputField, setInputField] = useState({
        ethAmountInput:"",
        maxAmountSelected: false,
    });
    const {
        ethAmountInput,
        maxAmountSelected,
    } = inputField;
    const setEthAmountInput = (newValue) => {
        setInputField({
            maxAmountSelected: false,
            ethAmountInput: newValue,
        });
    };

    const [formError, setFormError] = useState("");
    const [broadcastingTx, setBroadcastingTx] = useState(false);
    const [confirmingTx, setConfirmingTx] = useState(false)

    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();
    
    const currentEthPrice = get(protocolState, "UNI_USDC_ETH.ETH_PRICE");
    const ethBalance = get(protocolState, "BALANCES.ETH", "0");
    const userFgen = get(protocolState, "BALANCES.FGEN", "0");
    const userFgenCommitted = get(protocolState, "BALANCES.FGEN_COMMITTED", "0");

    const userFgenBN = new BigNumber(userFgen);
    const userFgenCommittedBN = new BigNumber(userFgenCommitted);
    const ethAmountInputBN = ethAmountInput && new BigNumber(ethAmountInput);
    const currentEthPriceBN = currentEthPrice && new BigNumber(currentEthPrice);
    const ethAmountUsdValue = ethAmountInput && currentEthPrice && ethAmountInputBN.times(currentEthPriceBN).toString();
    const ethBalanceBN = ethBalance && new BigNumber(ethBalance);

    const userHasDeposits = userFgenBN.gt(0) || userFgenCommittedBN.gt(0);
    const buttonDisabled = !ethBalance || ethBalanceBN.lte(0);

    useEffect(() => {
        if (formError) {
            setFormError("");
        }
    }, [ethAmountInput]);

    const onClickGenesisPurchase = async () => {
        if (!ethAmountInput) {
            setFormError("Enter a deposit amount");
            return;
        }

        if (ethAmountInputBN.lte(0)) {
            setFormError("Please enter a valid amount");
            return;
        }

        if (ethAmountInputBN.gt(ethBalanceBN)) {
            setFormError("Insufficient balance")
            return;
        }

        setBroadcastingTx(true);
        const maxAmountBN = await getMaxAmountBN();
        const ethAmountToSubmit = maxAmountSelected ?
            maxAmountBN.toString() :
            ethAmountInput;

        await transactions.GenesisPurchase({
            ethAmount: ethAmountToSubmit
        }, {
            onBroadcast: () => {
                setBroadcastingTx(false);
                setConfirmingTx(true);
                
            },
            onConfirm: () => {
                setConfirmingTx(false);
                setDisplayPreswapTooltip(true);
                setEthAmountInput("");
            },
            onFailure: () => {
                setBroadcastingTx(false);
                setConfirmingTx(false);
                setEthAmountInput("");
            },
        });
    };

    const getMaxAmountBN = async () => {
        // TODO: subtract gas estimate
        return ethBalanceBN;
    };

    const onClickMax = async () => {
        const maxAmountBN = await getMaxAmountBN();
        const formattedMaxAmount = maxAmountBN.dp(6, BigNumber.ROUND_DOWN).toString();
        setInputField({
            ethAmountInput: formattedMaxAmount,
            maxAmountSelected: true,
        });
    };

    return {
        ethAmountInput,
        setEthAmountInput,
        ethAmountUsdValue,
        ethBalance,
        onClickMax,
        formError,
        broadcastingTx,
        confirmingTx,
        connectedAccount,
        onClickGenesisPurchase,
        userHasDeposits,
        buttonDisabled,
        formContainerProps,
    };
}