import React, {useEffect} from "react";
import Flex from "../../ui/Flex";
import StakingForm from "./StakingForm";
import config from "../../../config";
import {colors, fontWeights} from "../../../providers/theme/theme";
import FeiHeader from "../../ui/FeiHeader";
import WavesImageBackground from "../../ui/backgrounds/WavesImageBackground";
import {BodyText, HeaderText, LinkSpan} from "../../ui/Text";
import {track} from "../../../providers/analytics";
import {EVENT_TYPES, CONTEXT_NAMES} from "../../../providers/analytics/events";
import LoadingPage from "../Loading";
import FeiFooter from "../../ui/FeiFooter";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../ui/Button";


export default function StakeView({
    JSON_MODE,
    loading,
    onClickReadMore,
    ...props
}) {

    const history = useHistory();

    useEffect(() => {
        track({
            eventType: EVENT_TYPES.VIEWED_SCREEN,
            contextName: CONTEXT_NAMES.STAKE,
            eventName: CONTEXT_NAMES.STAKE,
        });
    }, []);

    if (loading) {
        return (<LoadingPage/>);
    }

    return (
        <Flex {...styles.Container}>
            <FeiHeader contextName={CONTEXT_NAMES.STAKE}/>
            <WavesImageBackground customContainerStyle={{
                top: ["300px", "200px"]
            }}/>
            <Flex {...styles.HeaderContainer}>
                <HeaderText
                    text={"Unstake FEI-TRIBE LP tokens"}
                    {...styles.PageHeaderText}
                />
                <BodyText
                    bold
                    {...styles.PageSubtitleText}
                >
                    This staking page has been deprecated. Please claim and withdraw your TRIBE-FEI LP tokens below. Check out the user guide to migrating your tokens to v2 staking <LinkSpan onClick={() => {window.open("https://docs.fei.money/migrating-to-v2-staking")}}>here</LinkSpan>.
                </BodyText>
            </Flex>
            <StakingForm {...props}/>
            <PrimaryButton
                {...styles.ToggleStakingPageButton}
                text={"New Staking Page"}
                onClick={() => {history.push("/stake")}}
            />
            {/* <HowToInfoBlock/> */}
            {
                JSON_MODE && config.jsonMode && (
                    <BodyText 
                        mono 
                        bold 
                        width={"100%"}
                        marginTop={"500px"}
                        text={JSON.stringify(JSON_MODE, null, 2)}
                    />
                )
            }
            <FeiFooter contextName={CONTEXT_NAMES.STAKE}/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: colors.grey.dark,
        overflow: "hidden",
        paddingBottom: "40px",
    },
    HeaderContainer: {
        maxWidth: ["300px", "100vw"],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        zIndex: 1,
    },
    PageHeaderText: {
        marginTop: 50,
        fontWeight: fontWeights.regular,
        textAlign: "center",
    },
    PageSubtitleText: {
        marginTop: 10,
        textAlign: "center",
        maxWidth: ["100%", "650px"],
        color: colors.yellow.notice,
        lineHeight: 1.6
    },
    StakingWarningText: {
        marginTop: "20px",
    },
    YellowWarningText: {
        marginTop: "30px",
        color: colors.yellow.notice,
        textAlign: "center",
        maxWidth: ["290px", "100%"]
    },
    ToggleStakingPageButton: {
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
        marginTop: "70px",
    }
};