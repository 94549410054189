import React from "react";

import Svg from "../ui/Svg";

export default function FeiIconJustPyramid({ width = 30, height = 30, fill = "white", ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 46.2568H14.4872C12.1153 46.2568 10.6815 43.6343 11.9618 41.6376L21.9255 26.0978C22.661 24.9506 23.93 24.2568 25.2928 24.2568H38.7072C40.07 24.2568 41.339 24.9506 42.0745 26.0978L52.0382 41.6376C53.3185 43.6343 51.8847 46.2568 49.5128 46.2568H32Z" fill={fill}/>
            <path d="M33.7897 10.5105L37.9744 16.9048C38.8449 18.235 37.8906 20 36.3009 20H27.7751C26.1701 20 25.2189 18.2046 26.1204 16.8766L30.4615 10.4823C31.2632 9.30148 33.0082 9.31626 33.7897 10.5105Z" fill={fill}/>
        </Svg>
    );
}