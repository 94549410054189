import { useEthereum } from "../../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";
import { useState } from "react";


export function useComponentNameLogic({...props}) {
    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();
    
    const [formOpen, setFormOpen] = useState(false);
    const [claimBroadcasting, setClaimBroadcasting] = useState(false);
    const [claimConfirming, setClaimConfirming] = useState(false);

    const rewardsPerSecond = get(protocolState, "TRIBAL_CHIEF.AAVE.REWARDS_PER_SECOND", "0");
    const totalAFeiSupply = get(protocolState, "TRIBAL_CHIEF.AAVE.TOTAL_A_FEI_SUPPLY", "0");
    const feiPrice = get(protocolState, "CHAINLINK.FEI_USD_PRICE", "0");
    const tribePrice = get(protocolState, "USD_PRICES.TRIBE", "0");
    const userAFeiBalance = get(protocolState, "TRIBAL_CHIEF.AAVE.USER_A_FEI_BALANCE", "0");
    const userUnclaimedTribe = get(protocolState, "TRIBAL_CHIEF.AAVE.USER_UNCLAIMED_REWARDS", "0");

    async function onClickClaimButton() {
        setClaimBroadcasting(true);
        await transactions.ClaimAaveAFeiTribeRewards({
            amount: userUnclaimedTribe,
        }, {
            onBroadcast: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(true);
            },
            onConfirm: () => {
                setClaimConfirming(false);     
            },
            onFailure: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(false);
            },
        });
    }

    const onClickSummary = () => {
        setFormOpen(!formOpen);
    }



    const feiPriceBN = new BigNumber(feiPrice);
    const rewardsPerSecondBN = new BigNumber(rewardsPerSecond);
    const totalAFeiSupplyBN = new BigNumber(totalAFeiSupply);
    const tribePriceBN = new BigNumber(tribePrice);

    const rewardsPerYearBN = rewardsPerSecondBN.times(3600).times(24).times(365);
    const rewardsPerYearInUsdBN = rewardsPerYearBN.times(tribePriceBN);


    const tvlBN = totalAFeiSupplyBN.times(feiPriceBN);
    const tvl = tvlBN.toString();


    const apr = tvlBN.gt(0) ?  rewardsPerYearInUsdBN.div(tvlBN).times(100).decimalPlaces(1).toString() : "0";

    return {
        tvl,
        apr,
        formOpen,
        userAFeiBalance,
        userUnclaimedTribe,
        onClickSummary,
        hasConnectedAccount: !!connectedAccount,
        claimBroadcasting,
        claimConfirming,
        claimButtonDisabled: userUnclaimedTribe === "0",
        onClickClaimButton,
        ...props,
    };
}