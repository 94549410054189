import React from "react";

import Svg from "../ui/Svg";

export default function TribeIcon({ width = 18, height = 18, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="32" fill="#178DD0"/>
            <path d="M14.9254 33.2478C15.0906 31.7745 15.1732 31.0379 15.8171 30.5212C16.461 30.0046 17.196 30.0834 18.6661 30.2408C19.3296 30.3119 20.0997 30.404 20.9815 30.5233C21.7561 30.628 22.4618 30.7901 23.0951 30.9824C24.3869 31.3745 25.0327 31.5706 26.0164 32.8972C27 34.2238 27 35.2905 27 37.4239C27 38.7372 27 40.4038 27 42.5C27 45.4451 27 47.5421 27 49.003C27 50.4152 27 51.1213 26.5607 51.5607C26.1213 52 25.4145 52 24.0008 52C22.9173 52 21.6721 52 20.5 52C20.4189 52 20.3384 52 20.2586 52C17.9346 51.999 16.7726 51.9985 15.9129 51.2105C15.0533 50.4225 14.9578 49.3333 14.767 47.1549C14.6174 45.4471 14.5 43.3565 14.5 41C14.5 37.8523 14.7094 35.1736 14.9254 33.2478Z" fill="white"/>
            <path d="M48.5746 33.2478C48.4094 31.7745 48.3268 31.0379 47.6829 30.5212C47.039 30.0046 46.304 30.0834 44.8339 30.2408C44.1704 30.3119 43.4003 30.404 42.5185 30.5233C41.7439 30.628 41.0382 30.7901 40.4049 30.9824C39.1131 31.3745 38.4673 31.5706 37.4836 32.8972C36.5 34.2238 36.5 35.2905 36.5 37.4239C36.5 38.7372 36.5 40.4038 36.5 42.5C36.5 45.4451 36.5 47.5421 36.5 49.003C36.5 50.4152 36.5 51.1213 36.9393 51.5607C37.3787 52 38.0855 52 39.4992 52C40.5827 52 41.8279 52 43 52C43.0811 52 43.1616 52 43.2414 52C45.5654 51.999 46.7274 51.9985 47.5871 51.2105C48.4467 50.4225 48.5422 49.3333 48.733 47.1549C48.8826 45.4471 49 43.3565 49 41C49 37.8523 48.7906 35.1736 48.5746 33.2478Z" fill="white"/>
            <path d="M15 17.0209C15 14.7181 15 13.5667 15.6656 12.7421C15.7503 12.6373 15.8499 12.53 15.9482 12.4379C16.7213 11.7131 17.8395 11.6301 20.076 11.4643C23.2005 11.2326 27.4664 11 32 11C36.5336 11 40.7995 11.2326 43.924 11.4643C46.1605 11.6301 47.2787 11.7131 48.0518 12.4379C48.1501 12.53 48.2497 12.6373 48.3344 12.7421C49 13.5667 49 14.7181 49 17.0209C49 19.3044 49 20.4462 48.3349 21.2707C48.2508 21.3749 48.1503 21.4832 48.0526 21.5749C47.2801 22.2996 46.1762 22.3822 43.9685 22.5473C40.9228 22.7751 36.7203 23 32 23C27.2797 23 23.0772 22.7751 20.0315 22.5473C17.8238 22.3822 16.7199 22.2996 15.9474 21.5749C15.8497 21.4832 15.7492 21.3749 15.6651 21.2707C15 20.4462 15 19.3044 15 17.0209Z" fill="white"/>
        </Svg>
    );
}