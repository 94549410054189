import React from "react";
import Flex from "../../ui/Flex";
import { colors } from "../../../providers/theme/theme";
import { HeaderText, MiniText, TitleText } from "../../ui/Text";
import Web3Button from "../../ui/Button/Web3Button";
import { CONTEXT_NAMES, EVENTS } from "../../../providers/analytics/events";
import PlusIcon from "../../svgs/PlusIcon";
import UploadIcon from "../../svgs/UploadIcon";
import { formatCryptoAmount } from "../../../utils/numbers";



export default function PositionSummary({
    stakedAmount,
    unclaimedRewardAmount,
    rewardTokenSymbol,
    stakingTokenSymbol,
    onClickClaimButton,
    onClickClaimAndWithdrawButton,
    claimBroadcasting,
    claimConfirming,
    claimAndWithdrawBroadcasting,
    claimAndWithdrawConfirming,
    customStakedBalanceLabel,
    customClaimAndWithdrawLabel,
}) {
    return (
        <Flex {...styles.Container}>
            <Flex {...styles.PositionDataContainer}>
                <TitleText bold text={"Your Position"} />
                <Flex {...styles.PositionDataRow}>
                    <Flex {...styles.PositionSummaryCardDataContainer}>
                        <HeaderText
                            text={`${formatCryptoAmount(stakedAmount)}`}
                            {...styles.PositionSummaryHeaderText}
                        />
                        <MiniText
                            text={customStakedBalanceLabel || `${stakingTokenSymbol} Staked`}
                            {...styles.PositionSummaryLabelText}
                        />
                    </Flex>
                    <Flex {...styles.PositionSummaryCardDataContainer}>
                        <HeaderText
                            text={`${formatCryptoAmount(unclaimedRewardAmount)}`}
                            {...styles.PositionSummaryHeaderText}
                        />
                        <MiniText
                            text={"Unclaimed TRIBE"}
                            {...styles.PositionSummaryLabelText}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <Flex {...styles.ButtonsContainer}>
                <Web3Button
                    onClick={onClickClaimButton}
                    broadcastingTx={claimBroadcasting}
                    confirmingTx={claimConfirming}
                    globalButtonStyles={{
                        ...styles.Button,
                        marginTop: "0px",
                    }}
                    primaryButtonStyles={{
                        ...styles.ClaimButton
                    }}
                    contextName={CONTEXT_NAMES.TRIBAL_CHIEF}
                    eventName={EVENTS.TRIBAL_CHIEF.CLAIM}
                    buttonContent={(
                        <Flex {...styles.ButtonContentContainer}>
                            <PlusIcon/>
                            <TitleText
                                {...styles.ButtonText}
                                bold
                                text={"Claim all Rewards"}
                            />
                        </Flex>
                    )}
                />
                <Web3Button
                    onClick={onClickClaimAndWithdrawButton}
                    broadcastingTx={claimAndWithdrawBroadcasting}
                    confirmingTx={claimAndWithdrawConfirming}
                    globalButtonStyles={styles.Button}
                    primaryButtonStyles={{
                        ...styles.ClaimAndWithdrawButton
                    }}
                    contextName={CONTEXT_NAMES.TRIBAL_CHIEF}
                    eventName={EVENTS.TRIBAL_CHIEF.CLAIM_AND_WITHDRAW}
                    buttonContent={(
                        <Flex {...styles.ButtonContentContainer}>
                            <UploadIcon/>
                            <TitleText 
                                {...styles.ButtonText}
                                bold 
                                text={customClaimAndWithdrawLabel || "Claim + Withdraw"}
                            />
                        </Flex>
                    )}
                />
            </Flex>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100%",
        height: ["auto", "150px"],
        paddingTop: ["20px", "auto"],
        paddingBottom: ["20px", "auto"],
        paddingLeft: ["10px", "30px"],
        paddingRight: ["10px", "30px"],
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-between"
    },
    PositionSummaryHeaderText: {
        height: "40px",
        fontSize: "22px",
        marginTop: "10px",
    },
    PositionSummaryLabelText: {
        height: "30px",
        marginTop: "2px",
        fontSize: "13px",
        color: colors.grey.light,
        textAlign: "center"
    },
    PositionDataContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    PositionDataRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    PositionSummaryCardDataContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    ButtonsContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: ["20px", "auto"],
        width: ["100%", "auto"]
    },
    Button: {
        marginTop: "15px",
        width: ["100%", "280px"],
    },
    ClaimButton: {
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
    },
    ClaimAndWithdrawButton: {
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
    },
    ButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    ButtonText: {
        marginLeft: "10px",
    },
};