import React from "react";
import get from "lodash/get"
import styled from "styled-components";
import { styles as textStyles } from "./Text";

import {
    borderColor,
    borders,
    color,
    display,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    opacity,
    space,
    textAlign,
    layout,
} from "styled-system";

import {
    hoverBg,
    hoverColor,
    hoverOpacity,
    cursor,
} from "../../providers/theme/customProperties";
import { colors } from "../../providers/theme/theme";
import {track} from "../../providers/analytics";
import {EVENT_TYPES} from "../../providers/analytics/events";

export const LinkElement = styled.a`
  ${borders};
  ${borderColor};
  ${color};
  ${cursor};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${opacity};
  ${space};
  ${textAlign};
  ${layout}

  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  text-decoration: ${props => (props.hasUnderline ? "underline" : "none")};

  @media (pointer: fine) {
    &:hover {
      ${hoverBg};
      ${hoverColor};
      ${hoverOpacity};
      border-bottom: ${props => (props.hasHoverUnderline ? `1px solid blue` : null)};
    }
  }
`;

function Link({
    children,
    onClick,
    text,
    tracking,
    ...props
}) {
    return (
        <LinkElement
            onClick={e => {
                if (tracking) {
                    const contextName = get(tracking, "contextName");
                    const eventName = get(tracking, "eventName");
                    const data = get(tracking, "data");
                    track({
                        contextName,
                        eventName,
                        eventType: EVENT_TYPES.CLICKED_LINK,
                    }, data);
                }
                onClick && onClick(e);
            }}
            {...styles.Base}
            {...props}
        >
            {children || text}
        </LinkElement>
    );
}

const styles = {
    Base: {
        ...textStyles.BodyText,
        hoverColor: colors.white,
        hasUnderline: false,
        cursor: "pointer",
    },
}



export default React.memo(Link);