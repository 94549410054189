import React from "react";

import Svg from "../ui/Svg";

export default function EthCircleIcon({ width = 33, height = 33, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7754 32.8367C25.6119 32.8367 32.7754 25.6732 32.7754 16.8367C32.7754 8.00011 25.6119 0.83667 16.7754 0.83667C7.93883 0.83667 0.775391 8.00011 0.775391 16.8367C0.775391 25.6732 7.93883 32.8367 16.7754 32.8367Z" fill="#627EEA"/>
            <path d="M16.5 4.83691V13.7069L23.997 17.0569L16.5 4.83691Z" fill="white" opacity="0.602"/>
            <path d="M16.498 4.83691L9 17.0569L16.498 13.7069V4.83691Z" fill="white"/>
            <path d="M16.5 22.8049V28.8319L24.002 18.4529L16.5 22.8049Z" fill="white" opacity="0.602"/>
            <path d="M16.498 28.8321V22.8041L9 18.4531L16.498 28.8321Z" fill="white"/>
            <path d="M16.5 21.41L23.997 17.057L16.5 13.709V21.41Z" fill="white" opacity="0.2"/>
            <path d="M9 17.057L16.498 21.41V13.709L9 17.057Z" fill="white" opacity="0.602"/>
        </Svg>
    );
}






