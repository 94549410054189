import React from "react";
import ethConfig from "../../../providers/ethereum/config"
import Flex from "../Flex";
import Link from "../Link";
import { HeaderText, BodyText, MiniText, styles as textStyles } from "../Text";
import DownCaret from "../../svgs/DownCaret";
import { radii, colors } from "../../../providers/theme/theme";
import Close from "../../svgs/Close";
import { track } from "../../../providers/analytics";
import { EVENTS, EVENT_TYPES } from "../../../providers/analytics/events";
import WalletInfo from "../../ui/WalletInfo";

export default function FeiHeader({
    menuOpen,
    toggleMenu,
    links,
    connectedAccount,
    contextName,
}) {
    const dynamicMobileMenuStyles = menuOpen ? styles.MobileMenuOpen : {};
    const hasLinks = links && links.length > 0;
    const hasConnectedAccount = !!connectedAccount;

    return (
        <Flex {...styles.HeaderContainer}>
            <Flex {...styles.ContentContainer}>
                <Flex {...styles.HeaderTextContainer}>
                    <HeaderText 
                        text={"Fei Protocol"}
                        onClick={() => {
                            track({
                                eventName: EVENTS.GENERIC.FEI_MONEY_HEADER,
                                eventType: EVENT_TYPES.CLICKED_LINK,
                                contextName,
                            });
                            window.open("https://fei.money");
                        }}
                        {...styles.PageHeaderText}
                    />
                    {
                        ethConfig.networkName !== "Mainnet" && (
                            <MiniText
                                mono bold error 
                                text={`${ethConfig.networkName}`}
                                fontSize={"10px"}
                            />
                        )
                    }
                </Flex>
                <Flex 
                    {...styles.LinksContainer}
                    justifyContent={hasConnectedAccount ? "center" : "flex-end"}
                >
                    {
                        links.map(({
                            displayName,
                            onClick, 
                            eventTrackingName,
                            notification,
                            dismissNotification,
                            onClickNotification,
                        }, i) => {
                            return (
                                <HeaderLink
                                    key={i}
                                    isLastLink={i === links.length - 1}
                                    onClick={onClick}
                                    displayName={displayName}
                                    contextName={contextName}
                                    eventTrackingName={eventTrackingName}
                                    notification={notification}
                                    dismissNotification={dismissNotification}
                                    onClickNotification={onClickNotification}
                                />
                            );
                        })
                    }
                </Flex>
                <Flex {...styles.MobileMenuLinkContainer}>
                    {
                            (hasLinks || hasConnectedAccount) && (
                                <Link onClick={toggleMenu}>
                                    <Flex {...styles.MobileMenuLinkContent}>
                                        <BodyText text={"Menu"} {...styles.MobileMenuLinkText}/>
                                        <DownCaret {...styles.DownCaret}/>
                                    </Flex>
                                </Link>
                            )
                    }
                </Flex>
                <Flex display={["none", "flex"]}>
                    <WalletInfo contextName={contextName}/>
                </Flex>
                <Flex
                    {...styles.MobileMenuContainer}
                    {...dynamicMobileMenuStyles}
                >
                    {
                        links.map(({displayName, onClick, eventTrackingName}, i) => {
                            return (
                                <Link
                                    key={i}
                                    onClick={onClick}
                                    tracking={{
                                        contextName,
                                        eventName: eventTrackingName,
                                    }}
                                    {...styles.HeaderLinkText}
                                    {...styles.MobileMenuText}
                                >
                                    {displayName}
                                </Link>
                            );
                        })
                    }
                    <Flex display={["flex", "none"]}>
                        <WalletInfo contextName={contextName}/>
                    </Flex>
                    <Flex
                        onClick={toggleMenu}
                        {...styles.MobileMenuCloseLink}
                    >
                        <DownCaret {...styles.UpCaret}/>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

function HeaderLink({
    displayName,
    onClick,
    eventTrackingName,
    contextName,
    notification,
    dismissNotification,
    onClickNotification,
    isLastLink,
}) {
    const linkContainerStyle = {
        paddingRight: isLastLink ? "0px" : "20px",
        position: "relative",
    };

    return(
        <Flex 
            {...linkContainerStyle}
        >
            <Link 
                onClick={onClick}
                tracking={{
                    contextName,
                    eventName: eventTrackingName,
                }}
                {...styles.HeaderLinkText}
            >
                {displayName}
            </Link>
            {
                notification && (
                    <Flex 
                        {...styles.NotificationContainer}
                        onClick={onClickNotification}
                        cursor={!!onClickNotification ? "pointer" : null} 
                    >
                        <MiniText dark text={notification}/>
                        {
                            dismissNotification && (
                                <Close 
                                    onClick={dismissNotification}
                                    {...styles.CloseNotificationIcon}
                                />
                            )
                        }
                        <Flex {...styles.NotificationsCaret}/>
                    </Flex>
                )
            }
        </Flex>
    );
}

const styles = {
    HeaderContainer: {
        width: "100vw",
        backgroundColor: "rgba(255,255,255,0.1)",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
    },
    ContentContainer: {
        width: "100%",
        maxWidth: "750px",
        height: "50px",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    PageHeaderText: {
        fontSize: "20px",
        lineHeight: "24px",
        marginLeft: "20px",
        cursor: "pointer"
    },
    LinksContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        display: ["none", "flex"],
    },
    HeaderLinkText: {
        hoverOpacity: "0.8",
        hoverBg: "transparent",
        ...textStyles.MiniText,
        fontSize: "13px",
    },
    MobileMenuLinkContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "12px",
        display: ["flex", "none"],
    },
    MobileMenuLinkContent: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    MobileMenuLinkText: {
        opacity: "0.76",
        marginRight: "6px"
    },
    MobileMenuContainer: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        width: "100vw",
        backgroundColor: "#2A2C40",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "0px",
        height: "0px",
        transition: "0.5s",
        overflow: "hidden",
        borderBottomRightRadius: radii.small,
        borderBottomLeftRadius: radii.small,
    },
    MobileMenuText: {
        marginBottom: "20px",
        fontSize: "16px",
    },
    MobileMenuCloseLink: {
        width: "100%",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        marginTop: "10px",
    },
    MobileMenuOpen: {
        height: "auto",
        padding: "20px",
        paddingBottom: "10px",
    },
    DownCaret: {
        marginBottom: "5px",
        width: "20px",
        height: "15px"
    },
    UpCaret: {
        transform: "rotate(180)",
        width: "20px",
        height: "15px"
    },
    ConnectWalletButton: {
        height: "35px",
        minHeight: "35px",
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 0,
    },
    NotificationContainer: {
        borderRadius: radii.tiny,
        backgroundColor: colors.grey.lightest,
        padding: "10px",
        position: "absolute",
        bottom: "-50px",
        minWidth: "190px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    NotificationsCaret: {
        height: "15px",
        width: "15px",
        transform: "rotate(45deg)",
        backgroundColor: colors.grey.lightest,
        position: "absolute",
        top: "-7.5px",
        left: "20px",
        zIndex: -1,
    },
    CloseNotificationIcon: {
        cursor: "pointer",
        fill: colors.grey.medium,
        height: "14px",
        width: "14px",
    },
    HeaderTextContainer: {
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
};