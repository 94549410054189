import React from "react";
import ReactLoaderSpinner from "react-loader-spinner"
import Flex from "./Flex";
import { TitleText } from "./Text";
import { colors } from "../../providers/theme/theme";
import EthereumIcon from "../svgs/EthereumIcon";

export default function BroadcastingTransaction({
    shortBroadcastingTxContent,
    text,
}) {

    return (
        <Flex {...styles.Container}>
            <Flex {...styles.EthSpinnerContainer}>
                <Flex {...styles.SpinnerContainer}>
                    <ReactLoaderSpinner
                        type="TailSpin"
                        color={colors.white}
                        height={spinnerSize}
                        width={spinnerSize}
                    />
                </Flex>
                <EthereumIcon height={"20px"} width={"auto"} marginLeft={"2px"}/>
            </Flex>
            {
                !shortBroadcastingTxContent && (
                    <TitleText 
                        text={text}
                        {...styles.Text}
                    />
                )
            }
        </Flex>
    );
}

const spinnerSize = "32px";

const styles = {
    Container: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    Text: {
        marginLeft: "10px",
    },
    EthSpinnerContainer: {
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: spinnerSize,
        height: spinnerSize,
    },
    SpinnerContainer: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        button: "0",
    }
}