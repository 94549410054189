import React from "react";

import Svg from "../ui/Svg";

export default function EthereumIcon({ width = 15, height = 24, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49609 0V8.87L14.9931 12.22L7.49609 0Z" fill="white" opacity="0.602"/>
            <path d="M7.498 0L0 12.22L7.498 8.87V0Z" fill="white"/>
            <path d="M7.49609 17.9682V23.9952L14.9981 13.6162L7.49609 17.9682Z" fill="white" opacity="0.602"/>
            <path d="M7.498 23.9952V17.9672L0 13.6162L7.498 23.9952Z" fill="white"/>
            <path d="M7.49609 16.5731L14.9931 12.2201L7.49609 8.87207V16.5731Z" fill="white" opacity="0.2"/>
            <path d="M0 12.2201L7.498 16.5731V8.87207L0 12.2201Z" fill="white" opacity="0.602"/>
        </Svg>
    );
}


