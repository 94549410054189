import React from "react";
import Flex from "../../ui/Flex";
import config from "../../../config";
import { colors, fontWeights, radii, shadows } from "../../../providers/theme/theme";
import FeiHeader from "../../ui/FeiHeader";
import FeiFooter from "../../ui/FeiFooter";
import { BodyText, HeaderText, LinkSpan } from "../../ui/Text";
import PoolSummary from "./PoolSummary";
import { Fragment } from "react";
import PositionSummary from "./PositionSummary";
import StakingForm from "./StakingForm";
import DepositsTable from "./DepositsTable";
import ConnectWalletButton from "../../ui/Button/ConnectWalletButton";
import { CONTEXT_NAMES } from "../../../providers/analytics/events";
import { PrimaryButton } from "../../ui/Button";
import AaveFeiClaimForm from "./AaveFeiClaimForm";
import { useHistory } from "react-router-dom";
import FuseTribeForm from "./FuseTribeForm";


export default function TribalChiefView({
    selectedPoolId,
    onClickPoolSummary,
    showConnectWalletButton,
    cardConfigs,
    inputValue,
    setInputValue,
    onClickStakeButton,
    onClickClaimButton,
    onClickClaimAndWithdrawButton,
    depositBroadcasting,
    depositConfirming,
    claimBroadcasting,
    claimConfirming,
    claimAndWithdrawBroadcasting,
    claimAndWithdrawConfirming,
    inputError,
    onClickMax,
    JSON_MODE,
}) {

    const history = useHistory();

    return (
        <Flex {...styles.Container}>
            <FeiHeader/>
            <Flex {...styles.HeaderContainer}>
                <HeaderText
                    text={"Farm"}
                    {...styles.PageHeaderText}
                />
                <BodyText
                    secondary
                    text={"Stake DeFi tokens to support Fei Protocol and earn TRIBE rewards"}
                    {...styles.PageSubtitleText}
                />
                <BodyText
                    bold
                    {...styles.PageSubtitleText}
                    color={colors.yellow.notice}
                    marginTop={"30px"}
                    lineHeight={1.6}
                >
                    Don't see your staked FEI-TRIBE LP tokens below? Click <LinkSpan onClick={() => {window.open("https://docs.fei.money/migrating-to-v2-staking")}}>here</LinkSpan> to check out our user guide for migrating them from the v1 staking page.
                </BodyText>
            </Flex>
            <Flex {...styles.CardsContainer}>
                <FuseTribeForm/>
                {
                    cardConfigs.map((cardConfig, index) => {
                        const { poolId, displayYourPositionSection } = cardConfig;
                        return (
                            <Flex {...styles.PoolCardContainer} key={index}>
                                <PoolSummary 
                                    onClickPoolSummary={onClickPoolSummary}
                                    {...cardConfig}
                                />
                                {
                                    (selectedPoolId === poolId && showConnectWalletButton) && (
                                        <Fragment>
                                            <Flex {...styles.SeparatorLine}/>
                                            <Flex {...styles.ConnectWalletContainer}> 
                                                <ConnectWalletButton contextName={CONTEXT_NAMES.TRIBAL_CHIEF}/>
                                            </Flex>
                                        </Fragment>
                                    )
                                }
                                {
                                    (selectedPoolId === poolId && !showConnectWalletButton) && (
                                        <Fragment>
                                            {
                                                displayYourPositionSection && (
                                                    <Fragment>
                                                         <Flex {...styles.SeparatorLine}/>
                                                        <PositionSummary
                                                            onClickClaimButton={onClickClaimButton}
                                                            onClickClaimAndWithdrawButton={onClickClaimAndWithdrawButton}
                                                            claimBroadcasting={claimBroadcasting}
                                                            claimConfirming={claimConfirming}
                                                            claimAndWithdrawBroadcasting={claimAndWithdrawBroadcasting}
                                                            claimAndWithdrawConfirming={claimAndWithdrawConfirming}
                                                            {...cardConfig}
                                                        />
                                                    </Fragment>
                                                )
                                            }

                                            <Flex {...styles.SeparatorLine}/>
                                            <StakingForm
                                                onClickMax={onClickMax}
                                                onClickStakeButton={onClickStakeButton}
                                                inputValue={inputValue}
                                                setInputValue={setInputValue}
                                                depositBroadcasting={depositBroadcasting}
                                                depositConfirming={depositConfirming}
                                                inputError={inputError}
                                                {...cardConfig}
                                            />
                                            {/* <Flex {...styles.SeparatorLine}/> */}
                                            {/* <DepositsTable
                                                {...cardConfig}
                                            /> */}
                                        </Fragment>
                                    )
                                }
                            </Flex>

                        );
                    })
                }
                <AaveFeiClaimForm/>
            </Flex>
            <PrimaryButton
                {...styles.ToggleStakingPageButton}
                text={"Old Staking Page"}
                onClick={() => {history.push("/stake-v1")}}
            />
            {
                JSON_MODE && config.jsonMode && (
                    <BodyText 
                        bold 
                        mono 
                        lineHeight={"2"}
                        marginTop={"300px"}
                        text={JSON.stringify(JSON_MODE, null, "\t")}
                        maxWidth={"100%"}
                    />
                )
            }
            <FeiFooter/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: colors.grey.dark,
        overflow: "hidden",
        paddingBottom: "40px",
    },
    HeaderContainer: {
        maxWidth: ["300px", "100vw"],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        zIndex: 1,
    },
    PageHeaderText: {
        marginTop: 50,
        fontWeight: fontWeights.regular,
        textAlign: "center",
    },
    PageSubtitleText: {
        marginTop: 10,
        textAlign: "center",
        maxWidth: "600px",
    },
    CardsContainer: {
        width: ["95%", "700px"],
        marginTop: "30px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    PoolCardContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: colors.darkCardBackground,
        borderRadius: radii.small,
        marginTop: "20px",
        shadow: shadows.primary,
    },
    SeparatorLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    ConnectWalletContainer: {
        marginBottom: "10px"
    },
    ToggleStakingPageButton: {
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
        marginTop: "70px",
    }
    
};