import React from "react";
import { colors, radii } from "../../../providers/theme/theme";
import { formatCryptoAmount } from "../../../utils/numbers";
import DownloadIcon from "../../svgs/DownloadIcon";
import MaxButton from "../../ui/Button/MaxButton";
import Web3Button from "../../ui/Button/Web3Button";
import Flex from "../../ui/Flex";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import { BoldSpan, LinkSpan, MiniText, TitleText } from "../../ui/Text";
import BigNumber from "bignumber.js";


export default function StakingForm({
    onClickStakeButton,
    inputValue,
    setInputValue,
    errorMessage,
    stakingTokenSymbol,
    stakingTokenUserBalance,
    lockupOptions,
    requiresTokenTransferApproval,
    tokenApprovalTransactionName,
    depositBroadcasting,
    depositConfirming,
    inputError,
    onClickMax,
    buyTokenLink,
    customButtonLabel,
}) {
    const inputValueBN = new BigNumber(inputValue);
    const stakingTokenUserBalanceBN = new BigNumber(stakingTokenUserBalance);
    const stakeButtonDisabled = 
        !!inputError || 
        !inputValue || 
        inputValue === "0" ||
        inputValueBN.lt(0) ||
        stakingTokenUserBalanceBN.lt(inputValueBN);

    return (
        <Flex {...styles.Container}>
            <Flex {...styles.LeftSideContainer}>
                <Flex {...styles.AmountInputContainer}>
                    <Input
                        mono
                        type={"number"}
                        value={inputValue}
                        onChange={setInputValue}
                        {...styles.FormInput}
                        backgroundColor={inputError ? "#773E4A": "#525669"}
                        {...{paddingRight: `${20 + (stakingTokenSymbol.length * 11)}px`}}
                    />
                    <TitleText
                        mono bold
                        text={stakingTokenSymbol}
                        {...styles.AmountInputTokenSymbol}
                        
                    />
                </Flex>
                <Flex {...styles.BalanceRow}>
                    <MiniText {...styles.BalanceText}>
                            <BoldSpan>BALANCE:</BoldSpan> {formatCryptoAmount(stakingTokenUserBalance)} {stakingTokenSymbol}
                    </MiniText>
                    <MaxButton onClick={onClickMax}/>
                </Flex>

                {/* <Select
                    options={lockupOptions}
                    onSelect={(value) => { console.log(value); }}
                /> */}
            </Flex>
            <Flex {...styles.RightSideContainer}>
                <Web3Button
                    onClick={onClickStakeButton}
                    globalButtonStyles={styles.Button}
                    primaryButtonStyles={styles.StakeButton}
                    buttonContent={(
                        <Flex {...styles.ButtonContentContainer}>
                            <DownloadIcon/>
                            <TitleText 
                                {...styles.ButtonText}
                                bold 
                                text={customButtonLabel || "Stake"}
                            />
                        </Flex>
                    )}
                    requiresApproval={requiresTokenTransferApproval}
                    approvalTransactionName={tokenApprovalTransactionName}
                    broadcastingTx={depositBroadcasting}
                    confirmingTx={depositConfirming}
                    disabled={stakeButtonDisabled}
                />
                {
                    buyTokenLink && (
                        <MiniText {...styles.BalanceText} {...{marginTop: "8px"}}>
                            Don't have {stakingTokenSymbol}? Get some <LinkSpan onClick={() => { window.open(buyTokenLink);}}>here</LinkSpan>
                        </MiniText>
                    )
                }
            </Flex>

        </Flex>
    );
}

const styles = {
    Container: {
        flexDirection: ["column", "row"],
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        paddingBottom: ["20px", "auto"]
    },
    Button: {
        width: "100%",
        marginTop: "0px",
    },
    StakeButton: {
        backgroundColor: colors.green,
        hoverBg: colors.green,
    },
    ButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    ButtonText: {
        marginLeft: "10px",
    },
    LeftSideContainer: {
        flex: 1,
        paddingRight: ["0px", "20px"],
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
    },
    RightSideContainer: {
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        height: "100%",
        width: ["100%", "280px"],
        paddingTop: ["20px", "0px"]
    },
    AmountInputContainer: {
        position: "relative",
        width: "100%"
    },
    FormInput: {
        borderRadius: radii.small,
        width: "100%",
        backgroundColor: "#525669",
        color: colors.white,
        height: "44px",
        paddingLeft: 52,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    AmountInputTokenSymbol: {
        position: "absolute",
        right: 12,
        top: 12,
        color: "#D3D3D3",
    },
    BalanceText: {
        color: "#9799A4",
        fontSize: "14px",

    },
    BalanceRow: {
        paddingTop: "6px",
        paddingLeft: "5px",
        paddingRight: "5px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    }
};