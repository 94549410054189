const stagingConfig = {
    network: "RINKEBY",
    postGenesis: false,
    jsonMode: false,
    comingSoonMode: false,
    // amplitudeKey: "86e08cd9b4c35fb0b5ec6617b0243c86",
    feiTribeUniswapLpLink: "https://app.uniswap.org/#/add/0x37CbDCE73b16ea8C68A7c29C3Ac6af638f651E0D/0xC6a75927c7f5388Ce9E1BE1eE31Efe0Eede55df3",
    fip2RedemptionEnabled: false,
};

export default stagingConfig;