import React from "react";
import { Fragment } from "react";
import PoolSummary from "../PoolSummary";
import Flex from "../../../ui/Flex";
import { colors, fontWeights, radii, shadows } from "../../../../providers/theme/theme";
import FeiIcon from "../../../svgs/FeiIcon";
import Web3Button from "../../../ui/Button/Web3Button";
import { HeaderText, MiniText, TitleText } from "../../../ui/Text";
import { formatCryptoAmount } from "../../../../utils/numbers";
import PlusIcon from "../../../svgs/PlusIcon";
import AaveLogo from "../../../svgs/AaveLogo";

export default function ComponentNameView({
    tvl,
    apr,
    formOpen,
    onClickSummary,
    userAFeiBalance,
    userUnclaimedTribe,
    hasConnectedAccount,
    onClickClaimButton,
    claimBroadcasting,
    claimConfirming,
    claimButtonDisabled,
}) {
    const stakingTokenSymbol = "Borrowed FEI";
    return (
        <Flex {...styles.PoolCardContainer}>
            <PoolSummary
                tvl={tvl}
                apr={apr}
                onClickPoolSummary={onClickSummary} 
                stakingTokenSymbol={stakingTokenSymbol}
                buyTokenLink={"https://app.aave.com/reserve-overview/FEI-0x956f47f50a910163d8bf957cf5846d573e7f87ca0xb53c1a33016b2dc2ff3653530bff1848a515c8c5"}
                PoolIcon={(
                    <Flex position={"relative"}>
                        <FeiIcon width={"50px"} height={"50px"}/>
                        <AaveLogo 
                            width={"30px"} 
                            height={"30px"}
                            position={"absolute"}
                            right={"-15px"}
                            bottom={"-3px"}
                        />
                    </Flex>
                )}
                poolDescription={"Aave Variable Debt FEI"}
            />
            {
                formOpen && (
                    <Fragment>
                        <Flex {...styles.SeparatorLine}/>
                        <Flex {...styles.Form}>
                            {
                                !hasConnectedAccount ? (
                                    <Flex {...{width: "280px"}}>
                                        <Web3Button/>
                                    </Flex> 
                                ) : (
                                    <Flex {...styles.FormRow}>
                                        <Flex {...styles.PositionDataContainer}>
                                            <TitleText bold text={"Your Position"} />
                                            <Flex {...styles.PositionDataRow}>
                                                <Flex {...styles.PositionSummaryCardDataContainer}>
                                                    <HeaderText
                                                        text={`${formatCryptoAmount(userAFeiBalance)}`}
                                                        {...styles.PositionSummaryHeaderText}
                                                    />
                                                    <MiniText
                                                        text={`${stakingTokenSymbol}`}
                                                        {...styles.PositionSummaryLabelText}
                                                    />
                                                </Flex>
                                                <Flex {...styles.PositionSummaryCardDataContainer}>
                                                    <HeaderText
                                                        text={`${formatCryptoAmount(userUnclaimedTribe)}`}
                                                        {...styles.PositionSummaryHeaderText}
                                                    />
                                                    <MiniText
                                                        text={"Unclaimed TRIBE"}
                                                        {...styles.PositionSummaryLabelText}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex {...{width: "280px"}}>
                                            <Web3Button
                                                disabled={claimButtonDisabled}
                                                onClick={onClickClaimButton}
                                                broadcastingTx={claimBroadcasting}
                                                confirmingTx={claimConfirming}
                                                globalButtonStyles={{
                                                    ...styles.Button,
                                                    marginTop: "0px",
                                                }}
                                                primaryButtonStyles={{
                                                    ...styles.ClaimButton
                                                }}
                                                buttonContent={(
                                                    <Flex {...styles.ButtonContentContainer}>
                                                        <PlusIcon/>
                                                        <TitleText
                                                            {...styles.ButtonText}
                                                            bold
                                                            text={"Claim all Rewards"}
                                                        />
                                                    </Flex>
                                                )}
                                            />
                                        </Flex>

                                    </Flex>
                                )
                            }
                        </Flex>
                    </Fragment>

                )
            }
        </Flex>

    );
}

const styles = {
    PoolCardContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: colors.darkCardBackground,
        borderRadius: radii.small,
        marginTop: "20px",
        shadow: shadows.primary,
    },
    Form: {
        paddingBottom: "15px",
        paddingTop: "15px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    FormRow: {
        flexDirection: ["column", "row"],
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
    },
    SeparatorLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    PositionDataContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    PositionDataRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    PositionSummaryCardDataContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    PositionSummaryHeaderText: {
        height: "40px",
        fontSize: "22px",
        marginTop: "10px",
    },
    PositionSummaryLabelText: {
        height: "30px",
        marginTop: "2px",
        fontSize: "13px",
        color: colors.grey.light,
        textAlign: "center"
    },
    Button: {
        width: ["90%", "280px"],
        marginTop: "15px"
    },
    ClaimButton: {
        backgroundColor: colors.blue.tribe,
        hoverBg: colors.blue.tribe,
    },
    ButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    ButtonText: {
        marginLeft: "10px",
    },
};