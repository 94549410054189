export const EVENT_TYPES = {
    CLICKED_BUTTON: "CLICKED_BUTTON",
    CLICKED_LINK: "CLICKED_LINK",
    CLICKED_NOTIFICATION: "CLICKED_NOTIFICATION",
    VIEWED_SCREEN: "VIEWED_SCREEN",
    INPUT_TEXT: "INPUT_TEXT",
    OPENED_SELECTOR: "OPENED_SELECTOR",
    SELECTED_OPTION: "SELECTED_OPTION",
    COMPLETED_ACTION: "COMPLETED_ACTION",
    FAILED_ACTION: "FAILED_ACTION",
    TX_SUBMITTED: "TX_SUBMITTED",
    TX_ERROR: "TX_ERROR",
    TX_SUCCESS: "TX_SUCCESS",
    API_CALL: "API_CALL",
    API_ERROR: "API_ERROR",
};

export const CONTEXT_NAMES = {
    GENESIS: "GENESIS",
    EXCHANGE: "EXCHANGE",
    STAKE: "STAKE",
    HEADER: "HEADER",
    TRANSACTIONS: "TRANSACTIONS",
    BACKGROUND: "BACKGROUND",
    ANALYTICS_PAGE: "ANALYTICS_PAGE",
    WRONG_NETWORK_MODAL: "WRONG_NETWORK_MODAL",
    FIP_2_REDEEM: "FIP_2_REDEEM",
    TRIBAL_CHIEF: "TRIBAL_CHIEF",
};

export const EVENTS = {
    [CONTEXT_NAMES.GENESIS]: {
        PURCHASE: "PURCHASE",
        COMMIT: "COMMIT",
        REDEEM: "REDEEM",
        GOOGLE_CALENDAR_LINK: "GOOGLE_CALENDAR_LINK",
        PRESWAP_MODAL_CTA: "PRESWAP_MODAL_CTA",
        CAN_BURN_FEI: "CAN_BURN_FEI",
        AUDIT_REPORT: "AUDIT_REPORT",
    },
    [CONTEXT_NAMES.EXCHANGE]: {
        SELL_FEI: "SELL_FEI",
        BUY_FEI: "BUY_FEI",
        APPROVE_FEI: "APPROVE_FEI",
        ACCEPT_HIGH_PENALTY: "ACCEPT_HIGH_PENALTY",
    },
    [CONTEXT_NAMES.STAKE]: {
        APPROVE_LP: "APPROVE_LP",
        STAKE_LP: "STAKE_LP",
        CLAIM_TRIBE: "CLAIM_TRIBE",
        WITHDRAW_LP: "WITHDRAW_LP",
        GO_TO_UNISWAP_POOL: "GO_TO_UNISWAP_POOL",
        GO_TO_DOCS: "GO_TO_DOCS",
        USER_GUIDE: "USER_GUIDE",
    },
    [CONTEXT_NAMES.TRANSACTIONS]: {
        TOAST_ETHERSCAN: "TOAST_ETHERSCAN",
    },
    [CONTEXT_NAMES.TRIBAL_CHIEF]: {
        CLAIM: "CLAIM",
        CLAIM_AND_WITHDRAW: "CLAIM_AND_WITHDRAW",
        WITHDRAW: "WITHDRAW",
    },
    GENERIC: {
        OPEN_METAMASK_IO_LINK: "OPEN_METAMASK_IO_LINK",
        CONNECT_WALLET: "CONNECT_WALLET",
        DISCONNECT_WALLET: "DISCONNECT_WALLET",
        HEADER_LINK: "HEADER_LINK",
        FOOTER_LINK: "FOOTER_LINK",
        WATCHER_WEB3_CALL: "WATCHER_WEB3_CALL",
        WATCHER_WEB3_ERROR: "WATCHER_WEB3_ERROR",
        FEI_MONEY_HEADER: "FEI_MONEY_HEADER",
        TOKEN_APPROVAL: "TOKEN_APPROVAL",
    },
    ANALYTICS_PAGE: {

    },
    FIP_2_REDEEM: {
        REDEEM: "REDEEM",
        USER_GUIDE: "USER_GUIDE",
    },
};