import React from "react";
import { CONTEXT_NAMES, EVENTS } from "../../../providers/analytics/events";
import config from "../../../config";
import { colors, fontWeights, radii } from "../../../providers/theme/theme";
import WavesImageBackground from "../../ui/backgrounds/WavesImageBackground";
import FeiFooter from "../../ui/FeiFooter";
import FeiHeader from "../../ui/FeiHeader";
import Input from "../../ui/Input";
import Flex from "../../ui/Flex";
import { HeaderText, TitleText, MiniText, BoldSpan, BodyText, LinkSpan } from "../../ui/Text";
import FeiIcon from "../../svgs/FeiIcon";
import EthCircleIcon from "../../svgs/EthCircleIcon";
import { formatCryptoAmount } from "../../../utils/numbers";
import { PrimaryButton } from "../../ui/Button";
import ConnectWalletButton from "../../ui/Button/ConnectWalletButton";
import ExchangeIcon from "../../svgs/ExchangeIcon";
import MaxButton from "../../ui/Button/MaxButton";
import FormErrorDisplay from "../../ui/FormErrorDisplay";
import { Stat } from "../Analytics/view";

export default function FipTwoRedeemView({
    JSON_MODE,
    currentEthAvailable,
    inputValue,
    setInputValue,
    amountOut,
    errorMessage,
    showConnectWalletButton,
    onClickRedeem,
    buttonDisabled,
    broadcastingTx,
    confirmingTx,
    onClickMax,
    feiBalance,
}) {

    return (
        <Flex {...styles.Container}>
            <WavesImageBackground customContainerStyle={{
                top: [460,320]
            }}/>
            <FeiHeader contextName={CONTEXT_NAMES.FIP_2_REDEEM}/>
            <HeaderText
                    text={"Redeem ETH"}
                    {...styles.PageHeaderText}
            />
            <TitleText {...styles.SubtitleText}>
                Redeem your FEI for $1 with a 0.5% fee
            </TitleText>
            <Flex {...styles.StatContainer}>
                <Stat
                    value={`${formatCryptoAmount(currentEthAvailable)}`}
                    label={"Current ETH Available"}
                    tooltipText={"More ETH is available from Compound and Aave deposits at a rate of 10k ETH per 2 hours"}
                    customContainerProps={{width: "250px"}}
                />
            </Flex>
            <Flex {...styles.FormContainer}>
                <Flex {...styles.LabelValueContainer}>
                    <MiniText {...styles.DarkText}>
                        <BoldSpan>BALANCE:</BoldSpan> {formatCryptoAmount(feiBalance)} FEI
                    </MiniText>
                    <MaxButton onClick={onClickMax}/>
                </Flex>
                <Flex {...styles.AmountInputContainer}>
                        <Input
                            mono
                            type={"number"}
                            value={inputValue}
                            onChange={setInputValue}
                            {...styles.FormInput}
                            backgroundColor={errorMessage ? "#773E4A": "#525669"}
                        />
                        <FeiIcon {...styles.FormInputIcon}/>
                        <TitleText
                            mono bold
                            text={"FEI"}
                            {...styles.AmountInputTokenSymbol}
                        />
                </Flex>
                <Flex {...styles.AmountInputContainer} marginBottom={"15px"}>
                    <Flex {...styles.FormInput}>
                        <TitleText text={formatCryptoAmount(amountOut)}/>
                    </Flex>
                    <EthCircleIcon {...styles.FormInputIcon}/>
                    <TitleText
                        mono bold
                        text={"ETH"}
                        {...styles.AmountInputTokenSymbol}
                    />
                </Flex>
                {
                    showConnectWalletButton ? (
                        <ConnectWalletButton
                            contextName={CONTEXT_NAMES.FIP_2_REDEEM}
                        />
                    ) : (
                        <PrimaryButton
                            onClick={onClickRedeem}
                            {...styles.PrimaryButton}
                            disabled={buttonDisabled}
                            backgroundColor={colors.red.dark}
                            hoverBg={colors.red.dark}
                            broadcastingTx={broadcastingTx}
                            confirmingTx={confirmingTx}
                            tracking={{
                                contextName: CONTEXT_NAMES.FIP_2_REDEEM,
                                eventName: EVENTS.FIP_2_REDEEM.REDEEM,
                            }}
                        >
                            <Flex {...styles.PrimaryButtonContentContainer}>
                                <ExchangeIcon {...styles.PrimaryButtonIcon}/>
                                <TitleText
                                    bold
                                    text={"Redeem"}
                                />
                            </Flex>
                        </PrimaryButton>
                    )
                }
            </Flex>
            <FormErrorDisplay errorMessage={errorMessage}/>
            {
                JSON_MODE && config.jsonMode && (
                    <BodyText 
                        bold 
                        mono 
                        marginTop={100}  
                        lineHeight={"2"} 
                        text={JSON.stringify(JSON_MODE, null, "\t")}
                        maxWidth={"100%"}
                    />
                )
            }
            <FeiFooter contextName={CONTEXT_NAMES.FIP_2_REDEEM}/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: colors.grey.dark,
        overflow: "hidden",
        paddingBottom: "40px",
    },
    PageHeaderText: {
        marginTop: 50,
        fontWeight: fontWeights.regular
    },
    FormContainer: {
        marginTop: [20, 30],
        backgroundColor: colors.darkCardBackground,
        borderRadius: radii.medium,
        width: ["100%", 380],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 15, 
        zIndex: 1,
    },
    AmountInputContainer: {
        width: "100%",
        position: "relative",
        marginTop: "15px",
    },
    FormInput: {
        borderRadius: radii.medium,
        width: "100%",
        backgroundColor: "#525669",
        color: colors.white,
        height: 50,
        paddingLeft: 52,
        paddingRight: 55,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    FormInputIcon: {
        position: "absolute",
        left: 10,
        top: 9,
        width: 32,
        height: 32,
    },
    AmountInputTokenSymbol: {
        position: "absolute",
        right: 12,
        top: 14,
        color: "#D3D3D3"
    },
    PrimaryButton: {
        width: "100%",
        marginTop: "10px",
    },
    PrimaryButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    PrimaryButtonIcon: {
        marginRight: "20px",
    },
    LabelValueContainer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    DarkText: {
        color: "#9799A4",
    },
    SubtitleText: {
        opacity: "0.8",
        marginTop: "30px",
        textAlign: "center",
        maxWidth: ["250px", "100%"],
        fontSize: ["16px", "16px"]
    },
    StatContainer: {
        flexDirection: ["column", "row"],
        marginTop: "20px",
    }
};