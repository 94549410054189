import BigNumber from "bignumber.js";
import {CURRENCY_DECIMALS} from "../constants";

const MAX_DECIMALS = 6;

export function formatDollarAmount(amount) {
    BigNumber.set({ ROUNDING_MODE: 3 })
    const bn = new BigNumber(amount);
    if (bn.eq(0) || !amount) {
        return "0";
    }
    if (bn.lt(0.01)) {
        return bn.decimalPlaces(MAX_DECIMALS).toString();
    }
    if (bn.lt(10000)) {
        return withCommas(bn.toFixed(2)).toString();
    }
    return withCommas(bn.toFixed(0).toString())
}

export function formatCryptoAmount(amount, options = {}) {
    const {
        withoutCommas,
        noDecimals,
    } = options;
    BigNumber.set({ ROUNDING_MODE: 3 })
    const bn = new BigNumber(amount);
    if (bn.eq(0) || !amount) {
        return "0";
    }
    if (bn.lt(1)) {
        return bn.decimalPlaces(noDecimals ? 0 : MAX_DECIMALS).toString();
    }
    if (bn.lt(100)) {
        return bn.decimalPlaces(noDecimals ? 0 : 3).toString();
    }
    if (withoutCommas) {
        return bn.decimalPlaces(noDecimals ? 0 : 2).toString();
    }
    return withCommas(bn.decimalPlaces(noDecimals ? 0 : 2).toString());
}

export function withCommas(amount) {
    if (!amount) {
        return;
    }
    if (typeof(amount) === "number") {
        amount = amount.toString();
    }
    
    const amountBN = new BigNumber(amount);
    const threeCommaRegex = /\B(?=(\d{3})+(?!\d))/g;
    if (amountBN.isInteger()) {
        return amount.replace(threeCommaRegex, ",");
    }
    const [integerPart, decimalPart] = amount.split(".");
    const integerPartWithCommas = integerPart.replace(threeCommaRegex, ",");
    return `${integerPartWithCommas}.${decimalPart}`;

}

const twoHundredFiftyMillion = "250000000";

export function getFeiSupply(fgenAmount, ethPrice, scaleTarget = twoHundredFiftyMillion) {
    const fgenAmountBN = new BigNumber(fgenAmount);
    const ethPriceBN = new BigNumber(ethPrice);
    const scaleTargetBN = new BigNumber(scaleTarget);
    const twoThirdsBN = new BigNumber(2/3);
    const threeHalvesBN = new BigNumber(3/2);

    const intermediateValueBN = threeHalvesBN
        .times(scaleTargetBN.sqrt())
        .times(fgenAmountBN)
        .times(ethPriceBN);

    const feiSupply = Math.pow(intermediateValueBN.toString(), twoThirdsBN.toString())

    return feiSupply.toString();
}

export function getNextFeiPrice(feiSupply, scaleTarget = twoHundredFiftyMillion) {
    const feiSupplyBN = new BigNumber(feiSupply);
    const scaleTargetBN = new BigNumber(scaleTarget);
    if (feiSupplyBN.gte(scaleTargetBN)) {
        return "1";
    }
    const nextFeiPriceBN = feiSupplyBN.dividedBy(scaleTarget).squareRoot();
    return nextFeiPriceBN.toString();
}

export function fromWei(valueInWei, tokenSymbol) {
    const valueInWeiBN = new BigNumber(valueInWei);
    const numDecimals = CURRENCY_DECIMALS[tokenSymbol];
    const divisorBN = (new BigNumber(10)).exponentiatedBy(numDecimals);
    const valueBN = valueInWeiBN.div(divisorBN);
    return valueBN.toString();
}

export function toWei(value, tokenSymbol) {
    const valueBN = new BigNumber(value);
    const numDecimals = CURRENCY_DECIMALS[tokenSymbol];
    const multiplierBN = (new BigNumber(10)).exponentiatedBy(numDecimals);
    const valueInWeiBN = valueBN.times(multiplierBN);
    return valueInWeiBN.toFixed(0);
}

export function normalizeChainId(chainId) {
    const type = typeof(chainId);
    if (type === "string" && chainId.substr(0,2) !== "0x") {
        return `0x${chainId}`;
    }
    
    if (type === "number") {
        return `0x${chainId.toString(16)}`;
    }
    
    return chainId;
}

export function hexStringToInt(hexNumberString) {
    if (!hexNumberString || typeof(hexNumberString) !== "string") {
        return;
    }
    return parseInt(hexNumberString, 16);
}