import React, {useState} from "react";
import { PrimaryButton } from ".";
import { EVENTS } from "../../../providers/analytics/events";
import { useEthereum } from "../../../providers/ethereum";
import { colors } from "../../../providers/theme/theme";
import AllowanceProgressBar from "../AllowanceProgressBar";
import Flex from "../Flex";
import { TitleText } from "../Text";
import ConnectWalletButton from "./ConnectWalletButton";

export default function Web3Button({
    contextName,
    eventName,
    globalButtonStyles,
    primaryButtonStyles,
    buttonContent,
    onClick,
    broadcastingTx,
    confirmingTx,
    requiresApproval,
    approvalTransactionName,
    disabled,
}) {
    const {
        connectedAccount,
        transactions,
    } = useEthereum();

    const [approveButtonBroadcasting, setApproveButtonBroadcasting] = useState(false);
    const [approveButtonConfirming, setApproveButtonConfirming] = useState(false);

    const showConnectWalletButton = !!!connectedAccount;
    if (showConnectWalletButton) {
        return (
            <ConnectWalletButton
                contextName={contextName}
                style={globalButtonStyles}
            />
        );
    }

    async function onClickApprovalButton() {
        if (requiresApproval && approvalTransactionName) {
            setApproveButtonBroadcasting(true);
            await transactions[approvalTransactionName](null, {
                onBroadcast: () => {
                    setApproveButtonBroadcasting(false);
                    setApproveButtonConfirming(true);
                },
                onConfirm: () => {
                    setApproveButtonConfirming(false);     
                },
                onFailure: () => {
                    setApproveButtonBroadcasting(false);
                    setApproveButtonConfirming(false);
                },
            }); 
        }
    }

    return (
        <Flex {...styles.ButtonsContainer}>
            <Flex {...styles.ButtonsRow}>
                {
                    requiresApproval && (
                        <PrimaryButton
                            {...globalButtonStyles}
                            {...styles.ApproveButton}
                            broadcastingTx={approveButtonBroadcasting}
                            confirmingTx={approveButtonConfirming}
                            onClick={onClickApprovalButton}
                            shortBroadcastingTxContent
                            tracking={{
                                contextName,
                                eventName: EVENTS.GENERIC.TOKEN_APPROVAL
                            }}
                        >
                            <TitleText
                                bold
                                text={"Approve"}
                            />
                        </PrimaryButton>
                    )
                }
                <PrimaryButton
                    {...globalButtonStyles}
                    {...primaryButtonStyles}
                    onClick={onClick}
                    broadcastingTx={broadcastingTx}
                    confirmingTx={confirmingTx}
                    disabled={disabled || requiresApproval}
                    tracking={{
                        contextName,
                        eventName,
                    }}
                >
                    {buttonContent}
                </PrimaryButton>
            </Flex>
            {
                requiresApproval && (<AllowanceProgressBar/>)
            }
        </Flex>

    );

}

const styles = {
    ButtonsContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    ButtonsRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
    },
    ApproveButton: {
        backgroundColor: colors.purple,
        hoverBg: colors.purple,
        marginRight: "15px"
    },
    ApproveButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
    
};