import React from "react";

import Svg from "../ui/Svg";

export default function MediumIcon({ width = 37, height = 21, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7292 10.5002C20.7292 16.2991 16.0888 21 10.3648 21C4.6408 21 0 16.298 0 10.5002C0 4.70233 4.64045 0 10.3648 0C16.0891 0 20.7292 4.70127 20.7292 10.5002ZM32.0993 10.5002C32.0993 15.9586 29.7791 20.3853 26.9169 20.3853C24.0548 20.3853 21.7346 15.9586 21.7346 10.5002C21.7346 5.04176 24.0544 0.615073 26.9166 0.615073C29.7788 0.615073 32.099 5.04034 32.099 10.5002H32.0993ZM36.75 10.5002C36.75 15.3896 35.9341 19.3557 34.9273 19.3557C33.9206 19.3557 33.105 15.3906 33.105 10.5002C33.105 5.60971 33.9209 1.64468 34.9273 1.64468C35.9338 1.64468 36.75 5.60935 36.75 10.5002Z" fill="white"/>
        </Svg>

    );
}