import React from "react";
import Flex from "../ui/Flex";
import { colors } from "../../providers/theme/theme";
import FeiTribeIcon from "../svgs/FeiTribeIcon";
import { HeaderText } from "../ui/Text";

export default function ComingSoon() {
    return (
        <Flex {...styles.Container}>
            <FeiTribeIcon height={"100px"} width={"200px"} marginBottom={"20px"}/>
            <HeaderText {...styles.HeaderText} text={"Under maintenance. Coming back soon."}/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: colors.grey.dark,
    },
    HeaderText: {
        textAlign: "center",
        maxWidth: "90vw"
    },
};