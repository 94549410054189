import React, {Fragment} from "react";
import config from "../config";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Genesis from "../components/pages/Genesis";
// import Exchange from "../components/pages/Exchange";
import Stake from "../components/pages/Stake";
// import Analytics from "../components/pages/Analytics";
import NotFound from "../components/pages/NotFound";
import ComingSoon from "../components/pages/ComingSoon";
import FipTwoRedeem from "../components/pages/FipTwoRedeem";
import TribalChief from "../components/pages/TribalChief";


export default function AppNavigator() {
    if (config.comingSoonMode) {
        return (
            <Switch>
                <Route component={ComingSoon}/>
            </Switch>
        );
    }
    if (config.postGenesis) {
        return (
            <Switch>
                <Route path="/" exact component={TribalChief}/>
                <Route path="/stake" exact component={TribalChief}/>
                <Route path="/farm" exact component={TribalChief}/>
                <Route path={"/stake-v1"} exact component={Stake}/>
                <Route path="/genesis" exact component={Genesis}/>
                {/* <Route path="/exchange" exact component={Exchange}/> */}
                {/* <Route path="/analytics" exact component={Analytics}/> */}
                <Route path={"/redeem"} exact component={FipTwoRedeem}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
    return (
        <Switch>
            <Route path="/genesis" exact component={Genesis}/>
            <Route path="/" exact component={Genesis}/>
            <Route component={NotFound}/>
        </Switch>
    );
}

// Docs: https://reactrouter.com/web/
// Good explainer on react router basics: https://dev.to/codepanda/intro-to-react-router-1ngn