import React, {Fragment} from "react";
import Flex from "../Flex";

export default function Modal({
    showModal,
    closeModal = () => {},
    children
}) {
    if (showModal) {
        return (
            <Flex {...styles.Overlay} >
                <Flex 
                    {...styles.OverlayBackground} 
                    onClick={() => closeModal()}
                />
                <Flex {...styles.Dialog}>
                    {children}
                </Flex>
            </Flex>
        );
    }
    return <Fragment/>;

}

const styles = {
    Overlay: {
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: "20",
        alignItems: "center",
        justifyContent: "center",
    },
    OverlayBackground: {
        backgroundColor: "rgba(0,0,0,0.7)",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        height: "100%",
        width: "100%",
        zIndex: "21",
    },  
    Dialog: {
        zIndex:"21",
    }
};