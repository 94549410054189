import amplitude from "amplitude-js";
import config from "../../config";

// Calls the current amplitude instance. If the instance
// doesn't exist yet, then initialize it and call the methods on init
const callAmplitudeInstance = (methodName, args) => {
    // const amplitudeInstance = amplitude.getInstance();
    // if (!amplitudeInstance) {
    //     return;
    // }
    // if (amplitudeInstance._isInitialized) {
    //     amplitudeInstance[methodName].apply(amplitudeInstance, args);
    //     return;
    // }
    // amplitudeInstance.init(config.amplitudeKey);
    // amplitudeInstance.onInit(() => {
    //     amplitudeInstance[methodName].apply(amplitudeInstance, args);
    // });
}

export const setAmplitudeUserDevice = installationToken => {
    callAmplitudeInstance("setDeviceId", [installationToken]);
};

export const setAmplitudeUserId = userId => {
    callAmplitudeInstance("setUserId", [userId]);
};

export const setAmplitudeUserProperties = properties => {
    callAmplitudeInstance("setUserProperties", [properties]);
};

const sendAmplitudeData = (eventType, eventProperties) => {
    callAmplitudeInstance("logEvent", [eventType, eventProperties]);
};


const toScreamingSnakeCase = path => path.replace(/ /g, "_").toUpperCase();

export function track(eventProperties = {}, properties = {}) {
    const { contextName, eventType, eventName } = eventProperties;
    const trackingName = toScreamingSnakeCase(`${contextName}:${eventType}:${eventName}`);
    // sendAmplitudeData(trackingName, properties);
}