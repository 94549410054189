import { useState } from "react";
import get from "lodash/get";
import { useEthereum } from "../../../providers/ethereum";
import TribeIcon from "../../svgs/TribeIcon";
import FeiIcon from "../../svgs/FeiIcon";
import FeiTribeIcon from "../../svgs/FeiTribeIcon";
import FeiRariIcon from "../../svgs/FeiRariIcon";
import BigNumber from "bignumber.js";
import CurveIcon from "../../svgs/CurveIcon";
import { withCommas } from "../../../utils/numbers";
import GUniLogo from "../../svgs/GUniLogo";
import config from "../../../config";
import Flex from "../../ui/Flex";
import { BodyText, LinkSpan } from "../../ui/Text";


export function useTribalChiefLogic({...props}) {

    const [selectedPoolId, setSelectedPoolId] = useState(null);
    const [inputValue, _setInputValue] = useState();
    const [maxAmountSelected, setMaxAmountSelected] = useState(false);
    const setInputValue = (val) => {
        _setInputValue(val);
        setMaxAmountSelected(false);
    };

    const [depositBroadcasting, setDepositBroadcasting] = useState(false);
    const [depositConfirming, setDepositConfirming] = useState(false);

    const [claimBroadcasting, setClaimBroadcasting] = useState(false);
    const [claimConfirming, setClaimConfirming] = useState(false);

    const [claimAndWithdrawBroadcasting, setClaimAndWithdrawBroadcasting] = useState(false);
    const [claimAndWithdrawConfirming, setClaimAndWithdrawConfirming] = useState(false);

    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();

    const showConnectWalletButton = !!!connectedAccount;
    const selectedPoolConfig = tribalChiefConfig.pools.filter(({poolId}) => {return poolId === selectedPoolId})[0];

    const tribeUsdPrice = get(protocolState, "USD_PRICES.TRIBE", "0");
    const tribePerBlock = get(protocolState, "TRIBAL_CHIEF.TRIBE_PER_BLOCK", "0");
    const totalAllocPoints = get(protocolState, "TRIBAL_CHIEF.TOTAL_ALLOC_POINTS", "0");

    const selectedStakingTokenBalance = get(protocolState, `BALANCES.${(selectedPoolConfig || {}).stakingTokenSymbol}`, "0");
    
    const selectedStakingTokenBalanceBN = new BigNumber(selectedStakingTokenBalance);
    const tribeUsdPriceBN = new BigNumber(tribeUsdPrice);
    const tribePerBlockBN = new BigNumber(tribePerBlock);
    const totalAllocPointsBN = new BigNumber(totalAllocPoints);

    const blockTimeBN = new BigNumber(13.3);
    const secondsInAYearBN = new BigNumber(3600 * 24 * 365);
    const blocksPerYearBN = secondsInAYearBN.div(blockTimeBN);
    const tribalChiefTribePerYearBN = blocksPerYearBN.times(tribePerBlockBN);
    const tribalChiefTribeValuePerYearBN = tribalChiefTribePerYearBN.times(tribeUsdPriceBN);

    const inputValueBN = new BigNumber(inputValue);
    const inputError = inputValueBN.gt(selectedStakingTokenBalanceBN);

    function onClickPoolSummary(poolId) {
        setInputValue("");
        setSelectedPoolId(selectedPoolId === poolId ? null : poolId);
    }

    async function onClickStakeButton() {
        setDepositBroadcasting(true);

        const depositAmount = maxAmountSelected ? 
            selectedStakingTokenBalance :
            inputValue;
        await transactions.TribalChiefDeposit({
            tokenSymbol: selectedPoolConfig.stakingTokenSymbol,
            poolId: selectedPoolId,
            depositAmount,
        },{
            onBroadcast: () => {
                setDepositBroadcasting(false);
                setDepositConfirming(true);
            },
            onConfirm: () => {
                setDepositConfirming(false);
                setInputValue("");  
            },
            onFailure: () => {
                setDepositBroadcasting(false);
                setDepositConfirming(false);
            },
        });
    }

    async function onClickClaimButton() {
        setClaimBroadcasting(true);

        await transactions.TribalChiefHarvest({
            poolId: selectedPoolId,
            tokenSymbol: selectedPoolConfig.stakingTokenSymbol,
        }, {
            onBroadcast: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(true);
            },
            onConfirm: () => {
                setClaimConfirming(false);     
            },
            onFailure: () => {
                setClaimBroadcasting(false);
                setClaimConfirming(false);
            },
        });
    }

    async function onClickClaimAndWithdrawButton() {
        setClaimAndWithdrawBroadcasting(true);

        await transactions.TribalChiefHarvestAndWithdraw({
            poolId: selectedPoolId,
            tokenSymbol: selectedPoolConfig.stakingTokenSymbol,
        }, {
            onBroadcast: () => {
                setClaimAndWithdrawBroadcasting(false);
                setClaimAndWithdrawConfirming(true);
            },
            onConfirm: () => {
                setClaimAndWithdrawConfirming(false);     
            },
            onFailure: () => {
                setClaimAndWithdrawBroadcasting(false);
                setClaimAndWithdrawConfirming(false);
            },
        });
    }

    const onClickMax = () => {
        const formattedMaxAmount = selectedStakingTokenBalanceBN.dp(6, BigNumber.ROUND_DOWN).toString();
        _setInputValue(formattedMaxAmount);
        setMaxAmountSelected(true);
    }

    const cardConfigs = tribalChiefConfig.pools.map(({
        poolId,
        stakingTokenSymbol,
        stakingTokenSymbolCustomDisplay,
        stakingTokenAddress,
        PoolIcon,
        poolDescription,
        rewardTokenSymbol,
        RewardTokenIcon,
        tokenApprovalTransactionName,
        buyTokenLink,
        hackMessage,
    }) => {

        const stakedAmount = get(protocolState, `TRIBAL_CHIEF.STAKED_BALANCE.${stakingTokenSymbol}`, "0");
        const unclaimedRewardAmount = get(protocolState, `TRIBAL_CHIEF.ALL_PENDING_REWARDS.${stakingTokenSymbol}`);
        const stakingTokenUserBalance = get(protocolState, `BALANCES.${stakingTokenSymbol}`, "0");
        const tribalChiefAllowance = get(protocolState, `ALLOWANCES.${stakingTokenSymbol}.TRIBAL_CHIEF`, "0");
        const poolAllocPoints = get(protocolState, `TRIBAL_CHIEF.ALLOCATION_POINTS.${stakingTokenSymbol}`, "0");
        
        const stakingTokenTcBalance = get(protocolState, `TRIBAL_CHIEF.TOTAL_STAKED.${stakingTokenSymbol}`, "0");
        const stakingTokenUsdValue = get(protocolState, `USD_PRICES.${stakingTokenSymbol}`, "0");
        const stakingTokenTcBalanceBN = new BigNumber(stakingTokenTcBalance);
        const stakingTokenUsdValueBN = new BigNumber(stakingTokenUsdValue);
        const poolAllocPointsBN = new BigNumber(poolAllocPoints);

        const poolsShareOfRewardsBN = poolAllocPointsBN.div(totalAllocPointsBN);
        const poolTribeValuePerYearBN = tribalChiefTribeValuePerYearBN.times(poolsShareOfRewardsBN);
        const tvlBN = stakingTokenTcBalanceBN.times(stakingTokenUsdValueBN);
        const aprBN = tvlBN.gt(0) ? poolTribeValuePerYearBN.div(tvlBN).times(100).decimalPlaces(1) : new BigNumber(0);

        const displayYourPositionSection = stakedAmount !== "0" || unclaimedRewardAmount !== "0";
        const requiresTokenTransferApproval = tribalChiefAllowance === "0"

        return {
            poolId,
            stakingTokenSymbol: stakingTokenSymbolCustomDisplay || stakingTokenSymbol,
            PoolIcon,
            poolDescription,
            apr: aprBN.toString(), // TODO: implement
            tvl: tvlBN.toString(),
            rewardTokenSymbol,
            RewardTokenIcon,
            stakingTokenUserBalance,
            stakedAmount,
            unclaimedRewardAmount,
            displayYourPositionSection,
            lockupOptions: [], // for later
            requiresTokenTransferApproval,
            tokenApprovalTransactionName,
            buyTokenLink,
            hackMessage,
        };
    });

    const JSON_MODE = {
        protocolState,
    };
    
    return {
        ...props,
        selectedPoolId,
        onClickPoolSummary,
        showConnectWalletButton,
        cardConfigs,
        inputValue,
        setInputValue,
        onClickStakeButton,
        onClickClaimButton,
        onClickClaimAndWithdrawButton,
        depositBroadcasting,
        depositConfirming,
        claimBroadcasting,
        claimConfirming,
        claimAndWithdrawBroadcasting,
        claimAndWithdrawConfirming,
        inputError,
        onClickMax,
        JSON_MODE,
    };
}

// Currently set up for the testing pools on Rinkeby
// TODO: add staking token symbol to decimals to staking constants for each new pool added
export const tribalChiefConfig = {
    pools: [
        // {
        //     poolId: 0,
        //     stakingTokenSymbol: "FTRIBE",
        //     stakingTokenSymbolCustomDisplay: "fTRIBE",
        //     tokenApprovalTransactionName: "ApproveTribalChiefTribeOnTribeWrapper",
        //     tokenContractName: "FuseTribeOnTribeStakingWrapperToken",
        //     PoolIcon: (<FeiRariIcon width={"42px"} height={"68px"}/>),
        //     rewardTokenSymbol: "TRIBE",
        //     RewardTokenIcon: TribeIcon,
        //     poolDescription: "TRIBE supplied on Fuse",
        // },
        {
            poolId: 0,
            stakingTokenSymbol: "TRIBE_FEI_LP",
            stakingTokenSymbolCustomDisplay: "TRIBE-FEI",
            tokenApprovalTransactionName: "ApproveTribalChiefFeiTribeLp",
            tokenContractName: "FeiTribeUniswapV2Pair",
            PoolIcon: (<FeiTribeIcon width={"50px"} height={"50px"} />),
            rewardTokenSymbol: "TRIBE",
            RewardTokenIcon: TribeIcon,
            poolDescription: "FEI-TRIBE LP",
            buyTokenLink: config.feiTribeUniswapLpLink
        },
        {
            poolId: 1,
            stakingTokenSymbol: "FEI3CRV",
            stakingTokenSymbolCustomDisplay: "FEI+3CRV",
            tokenApprovalTransactionName: "ApproveTribalChiefCurve3Metapool",
            tokenContractName: "Curve3MetapoolToken",
            PoolIcon: (
                <Flex position={"relative"} onClick={() => {window.open("https://app.aave.com/reserve-overview/FEI-0x956f47f50a910163d8bf957cf5846d573e7f87ca0xb53c1a33016b2dc2ff3653530bff1848a515c8c5")}}>
                    <FeiIcon width={"50px"} height={"50px"}/>
                    <CurveIcon 
                        width={"30px"} 
                        height={"30px"}
                        position={"absolute"}
                        right={"-15px"}
                        bottom={"-3px"}
                    />
                </Flex>
            ),
            // PoolIcon: (<CurveIcon  width={"45px"} height={"45px"} />),
            rewardTokenSymbol: "TRIBE",
            RewardTokenIcon: TribeIcon,
            poolDescription: "Curve 3Pool + Fei",
            buyTokenLink: "https://curve.fi/factory/11/deposit",
        },
        {
            poolId: 2,
            stakingTokenSymbol: "G_UNI_FEI_DAI",
            stakingTokenSymbolCustomDisplay: "G-UNI FEI-DAI",
            tokenApprovalTransactionName: "ApproveGUniFeiDaiLp",
            tokenContractName: "GelatoUniswapDaiFeiLp",
            PoolIcon: (<GUniLogo width={"65px"} height={"65px"}/>),
            rewardTokenSymbol: "TRIBE",
            RewardTokenIcon: TribeIcon,
            poolDescription: "Gelato Uniswap FEI-DAI-LP",
            buyTokenLink: "https://www.sorbet.finance/#/pools/0x3D1556e84783672f2a3bd187a592520291442539",
            hackMessage: (
                <BodyText
                    error
                    bold
                    fontSize={"11px"}
                >
                    Sorbet Finance discovered a vulnerability on their contracts. Visit{" "}
                    <LinkSpan onClick={() => {window.open("https://www.sorbet.finance/#/limit-order")}}>this page</LinkSpan>{" "}
                    to secure your funds.
                </BodyText>
            ),
        }
    ]
};