import React, { useEffect, useState } from "react";
import get from "lodash/get";
import ethConfig from "../../../providers/ethereum/config";
import CtaModal from "./CtaModal";
import { hexToRGB } from "../../../utils/ui";
import { CONTEXT_NAMES, EVENTS } from "../../../providers/analytics/events";

export default function WrongEthereumNetworkModal({disconnectAccount, connectedChainId}) {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!connectedChainId) {
            setShowModal(false);
            return;
        }

        const expectedChainId = get(ethConfig, "chainId");
        setShowModal(connectedChainId !== expectedChainId);

    }, [connectedChainId]);

    const expectedNetworkName = get(ethConfig, "networkName");


    return (
        <CtaModal
            showModal={showModal}
            closeModal={() => {}} // Don't allow closing this modal
            headerText={"Wrong Network"}
            bodyText={`Please connect to the ${expectedNetworkName} network.`}
            // ctaButtonColor={hexToRGB("#000000", "0.2")}
            // ctaButtonText={"Disconnect Wallet"}
            // onClickCtaButton={disconnectAccount}
            // ctaTracking={{
            //     eventName: EVENTS.GENERIC.DISCONNECT_WALLET,
            //     contextName: CONTEXT_NAMES.WRONG_NETWORK_MODAL,
            // }}
        />
    );
}