import React from "react";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
} from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";

import config from "../../config";

const cache = new InMemoryCache({
    addTypename: true,
});

const subgraphLinks = [
    {
        name: "usdc-subgraph",
        uri: config.usdcSubgraphUri,
    },
    {
        name: "uniswap-v2-subgraph",
        uri: config.uniswapV2SubgraphUri,
    },
    {
        name: "genesis-subgraph",
        uri: config.genesisSubgraphUri,
    }
];

const links = subgraphLinks.map(({ name, uri }) => {
    // This split function makes it so that the link forwards to the
    // next one if the name doesn't match the "backendName" context value
    return ApolloLink.split(
        operation => operation.getContext().backendName === name,
        createHttpLink({ uri })
    );
});

// We add feiBackendLink at the end so that all remaining requests fall through to this one
const feiBackendLink = createHttpLink({ 
    uri: config.apiUri 
});
links.push(feiBackendLink);

const link = ApolloLink.from(links);

export const client = new ApolloClient({
    link,
    cache,
});

export default function Provider({children}) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}