import Image from "../ui/Image";
import source from "../../assets/images/rari.png"
export default function RariLogo({ width = "42px", height = "68px", ...props }) {
    return (
        <Image
            src={source}
            style={{
                width,
                height,
                ...styles.Image
            }}
            {...props}
        />
    );
}

const styles = {
    Image: {

    }
};