import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import get from "lodash/get";
import { useEthereum } from "../../../providers/ethereum";
import { formatCryptoAmount } from "../../../utils/numbers";

export function useFipTwoRedeemLogic({...props}) {
    const [inputValue, setInputValue] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [broadcastingTx, setBroadcastingTx] = useState(false);
    const [confirmingTx, setConfirmingTx] = useState(false);

    const {
        connectedAccount,
        protocolState,
        transactions,
    } = useEthereum();

    useEffect(() => {
        if (errorMessage) {
            setErrorMessage("");
        }
    }, [inputValue]);

    const feiBalance = get(protocolState, "BALANCES.FEI", "0");
    const ethPerFei = get(protocolState, "RESERVE_STABILIZATION.ETH_PER_FEI", "0");
    const ethInReserveStabilizer = get(protocolState, "RESERVE_STABILIZATION.ETH_AVAILABLE_FIP_20", "0");
    const ethInPcvDripper = get(protocolState, "RESERVE_STABILIZATION.ETH_IN_DRIPPER", "0");

    const feiAmountBN =  new BigNumber(inputValue ? inputValue : 0);
    const feiBalanceBN = new BigNumber(feiBalance);
    const ethInReserveStabilizerBN = new BigNumber(ethInReserveStabilizer);
    const ethInPcvDripperBN = new BigNumber(ethInPcvDripper);
    const ethPerFeiBN = new BigNumber(ethPerFei);
    const amountOutBN = feiAmountBN.times(ethPerFeiBN);
    const amountOut = amountOutBN.toString();
    const totalEthAvailable = ethInPcvDripperBN.plus(ethInReserveStabilizerBN).toString();

    const insufficientBalance = !inputValue || feiAmountBN.gt(feiBalanceBN);
    const buttonDisabled = !inputValue || insufficientBalance;

    const onClickMax = () => {
        setInputValue(feiBalance);
    };

    const onClickRedeem = async () => {
        if (amountOutBN.gt(ethInReserveStabilizerBN)) {
            let reserveBalanceError = `There is only ${formatCryptoAmount(ethInReserveStabilizer)} ETH currently available.`;
            if (ethInPcvDripperBN.gt(0)) {
                reserveBalanceError += " Another 5k ETH becomes available every hour if there is < 5k ETH available.";
            }
            setErrorMessage(reserveBalanceError);
            return;
        }
        setBroadcastingTx(true);
        await transactions.ExchangeFeiOnReserveStabilizer(
            { feiAmount: inputValue },
            {
                onBroadcast: () => {
                    setBroadcastingTx(false);
                    setConfirmingTx(true);
                },
                onConfirm: () => {
                    setConfirmingTx(false);     
                },
                onFailure: () => {
                    setBroadcastingTx(false);
                    setConfirmingTx(false);
                },
            }
        )
    };

    const JSON_MODE = { protocolState };

    return {
        JSON_MODE,
        currentEthAvailable: ethInReserveStabilizer,
        inputValue,
        setInputValue,
        amountOut,
        errorMessage,
        showConnectWalletButton: !!!connectedAccount,
        feiBalance,
        onClickRedeem,
        onClickMax,
        buttonDisabled,
        broadcastingTx,
        confirmingTx,
        ...props,
    };
}