import React from "react";
import config from "../../../config";
import TwitterIcon from "../../svgs/TwitterIcon";
import MediumIcon from "../../svgs/MediumIcon";
import DiscordIcon from "../../svgs/DiscordIcon";
import TelegramIcon from "../../svgs/TelegramIcon";
import GithubIcon from "../../svgs/GithubIcon";
import Flex from "../Flex";
import {MiniText, TitleText} from "../Text";
import { hexToRGB } from "../../../utils/ui";
import { colors } from "../../../providers/theme/theme";
import { track } from "../../../providers/analytics";
import { EVENTS, EVENT_TYPES } from "../../../providers/analytics/events";


export default function FeiFooterView({
    contextName,
    connectedAccount,
    watchAssetsFromWallet,
    isMetamask,
}) {
    const iconLinks = [
        {
            IconComponent: TwitterIcon,
            url: config.links.twitter,
        },
        {
            IconComponent: MediumIcon,
            url: config.links.medium
        },
        {
            IconComponent: DiscordIcon,
            url: config.links.discord,
            customStyle: styles.DiscordIconStyle,
        },
        {
            IconComponent: GithubIcon,
            url: config.links.github,
        },
        {
            IconComponent: TelegramIcon,
            url: config.links.telegram,
        },
    ];

    const textLinks = [
        {
            text: "Bug Bounty",
            url: "https://immunefi.com/bounty/feiprotocol",
        },
        {
            text: "Terms of Service",
            url: "https://assets.fei.money/docs/fei_terms_of_service_03_18_21.pdf"
        },
        {
            text: "Cookie Policy",
            url: "https://assets.fei.money/docs/fei_cookie_policy_03_18_21.pdf",
        },
        {
            text: "Privacy Policy",
            url: "https://assets.fei.money/docs/fei_privacy_policy_03_18_21.pdf"
        }
    ];

    if (connectedAccount && isMetamask && watchAssetsFromWallet) {
        textLinks.push({
            text: "Add Tokens to Wallet",
            onClick: watchAssetsFromWallet,
        });
    }
    return (
        <Flex {...styles.Container}>
            <Flex {...styles.TreelineContainer}>
                <Flex {...styles.TreelineLine}/>
                <TitleText
                    text={"🌲"}
                    {...styles.TreeEmojiText}
                />
                <Flex {...styles.TreelineLine}/>
            </Flex>
            <Flex {...styles.IconLinksContainer}>
                {
                    iconLinks.map(({
                        IconComponent,
                        url,
                        customStyle
                    }, i) => {
                        return (
                            <IconComponent
                                key={i}
                                onClick={() => {
                                    window.open(url);
                                }}
                                {...styles.IconLink}
                                {...customStyle}
                            />
                        );
                    })
                }
            </Flex>
            <Flex {...styles.TextLinkContainer}>
                {
                    textLinks.map(({text, url, onClick}, i) => {
                        return (
                            <MiniText 
                                {...styles.TextLink}
                                key={i}
                                text={text}
                                onClick={() => {
                                    if (onClick) {
                                        onClick();
                                        return;
                                    }
                                    track({
                                        contextName,
                                        eventType: EVENT_TYPES.CLICKED_LINK,
                                        eventName: EVENTS.GENERIC.FOOTER_LINK,
                                    }, {
                                        url,
                                    })
                                    window.open(url);
                                }}
                            >

                            </MiniText>
                        );
                    })
                }
            </Flex>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "100px"
    },
    TreelineContainer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    TreelineLine: {
        flex: 1,
        height: "2px",
        backgroundColor: hexToRGB(colors.grey.medium, "0.3")
    },
    TreeEmojiText: {
        marginLeft: "7px",
        marginRight: "7px",
        fontSize: "25px",
        lineHeight: 1,
    },
    IconLinksContainer: {
        marginTop: "15px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "300px",
    },
    IconLink: {
        opacity: "0.7",
        fill: colors.white,
        cursor: "pointer",
        height: "20px"
    },
    DiscordIconStyle: {
        height: "30px",
        width: "25px",
    },
    TextLinkContainer: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-between",
        width: ["auto", "550px"],
        height: ["120px", "auto"],
        marginTop: "20px",
        marginBottom: "0px",
    },
    TextLink: {
        cursor: "pointer",
        textAlign: "center",
    }
};