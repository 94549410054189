import React from "react";
import { MiniText } from "../Text";
import Flex from "../Flex";
import {PrimaryButton} from "./index";
import { colors, radii } from "../../../providers/theme/theme";

export default function MaxButton({onClick, customText}) {
    return (
        <PrimaryButton
            onClick={onClick}
            {...styles.Button}
        >
            <Flex {...styles.ButtonContent}>
                <MiniText
                    bold
                    text={customText || "MAX"}
                    {...styles.Text}
                />
            </Flex>
        </PrimaryButton>
    );
}

const styles = {
    Button: {
        bg: "transparent",
        hoverBg: colors.blue.linkDark,
        borderColor: colors.blue.linkDark,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: radii.tiny,
        paddingLeft: "0px",
        paddingRight: "0px",
        height: "auto",
        minHeight: "0px",
    },
    ButtonContent: {
        width: "100%",
        height: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    Text: {
        color: colors.blue.linkDark,
        hoverColor: "white",
        paddingLeft: "4px",
        paddingRight: "3.5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        letterSpacing: "1px",
        fontSize: "10px",
        lineHeight: 1,
    },
};