import React from "react";
import styled, { css } from "styled-components";
import {
    color,
    layout,
    position,
    space,
    typography,
    border,
} from "styled-system";
import get from "lodash/get";
import { radii } from "../../../providers/theme/theme";
import Flex from "../Flex";
import DownCaret from "../../svgs/DownCaret";

const SelectElement = React.memo(styled.select`

    ${color};
    ${layout};
    ${position};
    ${space};
    ${typography};
    ${border};

    &:focus {
        outline-width: 0;
    }

    /* Hide the down caret */
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;

`);
const OptionElement = React.memo(styled.option``);

export default function Select({
    options,
    onSelect,
}) {
    return (
        <Flex {...styles.Container}>
            <DownCaret
                {...styles.DownCaretIcon}
            />
            <SelectElement
                {...styles.Select}
                onChange={(e) => {
                    const value = get(e, "target.value");
                    onSelect(value);
                }}
            >
                {
                    options.map((option, index) => {
                        return (
                            <OptionElement value={index} key={index}>
                                {option}
                            </OptionElement>
                        );
                    })
                }
            </SelectElement>
        </Flex>
    );
}

const styles = {
    Container: {
        position: "relative"
    },
    DownCaretIcon: {
        position: "absolute",
        right: "7px",
        top: "15px",
        fill: "white",
        opacity: "1",
        width: "15px"
    },
    Select: {
        backgroundColor: "#525669",
        border: "none",
        borderRadius: radii.small,
        height: "44px",
        color: "white",
        paddingLeft: "15px",
        paddingRight: "25px",
        fontSize: "16px",
    }
};