import React from "react";
import ReactLoaderSpinner from "react-loader-spinner"
import { colors } from "../../providers/theme/theme";


export default function Loader({...props}) {
    return (
        <ReactLoaderSpinner
            type="BallTriangle"
            color={colors.grey.medium}
            height={100}
            width={100}
            {...props}
        />
    )
}