import React from "react";
import Flex from "../../ui/Flex";
import { HeaderText, MiniText } from "../../ui/Text";
import { colors } from "../../../providers/theme/theme";
import { formatDollarAmount, withCommas } from "../../../utils/numbers";


export default function PoolSummary({
    poolId,
    stakingTokenSymbol,
    PoolIcon,
    poolDescription,
    apr,
    rewardTokenSymbol,
    RewardTokenIcon,
    tvl,
    onClickPoolSummary,
    buyTokenLink,
    hideBuyTokenMessage,
    hackMessage,
}) {
    return (
        <Flex
            {...styles.PoolSummaryCard}
            onClick={() => onClickPoolSummary(poolId)}
        >
            {
                hackMessage && (
                    <Flex
                        position={"absolute"}
                        top={"5px"}
                        left={["10px", "100px"]}
                        right={["10px", "auto"]}
                    >
                        {hackMessage}
                    </Flex>
                )
            }
            <Flex 
                {...styles.IconContainer}
                onClick={() => {window.open(buyTokenLink)}}
                paddingTop={hackMessage ? ["40px", "0px"] : "auto"}
            >
                {PoolIcon}  
            </Flex>  
            <Flex
                {...styles.PoolSummaryCardDataContainer}
                minWidth={["auto", "220px"]}
                width={["auto", "220px"]}
            >
                <HeaderText 
                    text={stakingTokenSymbol}
                    {...styles.PoolSummaryHeaderText}
                />
                <MiniText 
                    text={poolDescription} 
                    textAlign={"center"}
                    {...styles.PoolSummaryLabelText}
                />
            </Flex>

            <Flex 
                {...styles.PoolSummaryCardDataContainer}
                width={["auto", "140px"]}
                minWidth={["auto", "140px"]}
            >
                <HeaderText 
                    text={`$${formatDollarAmount(tvl)}`}
                    {...styles.PoolSummaryHeaderText}
                />
                <MiniText
                    text={"TVL"}
                    {...styles.PoolSummaryLabelText}
                />
            </Flex>
            {/* <Flex
                {...styles.PoolSummaryCardDataContainer}
                flex={1}
            >
                <RewardTokenIcon 
                    height={"30px"} 
                    width={"30px"} 
                    marginBottom={"8px"}
                    marginTop={"23px"}
                />
                <MiniText
                    text={"Reward Token"}
                    textAlign={"center"}
                    {...styles.PoolSummaryLabelText}
                />
            </Flex> */}
            <Flex
                {...styles.PoolSummaryCardDataContainer}
                flex={1}
            >
                <HeaderText
                    text={`${withCommas(apr)}%`}
                    {...styles.PoolSummaryHeaderText}
                />
                <MiniText
                    text={"APR"}
                    {...styles.PoolSummaryLabelText}
                />
            </Flex>
        </Flex>
    );
}

const styles = {
    PoolSummaryCard: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "flex-start",
        height: ["auto", "100px"],
        width: "100%",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: ["20px", "0px"],
        cursor: "pointer",
        position: "relative"
    },
    PoolSummaryCardDataContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    PoolSummaryHeaderText: {
        height: "40px",
        fontSize: "26px",
        marginTop: "20px",
    },
    PoolSummaryLabelText: {
        height: "30px",
        marginTop: "2px",
        fontSize: "13px",
        color: colors.grey.light
    },
    IconContainer: {
        alignItems: "center",
        justifyContent: "center",
        width: ["auto", "70px"],
        minWidth: ["auto", "70px"],
    }
}