import React, {useEffect, useState} from "react";
import Flex from "../Flex";
import { BodyText } from "../Text";
import { radii, colors, } from "../../../providers/theme/theme";
import "./styles.css"

export default function FormErrorDisplay({
    errorMessage,
    light
}) {
    const [className, setClassName] = useState("initialPosition");

    useEffect(() => {
        setClassName(!!errorMessage ? "finalPosition" : "initialPosition")
    }, [errorMessage]);

    const lightModeStyles = light ? {
        backgroundColor: colors.grey.lightest,
    } : {};

    return (
        <Flex {...styles.Container}>
            <Flex
                {...styles.AnimatedContainer}
                {...lightModeStyles}
                className={className}
            >
                <BodyText
                    paragraph
                    text={errorMessage}
                    {...styles.Text}
                />
            </Flex>
        </Flex>
    );
}

const styles = {
    Container: {
        position: "relative",
        width: ["90%", 260],
    },
    AnimatedContainer: {
        position: "absolute",
        width: "100%",
        paddingTop: 12,
        paddingBottom: 12,
        paddingRight: 15,
        paddingLeft: 15,
        backgroundColor: "#23283E",
        borderBottomRightRadius: radii.small,
        borderBottomLeftRadius: radii.small,
        alignItems: "center",
        justifyContent: "center",
    },
    Text: {
        fontSize: "14px",
        color: colors.red.error,
        textAlign: "center",
    },
};