import { useEthereum } from "../../../providers/ethereum";

export function useFeiFooterLogic(props) {
    const {
        connectedAccount,
        watchAssetsFromWallet,
        walletProviderName,
    } = useEthereum();

    const isMetamask = walletProviderName === "METAMASK";

    return {
        connectedAccount,
        watchAssetsFromWallet,
        isMetamask,
        ...props,
    };
}