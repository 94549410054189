import {useEffect, useState} from "react";
import {useEthereum} from "../../../providers/ethereum";
import BigNumber from "bignumber.js";
import get from "lodash/get";
import { track } from "../../../providers/analytics";
import { CONTEXT_NAMES, EVENTS, EVENT_TYPES } from "../../../providers/analytics/events";

export function useStakeLogic() {
    // Setup state
    const [inputField, setInputField] = useState({
        lpTokenAmount:"",
        maxAmountSelected: false,
    });
    const {
        lpTokenAmount,
        maxAmountSelected,
    } = inputField;
    const setLpTokenAmount = (newValue) => {
        setInputField({
            maxAmountSelected: false,
            lpTokenAmount: newValue,
        });
    };

    const [action, setAction] = useState("WITHDRAW");
    const [broadcastingTx, setBroadcastingTx] = useState(false);
    const [confirmingTx, setConfirmingTx] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {
        protocolState,
        transactions,
        connectedAccount,
        loading,
    } = useEthereum();


    const lpTokenBalance = get(protocolState, "BALANCES.TRIBE_FEI_LP", "0");
    const lpTokenAllowance = get(protocolState, "ALLOWANCES.TRIBE_FEI_LP.FEI_POOL");
    const lpTokenSupply = get(protocolState, "UNI_FEI_TRIBE.TRIBE_FEI_LP_SUPPLY", "0");
    const stakedBalance = get(protocolState, "BALANCES.STAKED_TRIBE_FEI_LP", "0");
    const redeemableTribeRewards = get(protocolState, "STAKING.REDEEMABLE_TRIBE_REWARD", "0");
    const rewardForDuration = "0";//get(protocolState, "STAKING.REWARD_FOR_DURATION", "0");
    const stakedLpSupply = get(protocolState, "STAKING.STAKED_LP_SUPPLY", "0");
    const rewardPerToken = get(protocolState, "STAKING.REWARD_PER_TOKEN", "0");
    const feiReserves = get(protocolState, "UNI_FEI_TRIBE.FEI_RESERVES", "0");
    const tribeReserves = get(protocolState, "UNI_FEI_TRIBE.TRIBE_RESERVES", "0");

    const lpTokenAmountBN = new BigNumber(lpTokenAmount);
    const lpTokenBalanceBN = new BigNumber(lpTokenBalance);
    const lpTokenSupplyBN = new BigNumber(lpTokenSupply);
    const rewardForDurationBN = new BigNumber(rewardForDuration);
    const stakedLpSupplyBN = new BigNumber(stakedLpSupply);
    const feiReservesBN = new BigNumber(feiReserves);
    const tribeReservesBN = new BigNumber(tribeReserves);
    const stakedBalanceBN = new BigNumber(stakedBalance);

    const lpTokenValueBN = lpTokenSupplyBN.gt(0) ? 
        feiReservesBN.div(lpTokenSupplyBN).times(2): // The FEI-denominated value of 1 LP token
        new BigNumber(0); 
    const weeklyTribeRewardPerStakedLpTokenBN =  stakedLpSupplyBN.gt(0) ? 
        rewardForDurationBN.div(stakedLpSupplyBN) :
        new BigNumber(0);
    const weeklyTribeRewardPerOneThousandFeiBN = 
        weeklyTribeRewardPerStakedLpTokenBN
            .times(1000)
            .div(lpTokenValueBN);
    const tribePriceBN = feiReservesBN.div(tribeReservesBN);

    const fullApyBN = weeklyTribeRewardPerOneThousandFeiBN
        .times(tribePriceBN)
        .times(52)
        .times(100).div(1000);

    const frontLoadedApy = (1621884073000 - Date.now()) > 0;
    const variableApy = frontLoadedApy ? 
        fullApyBN.times(2/3.4).dp(2).toString() :
        fullApyBN.dp(2).toString();
    const stakedLpValue = lpTokenValueBN.times(stakedBalanceBN).toString();
    const displayAllowLpTokenButton = lpTokenAllowance === "0" && action === "STAKE";
    const weeklyTribeRewardPerOneThousandFei = weeklyTribeRewardPerOneThousandFeiBN.toString();

    // Clear the input if we toggle between action tabs
    useEffect(() => {
        setLpTokenAmount("");
        setErrorMessage("");
    }, [action]);

    useEffect(() => {
        if (errorMessage) {
            setErrorMessage("");
        }
    },[lpTokenAmount])

    const onClickAllowLpToken = async () => {
        setBroadcastingTx(true);
        await transactions.SetMaxFeiTribeLpAllowanceOnStakingPool(null, {
            onBroadcast: () => {
                setBroadcastingTx(false);
                setConfirmingTx(true);
            },
            onConfirm: () => {
                setConfirmingTx(false);
            },
            onFailure: () => {
                setBroadcastingTx(false);
                setConfirmingTx(false);
            },
        });
        
    };

    const onClickMax = () => {
        const formattedMaxAmount = lpTokenBalanceBN.dp(6, BigNumber.ROUND_DOWN).toString();
        setInputField({
            lpTokenAmount: formattedMaxAmount,
            maxAmountSelected: true,
        });
    }

    const onClickReadMore = () => {
        track({
            contextName: CONTEXT_NAMES.STAKE,
            eventType: EVENT_TYPES.CLICKED_LINK,
            eventName: EVENTS.STAKE.USER_GUIDE
        })
        window.open("https://docs.fei.money/staking-fei-tribe-lp");
    }

    const formConfig = {
        STAKE: {
            onClickPrimaryButton: async () => {
                if (!lpTokenAmount || lpTokenAmountBN.lte(0)) {
                    setErrorMessage("Please enter a valid amount");
                    return;
                }
                if (lpTokenAmountBN.gt(lpTokenBalanceBN)) {
                    setErrorMessage("Insufficient balance");
                    return;
                }

                setBroadcastingTx(true);
                const lpTokenAmountToSubmit = maxAmountSelected ?
                    lpTokenBalanceBN.toString() :
                    lpTokenAmount;

                await transactions.StakeLpTokens({ 
                    lpTokenAmount: lpTokenAmountToSubmit 
                }, {
                    onBroadcast: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(true);
                    },
                    onConfirm: () => {
                        setConfirmingTx(false);
                        setLpTokenAmount("");
                    },
                    onFailure: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(false);
                        setLpTokenAmount("");
                    },
                });                
            },
            buttonDisabled: displayAllowLpTokenButton,
        },
        CLAIM: {
            onClickPrimaryButton: async () => {
                setBroadcastingTx(true);
                await transactions.ClaimStakingRewards(null, {
                    onBroadcast: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(true);
                    },
                    onConfirm: () => {
                        setConfirmingTx(false);
                    },
                    onFailure: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(false);
                    },
                });
            },
            buttonDisabled: !redeemableTribeRewards || redeemableTribeRewards === "0",
        },
        WITHDRAW: {
            onClickPrimaryButton: async () => {
                setBroadcastingTx(true);
                await transactions.WithdrawStakedLpTokens(null, {
                    onBroadcast: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(true);
                    },
                    onConfirm: () => {
                        setConfirmingTx(false);
                    },
                    onFailure: () => {
                        setBroadcastingTx(false);
                        setConfirmingTx(false);
                    },
                });
            },
            buttonDisabled: !stakedBalance || stakedBalance === "0",
        }
    };

    const {
        onClickPrimaryButton,
        buttonDisabled,
    } = formConfig[action];

    const JSON_MODE = {
        protocolState,
        lpTokenBalance,
        stakedBalance,
    };
    return {
        loading,
        action,
        setAction,
        lpTokenAmount,
        lpTokenBalance,
        stakedLpValue,
        onClickMax,
        setLpTokenAmount,
        broadcastingTx,
        confirmingTx,
        onClickPrimaryButton,
        errorMessage,
        displayAllowLpTokenButton,
        onClickAllowLpToken,
        redeemableTribeRewards,
        stakedBalance,
        buttonDisabled,
        showConnectWalletButton: !!!connectedAccount,
        weeklyTribeRewardPerOneThousandFei,
        variableApy,
        onClickReadMore,
        JSON_MODE
    };
}