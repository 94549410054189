const productionConfig = {
    network: "MAINNET",
    postGenesis: true,
    jsonMode: false,
    comingSoonMode: false,
    analyticsPageEnabled: true,
    // amplitudeKey: "2fcc8cfff50dbd205cdcb27094c64eff",
    feiEthUniswapAnalyticsUrl: "https://v2.info.uniswap.org/pair/0x94B0A3d511b6EcDb17eBF877278Ab030acb0A878",
    feiTribeUniswapAnalyticsUrl: "https://v2.info.uniswap.org/pair/0x9928e4046d7c6513326cCeA028cD3e7a91c7590A",
    feiTribeUniswapLpLink: "https://app.uniswap.org/#/add/v2/0x956f47f50a910163d8bf957cf5846d573e7f87ca/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
    feiTribeUniswapTradingUrl: "https://app.uniswap.org/#/swap?inputCurrency=0x956f47f50a910163d8bf957cf5846d573e7f87ca&outputCurrency=0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
    fip2RedemptionEnabled: true,
    tribalChiefEnabled: true,
};

export default productionConfig;