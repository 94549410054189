import React, {Fragment, useRef} from "react";
import Flex from "../../ui/Flex";
import {colors, radii} from "../../../providers/theme/theme";
import { MiniText, TitleText, BoldSpan} from "../../ui/Text";
import {PrimaryButton} from "../../ui/Button";
import DownloadIcon from "../../svgs/DownloadIcon";
import PlusIcon from "../../svgs/PlusIcon";
import UploadIcon from "../../svgs/UploadIcon";
import {formatCryptoAmount, withCommas} from "../../../utils/numbers";
import FeiTribeIcon from "../../svgs/FeiTribeIcon";
import TribeIcon from "../../svgs/TribeIcon";
import Input from "../../ui/Input";
import AllowanceProgressBar from "../../ui/AllowanceProgressBar";
import {CONTEXT_NAMES, EVENTS} from "../../../providers/analytics/events";
import ConnectWalletButton from "../../ui/Button/ConnectWalletButton";
import FormErrorDisplay from "../../ui/FormErrorDisplay";
import MaxButton from "../../ui/Button/MaxButton";
import Tooltip from "../../ui/Tooltip";

export default function StakingForm({
    action,
    setAction,
    broadcastingTx,
    confirmingTx,
    onClickPrimaryButton,
    errorMessage,
    lpTokenAmount,
    lpTokenBalance,
    stakedLpValue,
    onClickMax,
    displayAllowLpTokenButton,
    onClickAllowLpToken,
    setLpTokenAmount,
    showConnectWalletButton,
    redeemableTribeRewards,
    stakedBalance,
    buttonDisabled,
    weeklyTribeRewardPerOneThousandFei,
    variableApy,
}) {
    const transactionInFlight = broadcastingTx || confirmingTx;
    const actionConfig = {
        STAKE: {
            ButtonIconComponent: displayAllowLpTokenButton ? <Fragment/> : <DownloadIcon/>,
            primaryColor: colors.green,
            buttonText: displayAllowLpTokenButton ? "Stake LP" : "Stake LP Tokens",
            onInputChange: setLpTokenAmount,
            primaryButtonTrackingEvent: EVENTS.STAKE.STAKE_LP,
            inputs: [{
                paddingRight: 120,
                value: lpTokenAmount,
                inputFieldDisabled: false,
                tokenSymbol: "FEI-TRIBE",
                Icon: FeiTribeIcon,
            }],
            SuperScriptComponent: (
                <Flex {...styles.LabelValueContainer}>
                    <MiniText
                        {...styles.DarkText}
                    >
                        <BoldSpan>BALANCE:</BoldSpan> {formatCryptoAmount(lpTokenBalance)} FEI-TRIBE LP
                    </MiniText>
                    <MaxButton
                        onClick={onClickMax}
                    />
                </Flex>
            ),
        },
        CLAIM: {
            ButtonIconComponent: <PlusIcon marginBottom={"1px"}/>,
            primaryColor: colors.blue.tribe,
            buttonText: "Claim all Rewards",
            inputs: [{
                    tokenSymbol: "TRIBE",
                    inputFieldDisabled: true,
                    value: formatCryptoAmount(redeemableTribeRewards, {
                        withoutCommas: true,
                    }),
                    paddingRight: 75,
                    Icon: TribeIcon,
            }],
            primaryButtonTrackingEvent: EVENTS.STAKE.CLAIM_TRIBE,
            onInputChange: () => {},
        },
        WITHDRAW: {
            ButtonIconComponent: <UploadIcon/>,
            primaryColor: colors.red.dark,
            buttonText: "Withdraw + Claim Rewards",
            inputs: [
                {
                    tokenSymbol: "FEI-TRIBE",
                    inputFieldDisabled: true,
                    value: formatCryptoAmount(stakedBalance, {
                        withoutCommas: true,
                    }),
                    paddingRight: 115,
                    Icon: FeiTribeIcon,
                    customContainerStyle: {
                        marginTop: 0,
                    }
                },
                {
                    tokenSymbol: "TRIBE",
                    inputFieldDisabled: true,
                    value: formatCryptoAmount(redeemableTribeRewards, {
                        withoutCommas: true,
                    }),
                    paddingRight: 75,
                    Icon: TribeIcon,
                    customContainerStyle: {
                        marginTop: 0,
                    }
                }
            ],
            primaryButtonTrackingEvent: EVENTS.STAKE.WITHDRAW_LP,
            onInputChange: () => {},
        },
    };

    const {
        ButtonIconComponent,
        primaryColor,
        buttonText,
        onInputChange,
        inputs,
        primaryButtonTrackingEvent,
        SuperScriptComponent,
    } = actionConfig[action];

    return (
        <Flex {...styles.Container}>
            <StakingInfo
                redeemableTribeRewards={redeemableTribeRewards}
                stakedBalance={stakedBalance}
                stakedLpValue={stakedLpValue}
                weeklyTribeRewardPerOneThousandFei={weeklyTribeRewardPerOneThousandFei}
                variableApy={variableApy}
            />
            <Flex {...styles.FormAndErrorContainer}>
                <Flex {...styles.FormContainer}>
                    {/* <Flex {...styles.StakeClaimWithdrawToggleContainer}> */}
                        {/* <ToggleButton
                            onClick={() => setAction("STAKE")}
                            transactionInFlight={transactionInFlight}
                            selected={action === "STAKE"}
                            color={actionConfig.STAKE.primaryColor}
                            text={"Stake"}
                            flex={2}
                        /> */}
                        {/* <ToggleButton
                            onClick={() => setAction("CLAIM")}
                            transactionInFlight={transactionInFlight}
                            selected={action === "CLAIM"}
                            color={actionConfig.CLAIM.primaryColor}
                            text={"Claim"}
                            flex={2}
                        /> */}
                        {/* <ToggleButton
                            transactionInFlight={transactionInFlight}
                            onClick={() => setAction("WITHDRAW")}
                            selected={action === "WITHDRAW"}
                            color={actionConfig.WITHDRAW.primaryColor}
                            text={"Withdraw"}
                            flex={3}
                        /> */}
                    {/* </Flex> */}
                    {SuperScriptComponent}
                    <Flex {...styles.CenterContentContainer}>
                        {
                            inputs.map(({
                                tokenSymbol,
                                inputFieldDisabled,
                                value,
                                paddingRight,
                                Icon,
                                customContainerStyle,
                            }, i) => {
                                let inputFieldBackground = "#525669";
                                if (errorMessage) {
                                    inputFieldBackground = "#773E4A";
                                }
                                if (inputFieldDisabled) {
                                    inputFieldBackground = "transparent";
                                }
                                return (
                                    <Flex 
                                        {...styles.AmountInputContainer}
                                        {...customContainerStyle}
                                        key={i}
                                    >
                                        <Input
                                            mono bold
                                            type={"number"}
                                            value={value}
                                            onChange={onInputChange}
                                            disabled={inputFieldDisabled}
                                            {...styles.FormInput}
                                            paddingRight={paddingRight}
                                            backgroundColor={inputFieldBackground}
                                        />
                                        <Icon {...styles.FormInputIcon}/>
                                        <TitleText
                                            mono bold
                                            text={tokenSymbol}
                                            {...styles.AmountInputTokenSymbol}
                                        />
                                    </Flex>
                                );
                            })
                        }
                    </Flex>
                    
                    <Flex {...styles.ButtonsRow}>
                        {
                            showConnectWalletButton ? (
                                <ConnectWalletButton style={styles.PrimaryButton}/>
                            ) : (
                                <Fragment>
                                    {
                                        displayAllowLpTokenButton && (
                                            <PrimaryButton
                                                {...styles.PrimaryButton}
                                                {...styles.AllowLpTokensButton}
                                                onClick={onClickAllowLpToken}
                                                broadcastingTx={displayAllowLpTokenButton && broadcastingTx}
                                                confirmingTx={displayAllowLpTokenButton && confirmingTx}
                                                shortBroadcastingTxContent={true}
                                                tracking={{
                                                    contextName: CONTEXT_NAMES.STAKE,
                                                    eventName: EVENTS.STAKE.APPROVE_LP,
                                                }}
                                            >
                                                <TitleText text={"Allow LP"}/>
                                            </PrimaryButton>
                                        )
                                    }
                                    <PrimaryButton
                                        {...styles.PrimaryButton}
                                        backgroundColor={primaryColor}
                                        hoverBg={primaryColor}
                                        broadcastingTx={!displayAllowLpTokenButton && broadcastingTx}
                                        confirmingTx={!displayAllowLpTokenButton && confirmingTx}
                                        onClick={onClickPrimaryButton}
                                        disabled={buttonDisabled}
                                        tracking={{
                                            contextName: CONTEXT_NAMES.STAKE,
                                            eventName: primaryButtonTrackingEvent,
                                        }}
                                    >
                                        <Flex {...styles.PrimaryButtonContentContainer}>
                                            {ButtonIconComponent}
                                            <TitleText marginLeft={displayAllowLpTokenButton ? "0px" : "14px"} bold text={buttonText}/>
                                        </Flex>
                                    </PrimaryButton>
                                </Fragment>
                            )
                        }
                    </Flex>
                    {
                        displayAllowLpTokenButton &&
                        !showConnectWalletButton && (
                            <AllowanceProgressBar/>
                        )
                    }
                    
                </Flex>
                <FormErrorDisplay errorMessage={errorMessage}/>
            </Flex>
        </Flex>
        
    );
}

function StakingInfo({
    redeemableTribeRewards,
    stakedBalance,
    stakedLpValue,
    weeklyTribeRewardPerOneThousandFei,
    variableApy,
}) {
    const rows = [
        {
            key: "Your Staked LP",
            value: `${formatCryptoAmount(stakedBalance)} FEI-TRIBE LP`,
        },
        {
            key: "Staked LP Value",
            value: `${formatCryptoAmount(stakedLpValue)} FEI`,
        },
        {
            key: "Unclaimed Rewards",
            value: `${formatCryptoAmount(redeemableTribeRewards)} TRIBE`,
        },
        {
            key: `Yield per 1,000 FEI`,
            value: `${formatCryptoAmount(weeklyTribeRewardPerOneThousandFei)} TRIBE/week`,
        },
        {
            key: "Variable APR",
            value: `${withCommas(variableApy)}%`,
        }
    ];
    return (
        <Flex {...styles.StakingInfoContainer}>
            {rows.map(({key, value, tooltipText}, i) => {
                return (
                    <Flex {...styles.StakingInfoRow} key={i}>
                        <TitleText 
                            text={key}
                            {...styles.StakingInfoText}
                        />
                        <Flex {...styles.StakingInfoRowRightSide}>
                            <TitleText 
                                text={value} 
                                mono
                                truncated
                                {...styles.StakingInfoText}
                            />
                            {
                                tooltipText && (
                                    <Tooltip 
                                        tooltipText={tooltipText}
                                        tooltipIconStyles={{marginLeft: "5px"}}
                                    />
                                )
                            }
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
}

function ToggleButton({
    selected,
    color,
    text,
    onClick,
    flex,
    transactionInFlight,
}) {
    const backgroundColor = selected ? color : "transparent";
    const textColor = selected ? colors.white : "#D3D3D3";
    return (
        <Flex
            {...styles.ToggleButton}
            backgroundColor={backgroundColor}
            onClick={transactionInFlight ? () => {} : onClick}
            flex={flex}
            cursor={transactionInFlight ? "default" : "pointer"}
        >
            <TitleText text={text} color={textColor} />
        </Flex>
    );
}

const formHeight = "250px";
const styles = {
    Container: {
        marginTop: [30, 30],
        backgroundColor: "#282B39",
        borderRadius: radii.medium,
        flexDirection: ["column", "row"],
        alignItems: ["center", "flex-start"],
        justifyContent: "flex-end",
        zIndex: 1,
        height: ["auto", formHeight],
    },
    FormContainer: {
        backgroundColor: "#3E4256",
        borderRadius: radii.medium,
        width: ["100%", "350px"],
        height: formHeight,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "15px",
        zIndex: 1,
    },
    FormAndErrorContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    StakeClaimWithdrawToggleContainer: {
        width: "100%",
        borderRadius: radii.medium,
        height: 40,
        backgroundColor: "#525669",
        boxShadow: "0px 5.98639px 11.9728px rgba(59, 59, 92, 0.08), 0px 0px 1.19728px rgba(50, 50, 93, 0.02), 0px 3.59184px 7.18367px rgba(0, 0, 0, 0.06)"
    },
    ToggleButton: {
        borderRadius: radii.medium,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        cursor: "pointer",
    },
    PrimaryButton: {
        width: "100%",
        marginTop: "15px",
    },
    PrimaryButtonContentContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    CenterContentContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
    },
    AmountInputContainer: {
        width: "100%",
        position: "relative", 
    },
    FormInput: {
        borderRadius: radii.medium,
        width: "100%",
        backgroundColor: "#525669",
        color: colors.white,
        height: 50,
        paddingLeft: 52,
        paddingRight: 55,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    FormInputIcon: {
        position: "absolute",
        left: 15,
        top: 9,
        height: 32,
        width: "auto",
        length: "auto",
    },
    AmountInputTokenSymbol: {
        position: "absolute",
        right: 12,
        top: 14,
        color: "#D3D3D3"
    },
    LabelValueContainer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginTop: "10px"
    },
    DarkText: {
        color: "#9799A4",
    },
    ButtonsRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    AllowLpTokensButton: {
        marginRight: "10px",
        backgroundColor: colors.purple,
    },
    StakingInfoContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: ["180px", "100%"],
        minWidth: ["100%", "430px"],
    },
    StakingInfoRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    StakingInfoText: {
        fontSize: ["12px", "16px"],
        lineHeight: ["1", "1.3"],
        maxWidth: ["150px", "300px"],
    },
    StakingInfoRowRightSide: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
}