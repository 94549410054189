import React from "react";
import styled from "styled-components";
import Loader from "../Loader";
import BroadcastingTransaction from "../BroadcastingTransaction";
import {colors, radii} from "../../../providers/theme/theme";

import {
    alignItems,
    border,
    borderRadius,
    boxShadow,
    color,
    display,
    fontFamily,
    fontSize,
    fontWeight,
    height,
    width,
    minHeight,
    minWidth,
    justifyContent,
    letterSpacing,
    lineHeight,
    position,
    space,
} from "styled-system";

import {
    fill,
    hoverBg,
    hoverBoxShadow,
    hoverColor,
    hoverFill,
    pointerEvents,
    textTransform,
    transform,
    hoverTransform,
    filter,
    cursor,
} from "../../../providers/theme/customProperties";

import {track} from "../../../providers/analytics";
import {EVENT_TYPES} from "../../../providers/analytics/events";
import get from "lodash/get";

const ButtonElement = styled.button`
    ${alignItems};
    ${borderRadius};
    ${boxShadow};
    ${color};
    ${display};
    ${fill};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${height};
    ${justifyContent};
    ${letterSpacing};
    ${lineHeight};
    ${minHeight};
    ${minWidth};
    ${pointerEvents};
    ${position};
    ${space};
    ${textTransform};
    ${transform};
    ${width};
    ${fill};
    ${border};
    ${filter};
    ${cursor};
    

    appearance: none;
    box-sizing: border-box;
    outline-style: none;
    text-align: center;
    text-decoration: none;
    transition-duration: 200ms;
    user-select: none;
    white-space: nowrap;

    @media (pointer: fine) {
        &:hover {
            ${hoverBg};
            ${hoverBoxShadow};
            ${hoverColor};
            ${hoverFill};
            ${hoverTransform};
        }

        &:active {
            ${boxShadow};
            transform: translateY(0);
        }
    }
    
`;

function Button({ 
    loading, 
    disabled, 
    children, 
    onClick,
    tracking,
    broadcastingTx,
    confirmingTx,
    shortBroadcastingTxContent,
    ...props }) {

    const disabledProps = (disabled || loading) ? {
        backgroundColor: colors.grey.medium,
        hoverBg: colors.grey.medium,
        cursor: "default",
        filter: "grayscale(100%)",
        hoverTransform: "scale(1)",
    } : {};

    const broadcastingTxProps = broadcastingTx ? {
        backgroundColor: colors.purple,
        hoverBg: colors.purple,
        cursor: "default",
    } : {};

    const confirmingTxProps = confirmingTx ? {
        backgroundColor: colors.green,
        hoverBg: colors.green,
        cursor: "default",
    } : {};

    let buttonContent = children;

    if (loading) {
        buttonContent = (
            <Loader 
                height="20px" 
                width="50px" 
                type="ThreeDots"
                color="rgba(255,255,255,0.5)"
            />
        );
    }

    if (broadcastingTx || confirmingTx) {
        buttonContent = (
            <BroadcastingTransaction 
                shortBroadcastingTxContent={shortBroadcastingTxContent}
                text={broadcastingTx ? "Broadcasting..." : "Confirming..."}
            />
        );
    }

    return (
        <ButtonElement
            disabled={disabled || loading || broadcastingTx}
            onClick={(e) => {
                if (tracking) {
                    const contextName = get(tracking, "contextName");
                    const eventName = get(tracking, "eventName");
                    const data = get(tracking, "data");
                    track({
                        contextName,
                        eventName,
                        eventType: EVENT_TYPES.CLICKED_BUTTON,
                    }, data);
                }
                onClick && onClick(e);
            }}
            {...styles.Base}
            {...props}
            {...disabledProps}
            {...broadcastingTxProps}
            {...confirmingTxProps}
        >
            {buttonContent}
        </ButtonElement>
    );
}
export const PrimaryButton = React.memo(({
    text,
    children,
    ...props
}) => {
    return (
        <Button {...styles.Primary} {...props}>
            {text || children}
        </Button>
    );
});

const styles = {
    Base: {
        lineHeight: 1,
        border: "none",
        cursor: "pointer",
    },
    Primary: {
        bg: colors.black,
        color: colors.white,
        hoverBg: colors.purple,
        hoverColor: colors.white,
        hoverBoxShadow: "button.hover",
        hoverTransform: "scale(1.02)",
        borderRadius: radii.small,
        height: "44px",
        minHeight: "40px",
        fontSize: "16px",
        pl: "20px",
        pr: "20px",
    }
}

export default React.memo(Button);

