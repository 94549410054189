import React, { useEffect } from "react";
import get from "lodash/get";
import { Pie as PieChartJs } from "react-chartjs-2";
import Flex from "../../ui/Flex";
import {CONTEXT_NAMES, EVENT_TYPES} from "../../../providers/analytics/events";
import { track } from "../../../providers/analytics";
import { colors, fontWeights } from "../../../providers/theme/theme";
import FeiHeader from "../../ui/FeiHeader";
import FeiFooter from "../../ui/FeiFooter";
import { BodyText, HeaderText } from "../../ui/Text";
import config from "../../../config";
import Tooltip from "../../ui/Tooltip";
import { formatCryptoAmount, formatDollarAmount } from "../../../utils/numbers";
import UniswapLinkBox from "../../ui/UniswapLinkBox";


export default function AnalyticsView({
    JSON_MODE,
    usdEthPeg,
    feiEthPrice,
    formattedFeiUsdPrice,
    formattedDistanceFromPeg,
    // PCV
    protocolControlledLiquidity,
    totalLiquidity: feiEthLpTotalSupply,
    formattedProtocolControlledFei,
    formattedCollateralizationPercentage,
    formattedFeiTotalSupply,
    formattedUserCirculatingFei,
    pcvValue,
    formattedTimeUntilNextReweight,
    pcvChartConfig,
    pcvChartData,
    protocolFeiChartConfig,
    protocolFeiChartData,
}) {
    useEffect(() => {
        track({
            eventType: EVENT_TYPES.VIEWED_SCREEN,
            contextName: CONTEXT_NAMES.ANALYTICS_PAGE,
            eventName: CONTEXT_NAMES.ANALYTICS_PAGE,
        });
    }, []);

    if (!config.analyticsPageEnabled) {
        return (
            <Flex {...styles.Container}>
                <FeiHeader contextName={CONTEXT_NAMES.ANALYTICS_PAGE}/>
                <HeaderText 
                    text={"Analytics under maintenance for FIP-20. Coming back soon"}
                    marginTop={"200px"}
                    maxWidth={"95vw"}
                    textAlign={"center"}
                />
                <UniswapLinkBox
                    url={config.feiEthUniswapAnalyticsUrl}
                    buttonText={"Uniswap FEI-ETH pair analytics"}
                    bodyText={"You can see more in depth analytics on Uniswap's the FEI-ETH pair page"}
                    titleText={"See Uniswap Analytics"}
                />
                <FeiFooter contextName={CONTEXT_NAMES.ANALYTICS_PAGE}/>
            </Flex>
        );
    }

    return (
        <Flex {...styles.Container}>
            <FeiHeader contextName={CONTEXT_NAMES.ANALYTICS_PAGE}/>
            <Flex {...styles.StatsContainer}>
                <HeaderText {...styles.SectionTitleText} text={"Peg"}/>
                {/* <Flex {...styles.StatsRow}>
                    <Stat
                        label={"Peg Price"}
                        value={`${formatDollarAmount(usdEthPeg)} USD/ETH`}
                        tooltipText={"Chainlink USD-ETH Oracle"}
                    />
                    <Stat
                        label={"Fei Price"}
                        value={`${formatDollarAmount(feiEthPrice)} FEI/ETH`}
                        tooltipText={"Spot FEI-ETH price"}
                    />
                </Flex> */}
                <Flex {...styles.StatsRow}>
                    <Stat
                        label={"FEI / USD"}
                        value={`${formattedFeiUsdPrice}`}
                        tooltipText={""}
                    />
                    <Stat
                        label={"Peg Distance"}
                        value={`${formattedDistanceFromPeg}`}
                        tooltipText={"Distance from the peg"}
                    />
                </Flex>
                {/* <Flex {...styles.StatsRow}>
                    <Stat
                        label={"Time until Reweight"}
                        value={formattedTimeUntilNextReweight}
                        tooltipText={"Must also > 50 bp under peg to be reweight eligible"}
                    />
                </Flex> */}
                {/* <iframe width={"800"} color={"white"} height={"400"} src={"https://dune.xyz/embeds/185978/347137/0e6b0c6e-7461-451e-a01b-f5a1f5d41518"}/> */}
                <HeaderText {...styles.SectionTitleText} text={"PCV"}/>
                <Flex {...styles.StatsRow}>
                    <Stat
                        label={"Protocol Controlled Value"}
                        value={`$${formatDollarAmount(pcvValue)}`}
                        tooltipText={"The USD value of all the assets owned by Fei Protocol"}
                    />
                    <Stat
                        label={"Collateralization Ratio"}
                        value={`${formattedCollateralizationPercentage}%`}
                        tooltipText={"The ratio of PCV to user circulating FEI"}
                    />
                </Flex>
                <Flex {...styles.StatsRow}>
                    <Stat
                        label={"Protocol Owned FEI"}
                        value={`${formattedProtocolControlledFei}`}
                        tooltipText={"FEI held by the protocol"}
                    />
                    <Stat
                        label={"User Circulating FEI"}
                        value={`${formattedUserCirculatingFei}`}
                        tooltipText={"All FEI not held by Fei Protocol"}
                    />
                </Flex>
                <HeaderText 
                    {...styles.SectionTitleText} 
                    text={"PCV Allocation"}
                    {...{marginBottom: "10px"}}
                />
                <BodyText 
                    bold secondary 
                    text={`Total: $${pcvChartData.totalValue} Million`}
                    {...styles.StatLabelText}
                    {...{marginBottom: "20px"}}
                />
                <Flex {...styles.StatsRow}>
                    <PieChartJs 
                    style={{cursor: "pointer"}}
                    getElementAtEvent={linkToPcvDepositContract.bind(null, pcvChartConfig)}
                    options={{
                        animation: false,
                        plugins: {
                            tooltip: {
                                bodyFont: {
                                    size: 18,
                                },
                                callbacks: {
                                    label: (context) => {
                                        const value = get(context, "raw");
                                        const label = get(context, "label");
                                        const percentage = get(context, "percentage");
                                        return `  ${label} $${value} Million`
                                    }
                                }
                            },
                            legend: {
                                position: "bottom",
                                labels: {
                                    color: "white",
                                    textAlign: "left",
                                    padding: 25,
                                    font: {
                                        size: "18",
                                    }
                                }
                            },
                        }
                    }}
                    data={pcvChartData}/>
                </Flex>
                <HeaderText 
                    {...styles.SectionTitleText} 
                    text={"Protocol-owned FEI"}
                    {...{marginTop: "60px", marginBottom: "10px"}}
                />
                <BodyText 
                    bold secondary 
                    text={`Total: $${protocolFeiChartData.totalValue} Million`}
                    {...styles.StatLabelText}
                    {...{marginBottom: "20px"}}
                />
                <Flex {...styles.StatsRow}>
                    <PieChartJs 
                    style={{cursor: "pointer"}}
                    getElementAtEvent={linkToPcvDepositContract.bind(null, protocolFeiChartConfig)}
                    options={{
                        animation: false,
                        plugins: {
                            tooltip: {
                                bodyFont: {
                                    size: 18,
                                },
                                callbacks: {
                                    label: (context) => {
                                        const value = get(context, "raw");
                                        const label = get(context, "label");
                                        return `  ${label} ${value} Million FEI`
                                    }
                                }
                            },
                            legend: {
                                position: "bottom",
                                labels: {
                                    color: "white",
                                    textAlign: "left",
                                    padding: 25,
                                    font: {
                                        size: "18",
                                    }
                                }
                            },
                        }
                    }}
                    data={protocolFeiChartData}/>
                </Flex>
                <UniswapLinkBox
                    url={config.feiEthUniswapAnalyticsUrl}
                    buttonText={"Uniswap FEI-ETH pair analytics"}
                    bodyText={"You can see more in depth analytics on Uniswap's the FEI-ETH pair page"}
                    titleText={"See Uniswap Analytics"}
                />
            </Flex>

            {
                JSON_MODE && config.jsonMode && (
                    <BodyText 
                        bold 
                        mono 
                        lineHeight={"2"}
                        marginTop={"300px"}
                        text={JSON.stringify(JSON_MODE, null, "\t")}
                        maxWidth={"100%"}
                    />
                )
            }
            <FeiFooter contextName={CONTEXT_NAMES.ANALYTICS_PAGE}/>
        </Flex>
    );
}

export function Stat({
    value,
    label,
    tooltipText,
    customContainerProps,
    customValueTextProps,
}) {
    const valueFontSize = value.length > 10 ? "24px" : "28px"
    return (
        <Flex 
            {...styles.StatContainer}
            {...customContainerProps}
        >
            <HeaderText 
                mono
                text={value}
                fontSize={valueFontSize}
                {...styles.StatValueText}
                {...customValueTextProps}
            />
            <StatLabel labelText={label} tooltipText={tooltipText}/>
        </Flex>
    );
}

export function StatLabel({labelText, tooltipText}) {
    return (
        <Flex {...styles.StatLabelTextRow}>
            <BodyText 
                bold secondary 
                text={labelText}
                {...styles.StatLabelText}
            />
            {
                tooltipText && (
                    <Tooltip
                        tooltipIconStyles={{marginLeft: "5px"}}
                        tooltipText={tooltipText}
                    />
                )
            }
        </Flex>
    );
}

const linkToPcvDepositContract = (chartConfig, element) => {
    const elementIndex = get(element, "0.index", null);
    const pcvDepositAddress = get(chartConfig, `data.${elementIndex}.pcvDepositAddress`, null);
    const customLink = get(chartConfig, `data.${elementIndex}.customLink`, null);
    if (pcvDepositAddress) {
        window.open(`https://etherscan.io/address/${pcvDepositAddress}`);
    } else if (customLink) {
        window.open(customLink);
    } else {
        window.open("https://docs.fei.money/developer/contract-addresses");
    }
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: colors.grey.dark,
        overflow: "hidden",
        paddingBottom: "40px",
    },
    StatsContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: ["30px", "60px"],
        width: ["100%", "700px"]
    },
    StatsRow: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "100%",
        marginBottom: ["0px", "20px"]
    },
    StatContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        maxWidth: "300px",
        flex: 1,
    },
    StatLabelTextRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
    },
    StatValueText: {
        fontWeight: fontWeights.regular,
        lineHeight: "1",
        textAlign: "center",
    },
    SectionTitleText: {
        marginBottom: "30px",
        marginTop:"20px",
    },
    StatLabelText: {
        textAlign: "center",
    },
};