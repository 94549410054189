import React from "react";
import styled, { css } from "styled-components";
import {
    color,
    layout,
    position,
    space,
    typography,
} from "styled-system";
import {
    hoverColor,
} from "../../../providers/theme/customProperties";
import { colors, fontWeights, fonts } from "../../../providers/theme/theme";
import { hexToRGB } from "../../../utils/ui";

export const Text = React.memo(styled.div`
  ${color};
  ${layout};
  ${position};
  ${space};
  ${typography};
  white-space: pre-line;

  &:hover {
    ${hoverColor};
  }

  ${props =>
        props.truncated &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
 
  ${props =>
        props.cursor === "pointer" &&
        css`
        cursor: pointer;
   `};
`);

export const Span = React.memo(styled.span`
  ${color};
  ${layout};
  ${position};
  ${space};
  ${typography};
  &:hover {
    ${hoverColor};
  }
  ${props =>
    props.cursor === "pointer" &&
    css`
    cursor: pointer;
`};
`);

export const LinkSpan = React.memo(styled.span`
    color: ${colors.blue.link};
    text-decoration: underline;
    cursor: pointer;
`);

export const BoldSpan = React.memo(styled.span`
    font-weight: ${fontWeights.bold}
`);

export const BaseText = React.memo(({
  text,
  children,
  dark,
  secondary,
  mono,
  paragraph,
  bold,
  light,
  error,
  ...props
}) => {
  const errorProps = error ? { color: colors.red.error } : {};
  const darkProps = dark ? { color: colors.grey.dark } : {};
  const monoProps = mono ? { fontFamily: fonts.mono } : {};
  const secondaryProps = secondary ? {
    color: dark ? hexToRGB(colors.grey.dark, 0.6) : hexToRGB(colors.grey.lightest, 0.8)
  } : {};
  const paragraphProps = paragraph ? { lineHeight: 1.4 } : {};
  const boldProps = bold ? { fontWeight: fontWeights.bold } : {};
  const lightProps = light ? { fontWeight: fontWeights.light } : {};
  return (
    <Text
      {...props}
      {...errorProps}
      {...darkProps}
      {...secondaryProps}
      {...monoProps}
      {...paragraphProps}
      {...boldProps}
      {...lightProps}
    >
      {text || children}
    </Text> 
  );
});

export const HeaderText = React.memo((props) => {
  return (
    <BaseText
      {...styles.HeaderText}
      {...props}
    />
  );
});

export const TitleText = React.memo((props) => {
  return (
    <BaseText
      {...styles.TitleText}
      {...props}
    />
  );
});

export const BodyText = React.memo((props) => {
  return (
    <BaseText
      {...styles.BodyText}
      {...props}
    />
  );
});


export const MiniText = React.memo((props) => {
  return (
    <BaseText
      {...styles.MiniText}
      {...props}
    />
  );
});

export const styles = {
  HeaderText: {
    fontSize: ["28px", "36px"],
    lineHeight:["32px", "40px"],
    color: colors.white,
    fontWeight: fontWeights.bold,
    fontFamily: fonts.primary,
  },
  TitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    color: colors.white,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
  },
  BodyText: {
    fontSize: "15px",
    lineHeight: "18px",
    color: colors.white,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
  },
  MiniText: {
    fontSize: "12px",
    lineHeight: "15px",
    color: colors.white,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
  },
};

// Scales down font size to fit in a certain space
// baseSize = standard size to show the text
// maxLengthAtBaseSize = the length after which we will begin scaling down
// scalingFactor = increase to scale down faster
export function scaleFontSize({
    text,
    baseSize,
    minSize,
    maxLengthAtBaseSize,
    scalingFactor = 1,
}){
  const textLength = !text ? 0 : text.length;
  if (textLength <= maxLengthAtBaseSize) {
    return baseSize;
  }
  const charactersOverLimit = textLength - maxLengthAtBaseSize;
  const sizeChangePerExtraCharacter = 1 * (scalingFactor);
  const calculatedSize = baseSize - (charactersOverLimit * sizeChangePerExtraCharacter);
  const fontSize = minSize ? Math.max(calculatedSize, minSize) : calculatedSize;
  return fontSize;
}