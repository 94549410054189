const developmentConfig = {
    // network: "LOCAL",
    network: "RINKEBY",
    postGenesis: true,
    jsonMode: true,
    comingSoonMode: false,
    analyticsPageEnabled: true,
    // amplitudeKey: "86e08cd9b4c35fb0b5ec6617b0243c86",
    feiTribeUniswapLpLink: "https://app.uniswap.org/#/add/0x251F9e3357930cDcF256e15c455588D1a049D77d/0xACef33441fD0CE75D772ba4C7fc4a32b3071C55F",
    fip2RedemptionEnabled: true,
};

export default developmentConfig;