import React from "react";

import Flex from "./Flex";
import {colors} from "../../providers/theme/theme";
import {MiniText} from "./Text";

export default function AllowanceProgressBar() {
    return (
        <Flex {...styles.ProgressBarContainer}>
            <Flex
                {...styles.ProgressBarDot}
                marginRight={"-10px"}
                backgroundColor={colors.purple}
            >
                <MiniText text={"1"} mono bold {...styles.ProgressBarLabelText}/>
            </Flex>
            <Flex {...styles.ProgressBarLine}/>
            <Flex
                {...styles.ProgressBarDot}
                marginLeft={"-10px"}
            >
                <MiniText 
                    text={"2"}
                    mono
                    bold
                    marginLeft={"2px"}
                    {...styles.ProgressBarLabelText}
                />
            </Flex>
        </Flex>
    );
}

const styles = {
    ProgressBarContainer: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "8px",
    },
    ProgressBarDot: {
        backgroundColor: colors.grey.medium,
        width: "20px",
        height: "20px",
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
    },
    ProgressBarLine: {
        height: "3px",
        width: "50%",
        background: `linear-gradient(90deg, ${colors.purple},${colors.grey.medium})`,
        zIndex: 1,
    },
    ProgressBarLabelText: {
        fontSize: "10px"
    },
};