import React, { Fragment } from "react";
import { PrimaryButton } from "./index";
import {useEthereum} from "../../../providers/ethereum";
import MetaMaskFox from "../../svgs/MetaMaskFox";
import Flex from "../Flex";
import {TitleText} from "../Text";
import {EVENTS} from "../../../providers/analytics/events";
import { colors } from "../../../providers/theme/theme";
import WalletConnectIcon from "../../svgs/WalletConnectIcon";

export default function ConnectWalletButton({
    style,
    contextName,
    small,
}) {

    const { 
        requestConnectAccount,
    } = useEthereum();

    const smallTextStyles = small ? { 
        fontSize: "16px",
        marginRight: "8px",
    } : {};
    const finalTextStyles = {
        ...styles.ButtonText,
        ...smallTextStyles,
    };

    return (
        <Fragment>
            <PrimaryButton
                onClick={requestConnectAccount}
                tracking={{
                    contextName,
                    eventName: EVENTS.GENERIC.CONNECT_WALLET,
                }}
                {...styles.Button}
                {...style}
            >
                <Flex {...styles.ButtonContent}>
                    <WalletConnectIcon {...styles.WalletConnectIcon}/>
                    <TitleText
                        text={"Connect Wallet"}
                        {...finalTextStyles}
                    />
                    <MetaMaskFox {...styles.MetaMaskFox}/>
                </Flex>
            </PrimaryButton>
        </Fragment>

    );
}

const styles = {
    Button: {
        backgroundColor: colors.blue.connectWallet,
        hoverBg: colors.blue.connectWallet,
        boxShadow: "0px 5.93478px 8.90217px rgba(50, 50, 93, 0.11), 0px 1.4837px 4.45109px rgba(0, 0, 0, 0.08)",
        width: "100%",
        marginTop: 15,
    },
    ButtonContent: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    ButtonText: {
        marginRight: 12,
        marginLeft: 12,
        fontSize: "20px",
    },
    WalletConnectIcon: {
        width: "30px",
        height: "auto",
    },
    MetaMaskFox: {
        marginTop: "1px",
        width: "auto",
        height: "30px"
    }
};